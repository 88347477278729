<template>
<div id="Contact">
  <center><h2>Contact</h2></center>
  <div class="contact-area">
    <p>If you have any questions, feel free to ask us below!</p>
    <p>{{feedback}}</p>
    <div class="contact-field" v-if="!processing">
      <br />
      <input class="default-input" v-model="name"  placeholder="Enter your name" />
      <input  class="default-input" v-model="email"  placeholder="Enter Your email" /> <br />
      <input class="default-input" v-model="subject"  placeholder="Subject"/> <br />
      <textarea class="default-input" v-model="message" placeholder="Enter Your Message"></textarea>
      <button class="primary-btn" @click="sendEmail()">Send</button>
    </div>

    <div class="processing" v-else-if="processing">
      <vue-spinner :processing="processing" color="#64B6DD" size="100px"></vue-spinner>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default{
  props:['isMobile'],
  components:{
    'vue-spinner': GridLoader,
  },
data(){
  return{
    name: '',
    email: '',
    subject: '',
    message: '',
    feedback: '',
    processing: false
  }
},
methods:{
  returnToLastRoute(){
    this.$router.back()
  },
  sendEmail(){
    this.processing = true
    let payload ={
      name:this.name,
      email:this.email,
      subject:this.subject,
      message:this.message
    }
    axios.post(this.$api_path+'skip_source/contact', payload)
    .then((res)=>{
      this.name = ''
      this.email = ''
      this.subject= ''
      this.message = ''
      this.feedback = res.data.feedback
      this.processing = false
    }).catch((err)=>{
      this.feedback = 'Could not send email'
      this.processing = false
      console.log(err)
    })
  }
}
}
</script>
<style scoped>
#Contact{
  min-height: 60vh;
}
#Contact h1{
  text-transform: uppercase;
  font-size: 1rem;
}
.setting-icon{
  position: absolute;
  right: 2rem;
  top: .5rem;
  width: 2rem;
}
.contact-area{
  width: 70%;
  margin: 3rem auto;
}
.contact-field input{
  border:none;
  border-bottom: 1px solid var(--primary);
  padding: .4rem 1rem;
  width: 100%;
  margin: .6rem 0;
  transition: 1s;
  font-size: 1rem;
}
.contact-field textarea{
  width: 100%;
  height: 8rem;
  padding: .4rem 1rem;
  margin: .6rem 0;
  border:none;
  border-bottom: 1px solid var(--primary);
  resize: none;
  font-size: 1.3rem;
}
@media only screen and (max-width: 600px) {
}
@media only screen and (min-width: 600px) {

}
</style>
