<template>
  <div id="MoreProducts">
    <div class="more-product-header">
      <h1>Cash Buyer & Distressed Seller List for Purchase</h1>
    </div>

    <div class="more-product-list">
      <div v-for="(product, idx) in more_products" :key="idx" class="product-box">
        <img :src="product.icon" class="product-icon"/>
        <p class="title">{{product.name}}</p>
        <a :href="product.link" target="_blank" class="product-btn"> GET IT NOW</a>
        <!-- <h3>${{ (product.price/100).toLocaleString()}}</h3> -->
        <h3>{{product.price}}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from '@/_firebase/init'

export default {
  name: 'MoreProducts',
  components: {
  },
  data(){
    return{
      more_products:[
        {icon: require('@/assets/logo.svg'),
        link: '/squeeze',
        name: 'Cash Buyers List: 500 records', price: '$125'},
        {icon: require('@/assets/logo.svg'),
        link: '/squeeze',
        name: 'Absentee Owner List: 2000 records', price: '$150'},
        {icon: require('@/assets/logo.svg'),
        link: '/squeeze',
        name: 'Liens List: 2000 recoreds', price: '$150'},
      ]
    }
  },
  methods:{
  },
  created(){
  }
}
</script>

<style scoped>
#MoreProducts{
  margin: 2rem 4rem;
}
.more-product-header{
  width: 80%;
  margin: 2rem auto;
  text-align:center;
}
.more-product-header h1{
  font-size: 3rem;
  text-transform: uppercase;
}
.more-product-list{
  width: 80%;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr .5fr;
  grid-column-gap: 20px;
  grid-row-gap: 6rem;
  justify-items: center;
  align-items: center;
  transition: 1s;
}
.product-box{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  text-align: center;
  transition: 1s;
}
.product-box .title{
  font-weight: bold;
  font-size: 1.4rem;
  transition: 1s;
}
.product-box h3{
  font-size: 1.7rem;
    transition: 1s;
}
.product-btn{
  border-radius: 10px;
  padding: 1rem 2rem;
  margin: 1rem;
  color: #fff;
  background-color: var(--primary);
  text-decoration: none;
}
.product-icon{
  margin: 2rem auto;
  max-width: 6rem;
}
.container{
  margin: 2rem auto;
  max-width: 900px;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.question-text{
  font-weight: bold;
}

.author-text{
  color: var(--primary);
}
@media screen and (max-width: 900px) {
  .product-box .title{
    font-size: .8rem;
    transition: 1s;
  }
}
@media screen and (max-width: 600px) {
  #MoreProducts{
    margin: 2rem 0;
  }
  .product-box .title{
    font-size: .6rem;
    transition: 1s;
  }
  .more-product-list{
    width: 90%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  .product-box{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    text-align: center;
    transition: 1s;
  }
  .more-product-header h1{
    font-size: 2rem;
    width: 100%;
    marign: 0;
  }
  .product-box h2{
    width: 100%;
    font-size: .8rem;
  }
  .product-icon{
    margin: 1rem auto;
    max-width: 3rem;
  }
  .product-btn{
    width: 80%;
    padding: .2rem 1rem;
    font-size: .7rem;
  }
  .product-box h3{
    font-size: 1.2rem;
  }
}
</style>
