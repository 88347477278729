<template>
  <div id="Hero">
    <div class="flex container m-3 my-10 h-full">
      <div class="w-1/2 flex flex-col items-center justify-center mt-10">
        <h2 class="text-5xl text-center font-bold">ALL-IN-ONE PLATFORM</h2>

        <h3 class="text-3xl my-2 text-center mx-auto text-blue-400">
          Real Estate Professionals
        </h3>

        <!-- <router-link
          v-if="!isAuthenthicated"
          class="bg-yellow-400 text-white p-5 text-2xl rounded"
          :to="{name:'Signup'}">
            GET STARTED
        </router-link>
        <router-link  v-else-if="isAuthenthicated" :to="{name:'Overview'}"
          class="bg-white text-blue-400 py-4 px-12 text-2xl font-bold border border-blue-400 rounded">
          APP
        </router-link> -->
        <a
          href="https://elite.skipsourcedata.com"
          class="bg-yellow-400 text-white p-5 text-2xl rounded"
        >
          GET STARTED</a
        >
      </div>
      <div class="w-1/2 flex flex-col items-center mt-10">
        <img class="w-10/12" src="@/assets/media/macbook-retina.png" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";

export default {
  name: "Hero",
  components: {},
  data() {
    return {
      placeholderImg: require("@/assets/suburbs.jpg"),
    };
  },
  computed: {
    ...mapState(["userID"]),
    ...mapGetters(["isAuthenthicated"]),
  },
};
</script>
<style scoped>
#Hero {
  position: relative;
  width: 100%;
  min-height: 50vh;
}
a {
  text-decoration: none;
}
.fullscreen-img-wrap {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  position: relative;
  /* Set a specific height */
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overlay {
  overflow-x: hidden;
  max-width: 100vw;
  position: absolute;
  width: 100%;
  height: 80vh;
  top: 0;
  background: #111;
  opacity: 0.6;
  z-index: 1;
  display: flex;
  justiy-content: center;
  align-item: center;
}
.over-content {
  z-index: 2;
  top: 34%;
  width: 100%;
  position: absolute;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.over-content h1 {
  font-size: 2.9rem;
  text-transform: uppercase;
}
.hero-btn {
  box-shadow: 0 0 0 0 rgba(172, 129, 45, 0.5);
  -webkit-box-shadow: 0 0 0 0 rgba(172, 129, 45, 0.5);
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 2s infinite;
}
.h2 {
  width: 80%;
}
.over-content p {
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-font-family: Bahnschrift;
  font-family: Bahnschrift;
}
.over-content label {
  color: #fff;
  font-size: 30px;
  font-weight: 100;
  -webkit-font-family: Arial, Helvetica, sans-serif;
  font-family: Arial, Helvetica, sans-serif;
}
.over-content-title {
  -webkit-font-family: Arial, Helvetica, sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.99rem;
  position: relative;
}
.main-btn {
  background-color: #fff;
  padding: 0.6rem 2rem;
  color: #464646;
  border: none;
}
.primary-btn {
  font-size: 1.2rem;
  text-transform: uppercase;
}
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}
.enter {
  opacity: 1;
  transform: translateY(0px);
}
.main-brand {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.new-tag-btn:hover {
  -webkit-animation: none;
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(172, 129, 45, 0.7);
  }

  70% {
    transform: scale(1.4);
    box-shadow: 0 0 0 10px rgba(59, 57, 162, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 57, 162, 0);
  }
}
</style>
