<template>
  <div id="SignUp">
    <div class="signup-block">
      <p class="welcome-text">Get Started</p>
          {{feedback}}
          <input class="default-input login-input" v-model="fname" placeholder="First Name"/>
          <input class="default-input login-input" v-model="lname" placeholder="Last Name"/>
          <input class="default-input login-input" v-model="username" placeholder="Username"/>
          <input class="default-input login-input" v-model="email" placeholder="Email"/>
          <input
          class="default-input
          login-input"
          v-model="password"
          type="password"
          placeholder="Password"/>
          <input
            class="default-input login-input"
             v-model="password2"
            type="password"
            placeholder="Confirm Password"/>

            <div class="processing-prep" v-if="!processing">
              <button @click.prevent="checkVerification()"  class="login-btn" >Signup</button>
            </div>

          <div v-else-if="processing">
            <vue-spinner class="process-animation" :processing="processing" color="#64B6DD" size="60px"></vue-spinner>
          </div>
    <p class="signup-link-text">Already have an account? <router-link class="signup-link" :to="{name: 'Login'}">Login</router-link></p>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {mapState, mapGetters, mapActions } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default{
  components:{
    'vue-spinner': PulseLoader
  },
  name: 'SignUp',
  data(){
    return{
      username:'',
      fname:'',
      lname: '',
      email:'',
      password: '',
      password2: '',
      address: {
        line1: '',
        line2:'',
        city:'',
        state:'',
        zip:''
      },
      role: 'free',
       processing: false,
       feedback:''
    }
  },
  computed:{
    ...mapGetters([
      'isAuthenthicated'
    ]),
  },
  methods:{
    checkVerification(){
      let illegalChars = /\W/;
      if(this.password != this.password2){
        this.feedback = "Passwords do not match"
      }else if(this.username.length < 3){
          this.feedback = "Username must be more than 3 characters"
      }else if(illegalChars.test(this.username)){
          this.feedback = "Please enter valid a Username. Use only numbers, underscores, and alphabets."
      }else{
        this.processing = true
        this.register()
      }
    },
    register(){
      let payload = {
        'fname': this.fname.toLowerCase(),
        'lname': this.lname.toLowerCase(),
        'username': this.username.toLowerCase(),
        'email':  this.email.toLowerCase(),
        'role': this.role.toLowerCase(),
        'phone': '',
        'address': this.address,
        'password': this.password,
      }
      if(this.password == this.password2){
        axios.post(this.$api_path+'skip_source/users/register', payload)
        .then(res=>{
          console.log(res.data)
          if(res.data.status == 'success'){
            this.processing = false
            this.$router.push({name: 'Login'})
          }else{
            this.feedback = res.data.feedback
            this.processing = false
          }
        }).catch(err => {
          this.processing = false
          console.log(err)
        })
      }else{
        this.feedback = "Passwords do not match"
      }
    },
    directLogin(email, password){
    let payload = {
        email: email,
        password: password
      }
      this.login(payload).then(response => {
        this.processing = false
        this.$router.push({name: 'Dashboard'})
      })
    },
    ...mapActions([
      'login'
    ]),
  },
}
</script>
<style scoped>
#SignUp{
  max-width: 900px;
  margin: 3rem auto;
}
.signup-block{
  margin-top: -1rem
}
.login-input{
  border-radius: 10px;
  padding: .6rem .6rem;
  width: 60%;
  font-size: .7rem;
  margin: .5rem 0;
  transition: 1s;
}
.input-2-group{
  display: flex;
  width: 100%;
}
.input-2-group input{
  width: 35%;
  font-size: .7rem;
  padding: .6rem .4rem;
}
.login-btn{
  background-color: var(--primary);
  text-align:center;
  border: none;
  border-radius: 10px;
  font-size: .9rem;
  padding: .6rem .6rem;
  color: #fff;
  width: 60%;
  margin: 2rem auto;
  cursor: pointer;
  transition: 1s;
}
.signup-link-text{
  font-size: .7rem;

}
.signup-link{
  font-size: .7rem;
  cursor: pointer;
  color: var(--primary);
}
.flat-mountains{
  width: 100%;
  height: 100vh;
  overflow:hidden;
  background-attachment: fixed;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
}
#signup_carousel{
  margin: 2rem auto;
  min-height: 20rem;
}
.example{color: #aaa; font-size: .7rem;}
.brand-area{
  width: 100%;
  text-align:center;
  color: var(--primary);
}
.logo-brand{
  width: 80%;
  margin: 2rem auto;
  transition:1s;
}
.sunsteam-img{
  border-radius: 100%;
  position:absolute;
  width: 250%;
  top: -200%;
  left: -130%;
  opacity: .4;
}
@media only screen and (max-width: 600px) {
  #SignUp{
    margin: 3rem;
  }
  .input-group{
    width: 75%;
  }
  .head-text{
    font-size: 1.2rem;
    font-weight:bold;
  }
  .login-input{
    width: 80%;
    font-size: .9rem;
  }
}
/* TINY */
@media only screen and (max-width: 400px) {
}
</style>
