<template>
  <div id="CardCheckout">
    <button class="bg-yellow-200 p-3 text-yellow-600" @click="setDefaultInfo()" v-if="testingMode">Set Default Information</button>
    <p class="total-text">
      Your Total is: ${{(total/100).toLocaleString()}}
    </p>
       <div v-if="processing && !feedback">
         <em>Please Wait. Your order is being processed...</em>
         <!-- <div class="loading-box">
           <img src="@/assets/gif/truck-loading-animation.gif" />
         </div> -->
       </div>
       <div v-else-if="!processing && feedback">
         <div>{{feedback}}</div>
       </div>
       <div v-else-if="!processing">

           <div class="card-info">
             <div v-show="errors">
               <ol class="card-errors">
                 <li v-for="(error, index) in errors" :key="index">
                   {{ error }}
                 </li>
               </ol>
             </div>

             <div class="checkout-inputs">

               <div class="input-group" :style="requiredStyle">
                     <label>Email</label>
                     <input type="text"
                            class="form-input"
                            placeholder="Enter your email here..."
                            v-model="email"
                            required>
                </div>

               <div class="input-group" :style="requiredStyle">
                     <label>Cardholder</label>
                     <input type="text"
                            class="form-input"
                            placeholder="John Doe"
                            v-model="cardholder"
                            required>
                </div>
                <div class="input-group" :style="requiredStyle">
                     <label>Credit/Debit Card Number</label>
                     <input type="text"
                            class="form-input"
                            placeholder="XXXXXXXXXXXXXXXX"
                            v-model="card.number"
                            required>
                </div>
                  <div class="input-group" :style="requiredStyle">
                     <label>CVC Number</label>
                     <input type="text"
                            class="form-input"
                            placeholder="CVC"
                            v-model="card.cvc"
                            required>
                  </div>
                  <div class="input-group" :style="requiredStyle">
                     <label>Expiration Date</label>
                         <input type="text"
                                class="form-input"
                                placeholder="MM/YY"
                                v-model="card.exp"
                                required>
                  </div>
                 </div>
           </div>
             <div class="btn-group">
               <button class="bg-blue-400 text-white text-3xl rounded py-3 px-4 w-1/2 mx-auto mt-10"
                       :class="{'bg-gray-600': !stripeCheck}"
                       @click.prevent="validate"
                       :disabled="stripeCheck"
                       >Purchase</button>
             </div>
             </div>
  </div>
</template>
<script>
import axios from 'axios'
import {mapState} from 'vuex'

export default{
  name: 'CardCheckout',
  props: ['total', 'userID', 'productdata'],
  data(){
    return{
      feedback:null,
      status:'danger',
      checkout_title: 'Your Total is $3,000',
      processing: false,
      cardholder: '',
      email: '',
      card: {number: '',cvc: '',exp: ''},
      requiredStyle:{
        'border': '1px solid #aaa',
      },
      errors: [],
      stripeCheck: false,
    }
  },
  methods:{
    makePurchase(token, cardholder){
      this.processing=true;
      var d = new Date()
      var epoch_time = Math.round(d.getTime() / 1000)
      let payload = {
        token:token,
        cardholder:cardholder,
        created: epoch_time,
        email: this.email,
        buyer_id: 'guest',
        amount: this.total,
        productdata: this.productdata,
      }

      let api = `${this.$api_path}skip_source/cashbuyers/`
      if(this.testingMode == true){
         api = `${this.$api_path}skip_source/cashbuyers/test`
      }

      axios.post(api, payload)
      .then((res)=>{
        this.processing=false;
        console.log(res.data)
        this.feedback = res.data.feedback
        setTimeout(() =>{
          this.$emit('saleMade', true)
         }, 2000);
      }).catch((err)=>{
        this.processing=false;
        this.feedback = 'Sorry, we could not process your payment at this time. We are experiencing a server issue.'
        console.log(err)
      })
    },
    setDefaultInfo(){
      this.email = 'bivegoh770@elastit.com'
      this.cardholder= 'James Kang'
      this.card.number= '4242424242424242'
      this.card.cvc= '121'
      this.card.exp= '10/22'
    },
    validate() {
      this.processing=true
      this.errors = [];
      let valid = true;
      this.requiredStyle = {'border': '2px solid #cc0000'}
      if (!this.cardholder) {
        valid = false;
        this.processing=false;
        this.errors.push('Cardholder information is required');
      }
      if (!this.card.number) {
        valid = false;
        this.processing=false;
        this.errors.push('Card Number is required');
      }
      if (!this.card.cvc) {
        valid = false;
        this.processing=false;
        this.errors.push('CVC is required');
      }
      if (!this.card.exp) {
        valid = false;
        this.processing=false;
        this.errors.push('Expiration date is required');
      }
      if (valid) {
        this.requiredStyle = {'border': '1px solid #aaa'}
        this.createToken();
      }
    },
    createToken() {
      this.stripeCheck = true;
      // this.addPostToDB(this.post_que)
      window.Stripe.setPublishableKey(this.stripeTracePubKey);
      window.Stripe.createToken(this.card, (status, response) => {
        if (response.error) {
          this.stripeCheck = false;
          this.errors.push(response.error.message);
          console.error(response);
        } else {
          const token = response.id
          const cardholder = this.cardholder
          this.makePurchase(token, cardholder)
        }
    });
    },
  },
  mounted(){
    // if(this.payment_choice == 'finance'){
    //   this.checkout_title = `One time downpayment of $${this.dueNow}`
    // }else if(this.payment_choice == 'full_pay'){
    //     this.checkout_title = `One time payment of $${this.dueNow}`
    // }
  },
  computed:{
    dueNow(){
      let due = 0

      return due
    },
    ...mapState(['stripeTracePubKey', 'testingMode'])
  }
}
</script>
<style scoped>
#CardCheckout{
  padding: 1.5rem;
  margin: 0 auto;
  width: 80%;
  transition: 1s;
}
.test{
  font-size: 10px;
  color: #777;
}
.price-text{
  float: right;
  font-size: 1.1rem;
}
.total-text{
  font-weight: bold;
  font-size: 1.7rem;
  margin: 2rem auto;
}
.appointment-btn{
  cursor:pointer;
  text-transform: uppercase;
  padding: 1.2rem;
  font-size: 16px;
  margin-top: 3rem;
  width: 80%;
  color: #fff;
  border: none;
  border-radius: 12px;
  background-color: var(--link-light);
}
.checkout-inputs{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card-info h3{
  margin: 2rem auto;
  font-size: 1.5rem;
}
.input-group{
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem auto;
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: .5rem 1.2rem;
}
.checkout-inputs label{font-size: 15px; text-align: left; width: 15rem;}
.form-input{
  padding: .5rem 1.2rem;
  border: none;
  width: 100%;
  text-align: right;
  transition: 1s;
}
.form-input:focus{
    outline: none;
}
.btn-group{
  display:flex;
  width:100%;
  justify-content:center;
  margin: 0 auto;
  transition: 1s;
}
.buy-btn{
  background-color: var(--success);
}
.cancel-btn{
  background-color: var(--bg-dark);
}
.card-errors{
  color: var(--danger);
  list-style-type: none;
}
.financing-agreement{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  margin: 1rem auto;
}
.financing-agreement input{
  width: 3rem;
  height: 3rem;
}
.financing-agreement span{
  margin-left: 1rem;
  font-size: .8rem;
  text-align: left;
}
.loading-box{
  width: 30%;
  margin: 2rem auto;
}
.loading-box img{
  width: 100%;
}
.default-btn{
  border: 2px solid #aaa;
  background-color: #efefef;
}
@media screen and (max-width: 600px) {
  .checkout-inputs{
    width: 90%;
  }
  .checkout-inputs label{
    margin-top:1rem;
    font-size: .7rem;
  }
  .form-input{
    font-size: .7rem;
  }
  .btn-group{
    width:100%;
  }
  .buy-btn{
    padding:.5rem .8rem;
    font-size :.6rem;
  }
  .cancel-btn{
    padding:.5rem .8rem;
    font-size :.6rem;
  }
  .default-info-btn{
    padding:.5rem .8rem;
    font-size :.6rem;
  }
}
@media screen and (max-width: 390px) {
  .checkout-inputs label{font-size: 8px;}
  .input-group{padding: .5rem .2rem;  }
  .form-input{
    padding: .5rem 1rem;
    border-radius: 8px;
  }
  .test{
    font-size: 7px;
  }
  .price-text{
    float: right;
    font-size: 1.1rem;
  }
}
</style>
