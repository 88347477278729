<template>
  <div id="HowItWorks">
    <div class="how-it-works-img">
      <video id="skip-video" autoplay="true" loop="true">
        <source src="@/assets/media/video/IMG_1791.mp4" type="video/mp4">
      </video>

    </div>
    <div class="how-it-works-text">
      <h1>Why Skip Source?</h1>
        <p>Skip Source provides fast, high quality, bulk data for the best price! Our company prides itself on the accuracy and efficiency of our skip tracing process making us more reliable than other skip tracing companies. We believe our solution, standards and business practices make us number one in the industry.</p>
        <br />
        <ul>
          <li>Innovative SKIP SOURCE: RVM & TEXT BLAST SOFTWARE</li>
          <li>We Offer Distressed Seller List</li>
          <li>We Offer Cash Buyers List</li>
          <li>No Long Term Contracts</li>
          <li>Top Tier Data</li>
          <li>Bulk Rate Discounts</li>
          <li>Direct Mail Discounts</li>
          <li>Free Resources</li>
          <li>Award Winning Customer Service</li>
        </ul>
        <!-- <router-link :to="{name: 'HowItWorks'}" class="link">Here is How It Works</router-link> -->
    </div>
  </div>
</template>
<script>
import {mapGetters,mapMutations} from "vuex"

export default {
  components: {
  },
  name: 'HowItWorks',
  data(){
    return{
      window:{},
      isMobile: false,
    }
  },
  methods:{

  },
}
</script>
<style scoped>
#HowItWorks{
  width: 90%;
  margin: 8rem auto;
  text-align: center;
  transition: 1s;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 2px;
  justify-items: stretch;
  align-items: stretch;
}
.how-it-works-text h1{
  font-size: 2rem;
  margin: 2rem 0;
}
.how-it-works-text p{
  font-size: 1.1rem;
}
.how-it-works-img{
}
#skip-video{
  width: 80%;
}
.img-pic{
  min-width: 20rem;
  width: 80%;
  transition: 1s;
}
.how-it-works-text{
  width: 90%;
  text-align: left;
  font-weight: bold;
  color: #000;
}
.link{
  text-decoration: none;
  color: #fff !important;
}
@media only screen and (max-width: 600px) {
  #HowItWorks{
    width: 90%;
    margin: 4rem auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .img-pic{
    margin-top: 2rem;
    width: 10rem;
  }
  .how-it-works-text h1{
    font-size: 1.2rem;
    margin: 2rem 0;
  }
  .how-it-works-text p{
    font-size: .8rem;
    margin: 2rem 0;
  }
}
@media only screen and (max-width: 400px) {
  .how-it-works-text p{
    font-size: .6rem;
    margin: 1rem 0;
  }
}
</style>
