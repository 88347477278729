<template>
  <div id="">
    <slot />
  </div>
</template>
<script>
export default{

}
</script>
<style scoped>
</style>
