import axios from 'axios'
import jwtDecode from 'jwt-decode';

const apiPath = "https://skipsource.skylineexecutive.com/"
// const localPath = "http://localhost:5000/"
const api_path = apiPath
export default {
  login(context, payload) {
    context.commit('PROCESS_DATA', true)
    context.commit('FEEDBACK_DATA', null)
    return new Promise((resolve, reject) => {
      axios.post(api_path + 'skip_source/users/login', payload)
        .then((res) => {
          if (res.status == 200 && res.data.status == 'success') {
            let accessToken = res.data.token;
            localStorage.setItem('usertoken', accessToken)
            const decode = jwtDecode(accessToken)
            const uid = decode.identity.user_id
            const role = decode.identity.role
            localStorage.setItem('uid', uid)
            localStorage.setItem('role', role)
            context.commit('AUTH_SUCCESS', accessToken, uid, role)
            context.commit('PROCESS_DATA', false)
            context.commit('FEEDBACK_DATA', res.data.feedback)
            context.commit('FEEDTYPE_DATA', res.data.status)
            resolve(res);
          } else if (res.status == 200 && res.data.status == 'danger') {
            context.commit('PROCESS_DATA', false)
            context.commit('FEEDBACK_DATA', res.data.feedback)
            context.commit('FEEDTYPE_DATA', res.data.status)
          } else {
            context.commit('PROCESS_DATA', false)
            context.commit('FEEDBACK_DATA', 'Login Failed, please try again')
            context.commit('FEEDTYPE_DATA', 'danger')
          }
        })
        .catch(err => {
          context.commit('PROCESS_DATA', false)
          context.commit('FEEDBACK_DATA', null)
          localStorage.removeItem('usertoken');
          context.commit('AUTH_ERROR');
          // console.log(err);
          reject(err);
        })
    })
  },
}
