import Vue from "vue";
import VueRouter from "vue-router";
import Land from "../landing/Land.vue";
import Privacy from "../views/docs/Privacy.vue";
import Terms from "../views/docs/TermsConditions.vue";
import ReturnPolicy from "../views/docs/ReturnPolicy.vue";
import Disclaimer from "../views/docs/Disclaimer.vue";
import AboutUs from "../views/docs/AboutUs.vue";
import HowItWorks from "../views/docs/HowItWorks.vue";
import FreeResources from "../views/docs/FreeResources.vue";
import PreprocessedList from "../views/docs/more-products/PreprocessedList.vue";
import WholesaleGlossary from "../views/docs/free-resource/WholesaleGlossary.vue";
import RVMScript from "../views/docs/free-resource/RVMScript.vue";
import TextBlastTemplates from "../views/docs/free-resource/TextBlastTemplates.vue";
import GateAuth from "../views/gate/GateAuth.vue";
import MoreProducts from "../views/MoreProducts.vue";
import NotFound from "@/views/docs/NotFound.vue";
import CashbuyerList from "@/views/cashbuyers/CashbuyerList.vue";
//Gate
import Login from "../views/gate/Login.vue";
import Signup from "../views/gate/Signup.vue";
import ForgotPassword from "../views/gate/ForgotPassword.vue";
import ResetPassword from "../views/gate/ResetPassword.vue";
//Dashboard
import Dashboard from "../views/Dashboard.vue";
//Childeren
import Overview from "../views/dashboard/Overview.vue";
import SkipTrace from "../views/dashboard/SkipTrace.vue";
import Payments from "../views/dashboard/Payments.vue";
import Affiliate from "../views/dashboard/Affiliate.vue";
import DashboardSupport from "../views/dashboard/DashboardSupport.vue";
import Account from "../views/dashboard/Account.vue";
//Docs
import HelpCenter from "../views/help/HelpCenter.vue";
import Contact from "../views/docs/Contact.vue";
import FAQ from "../views/docs/FAQ.vue";
import Support from "../views/docs/Support.vue";
import BlogPage from "../views/docs/BlogPage.vue";
//minutes
import MinutesHello from "@/views/minutessell/MinutesHello.vue";
import MinutesOrder from "@/views/minutessell/MinutesOrder.vue";
//squeeze
import SqueezePage from "@/views/squeeze/SqueezePage.vue";
import SqueezeCheckout from "@/views/squeeze/SqueezeCheckout.vue";
import SqueezeConfirmation from "@/views/squeeze/SqueezeConfirmation.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Land",
    component: Land,
  },
  {
    path: "/how-it-works",
    name: "HowItWorks",
    component: HowItWorks,
  },
  {
    path: "/free-resources",
    name: "FreeResources",
    component: FreeResources,
  },
  {
    path: "/more-products/preprocessed-lists",
    name: "PreprocessedList",
    component: PreprocessedList,
  },
  {
    path: "/free-resources/wholesale-glossary",
    name: "WholesaleGlossary",
    component: WholesaleGlossary,
  },
  {
    path: "/free-resources/rvm-script",
    name: "RVMScript",
    component: RVMScript,
  },
  {
    path: "/free-resources/text-blast-templates",
    name: "TextBlastTemplates",
    component: TextBlastTemplates,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/return-policy",
    name: "ReturnPolicy",
    component: ReturnPolicy,
  },
  {
    path: "/disclaimer",
    name: "Disclaimer",
    component: Disclaimer,
  },
  {
    path: "/more-products",
    name: "MoreProducts",
    component: MoreProducts,
  },
  {
    path: "/gate-auth",
    name: "GateAuth",
    component: GateAuth,
    meta: { layout: "nolayout" },
    children: [
      // {
      //   path: '/login',
      //   name: 'Login',
      //   component: Login,
      //   meta: { layout: 'nolayout' },
      // },
      // {
      //   path: '/signup',
      //   name: 'Signup',
      //   component: Signup,
      //   meta: { layout: 'nolayout' }
      // },
      // {
      //   path: '/forgot-password',
      //   name: 'ForgotPassword',
      //   component: ForgotPassword,
      //   meta: { layout: 'nolayout' }
      // },
      // {
      //   path: '/reset-password/:reset_token',
      //   name: 'ResetPassword',
      //   component: ResetPassword,
      //   meta: { layout: 'nolayout' }
      // },
    ],
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
  },
  {
    path: "/app",
    name: "Dashboard",
    component: Dashboard,
    meta: { layout: "nolayout" },
    children: [
      {
        path: "/app",
        name: "Overview",
        component: Overview,
        meta: { layout: "nolayout" },
      },
      {
        path: "/app/skip-trace",
        name: "SkipTrace",
        component: SkipTrace,
        meta: { layout: "nolayout" },
      },
      {
        path: "/app/payments",
        name: "Payments",
        component: Payments,
        meta: { layout: "nolayout" },
      },
      {
        path: "/app/support",
        name: "DashboardSupport",
        component: DashboardSupport,
        meta: { layout: "nolayout" },
      },
      {
        path: "/app/affiliate",
        name: "Affiliate",
        component: Affiliate,
        meta: { layout: "nolayout" },
      },
      {
        path: "/app/accounts",
        name: "Account",
        component: Account,
        meta: { layout: "nolayout" },
      },
    ],
  },
  {
    path: "/squeeze",
    name: "SqueezePage",
    component: SqueezePage,
    meta: { layout: "nolayout" },
  },
  {
    path: "/squeeze/checkout",
    name: "SqueezeCheckout",
    component: SqueezeCheckout,
    meta: { layout: "nolayout" },
  },
  {
    path: "/squeeze/confirmed",
    name: "SqueezeConfirmation",
    component: SqueezeConfirmation,
    meta: { layout: "nolayout" },
  },
  {
    path: "/minutes",
    name: "MinutesHello",
    component: MinutesHello,
    meta: { layout: "nolayout" },
  },
  {
    path: "/minutes/order",
    name: "MinutesOrder",
    component: MinutesOrder,
    meta: { layout: "nolayout" },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/blog/:slug",
    name: "BlogPage",
    component: BlogPage,
  },
  {
    path: "/buyers-list",
    name: "CashbuyerList",
    component: CashbuyerList,
  },
  {
    path: "/help",
    name: "HelpCenter",
    component: HelpCenter,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
