<template>
  <div id="SupportSkipTraceTab">
    <div class="dashboard-header">
      <h2>Skip Trace Support</h2>
    </div>
    <div class="search-table">
      <div class="input-group">
        <input placeholder="What would you like assitance with?" v-model="support_query" />
        <button class="trace-btn" @click="searchData()">Search</button>
      </div>
    </div>

    <div v-if="filteredResutls.length == 0">
      There is no information tailored to your search. You may need to file a support ticket for us to help you with your concerns!
    </div>
    <div v-else class="results">
      <div v-for="(res, idx) in filteredResutls" :key="idx" class="result">
        <div class="result-brief" @click="toggleSupportDropdown(idx)">
          <span  class="result-brief-text">
            <span class="status-icon" v-if="showResDropdown == idx">&times;</span>
            <span class="status-icon" v-else>&plus;</span>
          {{res.title}}</span>
        </div>
        <div class="result-dropdown" v-if="showResDropdown == idx">
          <div v-html="res.content"></div>
          <small  class="time-indicator" @click="changeDateFormat()">Last Updated: {{dateFormat(res.last_updated)}}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {rtdb} from '@/_firebase/init';
export default {
  name: 'SupportSkipTraceTab',
  components:{
  },
  data(){
    return{
      support_query:'',
      date_format_text: 'relative',
      results: [
        {title: 'Account is buggy', content: `<p>You may need to reset your account</p>`, last_updated: 1608003188},
        {title: 'I can\'t find my payment reciept', content: `<p>Please check the last box in Payments</p>`, last_updated: 1608004436,}
      ],
      showResDropdown: -1,
    }
  },
  methods:{
    toggleSupportDropdown(idx){
      this.showResDropdown = idx
    },
    getSupportInformation(){
      this.loading = true
      this.results = []
      return rtdb.ref(`/support/skip_trace`).on('value', (snapshot)  => { snapshot.forEach((childSnapshot) => {
        // childSnapshot.val()
        this.results.push(childSnapshot.val())
        this.loading = false
      });
    })
    },
    searchData(){
      console.log(this.support_query)
    },
    changeDateFormat(){
      if(this.date_format_text == 'relative'){
        this.date_format_text = 'normal'
      }else{
        this.date_format_text = 'relative'
      }
    },
    dateFormat(timestamp){
      if(this.date_format_text == 'normal'){
        return this.$localDate(timestamp)
      }else{
        return this.$relativeDate(timestamp)
      }
    }
  },
  computed:{
    filteredResutls(){
       let parsed_title = this.results.filter((x)=>{
           return x.title.includes(this.support_query)
       })
       let parsed_content = this.results.filter((x)=>{
           return x.content.includes(this.support_query)
       })
       return [...new Set([...parsed_title, ...parsed_content])];
    }
  },
  created(){
    this.getSupportInformation()
    // for(let i = 0; i < 40; i++){
    //   this.results.push({title: 'I can\'t find my payment reciept', content: `<p>Please check the last box in Payments</p>`, last_updated: 1608004436,})
    // }
  }
}
</script>
<style scoped>
#SupportSkipTraceTab{
  padding: 2rem;
  width: 90%;
  min-height: 80vh;
  background-color: #fff;
  border-radius:15px;
}
.search-table{
  display: flex;
  width: 100%;
  max-height: 3rem;
  align-items: flex-end;
  justify-content: flex-end;
}
.input-group {
  display: flex;
  width: 70%;
  justify-content: flex-end;
  align-items:center;
}
.input-group input{
  transition: 1s;
  width: 70%;
  padding: .5rem 1rem;
  border: 1px solid #aaa;
  border-radius: 5px;
}
.trace-btn{
  padding: .5rem 1.3rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 5px;
  cursor:pointer;
}
/* RESULT AREA */
.results{
  max-height: 50vh;
  overflow-y: auto;
  border: 2px solid #eee;
}
.result{
  width: 100%;
  margin:0;
  cursor:pointer;
  position: relative;
}
.result-brief{
  padding: 0;
  width: 100%;
  border-bottom: 2px solid #eee;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  height: 3rem;
  z-index: 2;
}
.result-brief-text{
  margin-left: 1rem;
}
.status-icon{
  font-size: 1.2rem;
  font-weight: bold;
}
.result-dropdown{
  padding: 2rem 1rem;
  background-color: #f3f3f3;
  position: relative;
}
.time-indicator{
  position: absolute;
  right: 1rem;
  bottom: .9rem;
  cursor:pointer;
}
/* RESULT AREA */
</style>
