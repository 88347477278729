<template>
  <div id="Payments">
    <div class="dashboard-header">
      <h1>Plans and Subscription</h1>
    </div>

    <div class="user-bal-box">
      <p class="head-text">Current Balance: ${{(user_stats.cash_balance/100).toFixed(2)}}</p>
      <br/>

      <div>
        <label for="price" class="block text-sm font-medium text-gray-700">Price: </label>
        <div class="border border-gray-300 rounded mt-1 relative rounded-md shadow-sm w-1/2 p-2">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">
              $
            </span>
          </div>
          <input type="text" name="price" id="price" class="outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00"  v-model="total"/>
          <div class="absolute inset-y-0 right-2 flex items-center text-gray-400">
            USD
          </div>
        </div>
      </div>


      <p class="danger" v-if="feedback != ''">{{feedback}}</p>
      <br />

      <div class="btn-group">
        <button class="primary-btn" @click="toggleBalance(true)" v-if="!addingFunds">Add Balance</button>
        <button class="primary-btn cancel-btn" @click="toggleBalance(false)" v-else>Cancel</button>
      </div>

      <!-- <p class="danger">You need to Add a Card to Recharge Your Account!</p> -->
    </div>
    <br />

    <AddACard v-if="addingFunds" :total="total" :email="email" @cancelCheckout="toggleBalance" />

    <SubscriptionPlans v-if="!addingFunds" @selectMembership="selectMembership" :userdata="userdata"/>
    <div class="contact-notice">
      <p>If you have any questions about pricing, memberships, or accounts, please reach us through the contact form.</p>
      <a href="/contact">Contact us</a>
    </div>
    <SubscribeToMembership :membership="membership" v-if="isCheckingOut" />
    <!-- <PaymentDetails /> -->

</div>
</template>

<script>
import {fb, db} from '@/_firebase/init';
import AddACard from './payments/AddACard.vue'
import SubscriptionPlans from './payments/SubscriptionPlans.vue'
import SubscribeToMembership from './payments/SubscribeToMembership.vue'
import {mapState} from 'vuex'
// import PaymentDetails from './payments/PaymentDetails.vue'

export default {
  name: 'Payments',
  components:{
    AddACard,
    SubscriptionPlans,
    SubscribeToMembership
    // PaymentDetails
  },
  data(){
    return{
      email: '',
      total: 0,
      addingFunds: false,
      feedback: '',
      // showBalance: false,
      user_stats:{
        cash_balance: 0
      },
      userdata: {},
      membership: {},
      isCheckingOut: false,
    }
  },
  methods:{
    selectMembership(val){
      this.membership = val
      this.isCheckingOut = true
    },
    async getUserData(){
      const userStatsRef = db.collection('users').doc(this.userID);
      const doc = await userStatsRef.get();
      if (!doc.exists) {
      console.log('No such document!');
      } else {
        console.log(this.user_stats)
        this.email = doc.data().email
        this.userdata = doc.data()
      }
    },
    async getUserStats(){
      const userStatsRef = db.collection('user_stats').doc(this.userID);
      const doc = await userStatsRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        this.user_stats = doc.data()
      }
    },
    toggleBalance(bool){
      if(this.total < 5){
        this.feedback = 'You must enter a value that is at least $5'
      }else{
        this.feedback == ''
        this.addingFunds = bool
      }

    }
  },
  computed:{
    ...mapState(['userID'])
  },
  mounted(){
    this.getUserData()
    this.getUserStats()
  }
}
</script>
<style scoped>
#Payments{
  padding: 2rem;
  width: 90%;
  min-height: 80vh;
  background-color: #fff;
  border-radius:15px;
}
.user-bal-box{
  /* width:50%; */
  padding: 2rem;
  margin: 2rem 1rem;
  padding: 1rem;
  min-height: 7rem;
  border-radius: 15px;
  border: 2px solid #e4e4e4;
  position: relative;
  overflow:hidden;
}
.cancel-btn{
  background-color: #aaa;
}
.head-text{
  color: var(--link-light);
  font-size: 1.6rem;
  margin-left: 1rem;
  transition: 1s;
}
.input-group{
  display:flex;
  justify-content: flex-start;
  align-items:center;
}
.input-group span{
  background-color: #fff;
  border: 1px solid #aaa;
  width: 1rem;
  height: 1rem;
  border-radius: 6px;
  padding: .5rem .5rem;
  text-align:center;
}
.default-input{
  padding: .5rem .5rem;
  width: 40%;
  border: 1px solid #aaa;
  border-radius: 6px;
  transition:1s;
  margin:0;
}
.input-group input[type=number]{
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: none;
}
.plan-box{
  width:50%;
  margin: 2rem 1rem;
  background-color: #ecf2f9;
  transition: 1s;
  text-align: center;
  padding: 3rem;
  border-radius: 20px;
}
.head-text{
  font-size: 1.3rem;
}
.head-text2{
    font-size: 1.2rem;
}
.contact-notice{
  width: 80%;
  margin: 1rem auto;
  text-align:center;
}
</style>
