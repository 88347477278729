<template>
  <div id="Affiliate">
    <div class="dashboard-header">
      <h2>Affiliate</h2>

    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: 'Affiliate',
  components:{
  },
  data(){
    return{
      search_query:''
    }
  }
}
</script>
<style scoped>
#Affiliate{
  padding: 2rem;
  width: 90%;
  min-height: 80vh;
  background-color: #fff;
  border-radius:15px;
}
</style>
