<template>
  <div id="FAQ">
    <h2>FAQ</h2>
    <div class="faq-list container">
      <div class="faq-content" v-for="(pair, index) in q_a" :key="index">
        <p class="question-text">{{pair.q}}</p>
        <p>{{pair.a}}</p>
      </div>
  </div>

  <div class="container text-default text-center">
    <small>Have a question you want to ask, but it is not on here? Ask us <router-link :to="{name: 'Contact'}">here!</router-link></small>
  </div>
  </div>
</template>

<script>

export default {
  name: 'FAQ',
  components: {
  },
  data(){
    return{
      q_a: [
        {q: "AM I BILLED PER HIT RATE OR PER RECORD SUBMITTED?",
        a: "Yes, you will be billed per record submitted. The results will depend on the accuracy of the information submitted, this is a per search service."},
        {q: "Is there a minimum order?",
        a: "Yes, 500 records."},
        {q: "Is there a certain format? ",
        a: "Yes, ALL records must be in CSV format and in the template attached. We have provided an example of the template for you. "},

        {q: "Do you have a refund policy?",
        a: "We do not offer any refunds on our products as of right now. Once an order is submitted, we are not able to offer any cancellations."},

        {q: "Can you skip trace Businesses: Such as, LLC's, etc..?",
        a: "Yes, We offer that service in our Elite Membership. "},

        {q: "Do you offer individual searches?",
        a: "Unfortunately, we only offer bulk skip tracing right now"},

        {q: "How many numbers and emails will I get per record? ",
        a: `Our superior data and elite skip tracing provides: a minimum of three phone numbers and three emails per record. `},

        {q: "HOW DO I PAY? ",
        a: `Step one would be to create an account, we take all major forms of Debit Cards & Credit Cards. `},
        {q: "WHAT IS YOUR HIT RATE?",
        a: `Our typical hit rate is 75%-80%, depending on the quality of the list. The better the quality of the list, the higher the hit rate, the hit rate will be lower, with low quality list.`},

      ]
    }
  }
}
</script>

<style scoped>
#FAQ{
  margin: 2rem 4rem;
}
#FAQ h2{
  font-size: 2rem;
}
.faq-list{
  margin-left: 2rem;
  margin-right: 2rem;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.question-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #FAQ h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .faq-list{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
