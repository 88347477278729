<template>
  <div id="RVMBanner">
    <h1>RVM Banner goes here</h1>
  </div>
</template>
<script>
// import {mapGetters,mapMutations} from "vuex"
// import {db} from '@/_firebase/init'

export default {
  components: {
  },
  name: 'RVMBanner',
  data(){
    return{
    }
  },
  methods:{

  },
  created(){
  }
}
</script>
<style scoped>
#RVMBanner{
  width: 100%;
 background-color: var(--primary);
  margin: 8rem auto;
  padding: 2rem 0rem;
  text-align: center;
  transition: 1s;
  display: grid;
}
#recommended-carousel{
  min-height: 15rem;
  height: 100%;
}
.slide-space {
  max-width: 20rem;
  margin: .3rem;
  cursor:pointer;
  transition: 1s;
  background-color: #fff;
  border-radius: 20px;
}
.slide-space img{
  width: 90%;
  border-radius: 15px;
}
.slide-space:hover{
  transform: translate(-2px, -2px) scale(1.02);
}
.title{
  margin: .2rem auto;
  font-size: .8rem;
}
.blog-metabody{
  font-size: .7rem;
  color: #777;
}
.additional-info{
  font-size: .7rem;
  color: Var(--primary);
}
@media only screen and (max-width: 900px) {
  .slide-space img{
    width: 10rem;
  }
  .blog-metabody{
    font-size: .5rem;
  }
}
@media only screen and (max-width: 600px) {
  #RVMBanner{
    width: 90%;
    margin: 4rem auto;
  }

}
@media only screen and (max-width: 400px) {
  .how-it-works-text p{
    font-size: .6rem;
    margin: 1rem 0;
  }
}
</style>
