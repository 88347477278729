<template>
  <div id="Account">
    <div class="dashboard-header">
      <h2>Account</h2>
      <p>Our software is still in beta and more features are soon to be updated and added!</p>
      <br />
    </div>
    <div v-if="!editMode">
      <img :src="userdata.photo" class="profilephoto" />
      <h3>{{userdata.fname}} {{userdata.lname}}</h3>
      <p>{{userdata.username}}</p>
      <p>{{userdata.email}}</p>
      <button class="trace-btn" @click="toggleEditAccount(true)">Edit Account</button>
    </div>
    <div class="edit-account" v-else-if="editMode">
      <EditAccount :userdata="userdata" />
      <button class="trace-btn" @click="toggleEditAccount(false)">Save Changes</button>
    </div>
  </div>
</template>

<script>
import EditAccount from './account/EditAccount.vue'
import {mapState} from 'vuex'
import {db} from '@/_firebase/init'
export default {
  name: 'Account',
  components:{
    EditAccount
  },
  data(){
    return{
      editMode: false,
      search_query:'',
      userdata:{
        fname:'',
        lname:'',
        username: '',
        phone: '',
        photo: '',
      }
    }
  },
  methods:{
    toggleEditAccount(val){
      this.editMode = val
    },
    async getData(){
      const cityRef = db.collection('users').doc(this.userID);
      const doc = await cityRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        this.userdata = doc.data()
      }

    }
  },
  computed:{
    ...mapState(['userID'])
  },
  created(){
    this.getData()
  }
}
</script>
<style scoped>
#Account{
  padding: 2rem;
  width: 90%;
  min-height: 80vh;
  background-color: #fff;
  border-radius:15px;
}
.trace-btn{
  padding: .5rem 2rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 20px;
  cursor:pointer;
}
.profilephoto{
  border-radius: 100%;
  width: 10rem;
  height: 10rem;
}
.account-area{
  display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-template-rows: 1fr;
   grid-column-gap: 20px;
   grid-row-gap: 20px;
   justify-items: stretch;
   align-items: stretch;
  border-radius:10px;
  padding: 2rem;
  margin:2rem 0;
}
</style>
