<template>
  <div id="app">
    <component :is="layout">
      <div v-if="testingMode" class="test-announcement">YOU ARE IN TESTING MODE</div>
      <router-view />
    </component>
  </div>
</template>
<script>
import {mapState} from 'vuex'

const default_layout = "default";

export default {
  name: 'app',
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
    ...mapState(['testingMode'])
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
}
html, body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: var(--bg-default);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.primary-btn{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  background: #d00f0f;
  text-align:center;
  padding: 1rem 2rem;
  font-size:.9rem;
  border:none;
  margin: .5rem;
  color: #fff;;
  border-radius: 10px;
  transition: 1s;
  cursor: pointer;
}
.gradient{
  background: rgb(232,81,81);
  background: -moz-linear-gradient(180deg, rgba(232,81,81,1) 0%, rgba(208,15,15,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(232,81,81,1) 0%, rgba(208,15,15,1) 100%);
  background: linear-gradient(180deg, rgba(232,81,81,1) 0%, rgba(208,15,15,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e85151",endColorstr="#d00f0f",GradientType=1);
}
.test-announcement{
  position:absolute;
  background-color: #ff3333;
  border: 2px solid #000;
  top:0;
  right: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  text-align:center;
  padding: .25rem;
  color: #fff;
  font-weight: bold;
  z-index: 1000;
}
.danger{color: #ff3333;}
.success{ color: #17cf73;}
:root{
    --primary: #d00f0f;
    --secondary: #FDBD69;
    --tertiary: #F0592A;
    --success: #85e085;
    --bg-default: #FFFFFF;
    --dark: #000;
    --bg-secondary: #dedede;
    --text-light: #afafaf;
    --link-light: #00AFF0;
    --link-dark: #11607E;
    --bg-dark: #545454;
}

.trace-btn{
  padding: .5rem 2rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 20px;
  cursor:pointer;
}
.trace-btn:hover{
  color:#fff;
  background-color: #00AEEF;
}
</style>
