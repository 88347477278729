<template>
  <div id="WholesaleGlossary">

    <h1>Wholesale Glossary</h1>

    <div class="free-resource-list" v-for="(word, idx) in gloassary_list" :key="idx">
      <div class="free-resource">
        <p ><span class="term">{{word.term}}</span> - {{word.definition}}</p>
      </div>
    </div>

  <div class="container text-default text-center">
    <small>Want to add a term to our glossary? Let us know <router-link :to="{name: 'Contact'}">here!</router-link></small>
  </div>
  </div>
</template>
<script>

export default {
  name: 'WholesaleGlossary',
  components: {
  },
  data(){
    return{
      gloassary_list: [
        {term:'ARV',
        definition:'The value after a property is rehabbed and resold on the open market.'
      },
        {term:'Assessed Value',
        definition:'the value established for property tax purposes.'
      },
        {term:'Assignee',
        definition:'The person to whom an agreement or contract is sold or transferred.'
      },
        {term:'Assignment ',
        definition:'The method by which a right or contract is transferred.'
      },
        {term:'Assignor',
        definition:'The person who assigns or transfers an agreement or contract to another.'
      },
        {term:'Bird Dog',
        definition:'someone who identifies a potential good real estate investment opportunity and passes that deal on to another investor for a fee.'
      },
        {term:'Clear Title',
        definition:'a marketable title, one free of clouds and disputed interests.'
      },
        {term:'Closing',
        definition:'The formal meeting where loan documents are signed and funds disbursed. Note, however, that Federal law requires that funds not be disbursed for three business days on certain loans where personal residences serve as the security.'
      },
        {term:'Closing Costs',
        definition:'The expenses which borrowers incur to complete the loan transaction. These costs may include title searches, title insurance, closing fees, recording fees, processing fees and other charges.'
      },
        {term:'Closing Date',
        definition:'The date on which the seller delivers the deed and the buyer pays for the property.'
      },
        {term: 'Closing Statement',
        definition: 'An accounting of funds from a real estate transaction, also known as a HUD-1.'
      },
        {term: 'Contract',
        definition: 'An agreement between competent parties to do or not do certain things for consideration.'
      },
        {term: 'Contractor',
        definition: 'one who contracts to provide specific goods or services.'
      },
        {term: 'Convey',
        definition: 'to deed or transfer title to another.'
      },
        {term: 'Deed',
        definition: 'written document, properly signed and delivered, that conveys title to real property.'
      },
        {term: 'Due Diligence',
        definition: 'The act of carefully reviewing, checking and verifying all of the facts and issues before proceeding. In lending it is, among other things, verification of employment, income and savings; review of the appraisal; credit report; and status of the title.'
      },
      {term: 'Down Payment',
      definition: 'The portion of the purchase price paid by a buyer to a seller from sources of funds outside of those provided by a lender.'
      },
      {term: 'Earnest Money Deposit (EMD)',
      definition: 'a deposit made by a purchaser of real estate to show good faith.'
      },
      {term: 'Equity ',
      definition: 'The value of the unencumbered interest in real estate as determined by subtracting the total of the unpaid mortgage balances plus the sum of any current liens against the property from the property\'s fair market value.'
      },
      {term: 'Escrow',
      definition: 'an agreement between two or more parties providing that certain instruments or property be placed with a third party for safekeeping, pending the fulfillment or performance of a specified act or condition.'
      },
      {term: 'Escrow Account',
      definition: 'An account from which funds can be disbursed only for specified reasons; i.e. the money is held in trust for a specific use. In lending, these accounts are most often used to hold and disburse real estate taxes and hazard insurance premiums which have been paid in advance (usually on a monthly basis) by the borrower.'
      },
      {term: 'Estate',
      definition: 'legal proceeding by a lessor (landlord) to recover possession of property.'
      },
      {term: 'Eviction',
      definition: 'one who contracts to provide specific goods or services.'
      },
      {term: 'Exchange',
      definition: 'under Section 1031 of the IRS Tax Code, like-kind property used in a trade or business or held as an investment can be exchanged tax-free, subject to certain conditions.'
      },

      {term: 'Fee Agreement',
      definition: 'An agreement between a borrower and a broker which normally specifies the relationship between them and the amount of compensation to the broker.'
      },

      {term: 'FRBO',
      definition: 'for rent by owner.'
      },
      {term: 'FSBO',
      definition: 'for sale by owner.'
      },
      {term: 'Grantee',
      definition: 'the party to whom title to real property is conveyed.'
      },
      {term: 'Grantor',
      definition: 'the party who gives the deed'
      },
      {term: 'Hard Money Loan',
      definition: 'A loan that is underwritten with the condition and value of the property as the primary criteria for approval. Secondary issues may include the credit of the borrower, the ability of the borrower to repay the loan and/or the ability of the borrower to manage the property or successfully complete a rehab and sell the property. Owner occupancy, debt ratios and other issues are seldom a factor. Appraisals rather than purchase prices are used to determine value. Cash out purchases are often allowed and are another key benefit. These loans are usually approved within days and are often funded in two weeks or under with times as short as two or three days not uncommon. The cost for the benefits of speed of funding, lax underwriting and other advantages is typically a moderately high interest rate (usually low to mid teens) and high points (usually 5 to 10).'
      },
      {term: 'HUD-1 Statement',
      definition: 'A form used by a settlement or closing agent itemizing all charges imposed on a borrower and seller in a real estate transaction. This form gives a picture of the closing transaction, and provides each party with a complete list of incoming and outgoing funds. "Buyers" are referred to as "borrowers" on this form even if no loan is involved. The HUD-1 is also known as a "closing sheet" or "settlement form".'
      },
      {term: 'Joint Venture',
      definition: 'an agreement between two or more persons who invest in a single business or property.'
      },
      {term: 'Lien',
      definition: 'A claim on a property of another as security for money owed. Examples of types of liens would include judgments, mechanic\'s liens, mortgages and unpaid taxes.'
      },
      {term: 'Mortgage',
      definition: 'A lien against real property given by a borrower to a lender as security for money borrowed.'
      },
      {term: 'Probate',
      definition: 'The process of establishing the validity of a will before a duly authorized court or person. Once validity is confirmed, the probate court then administers the sale of property as directed by the will or as authorized by the court to settle any financial obligations'
      },
      {term: 'Settlement Statement',
      definition: 'also known as Closing Statement or HUD-1.'
      },
      {term: 'Tax Lien',
      definition: ' a debt attached to the property for failing to pay taxes'
      },
      {term: 'Terms',
      definition: 'conditions and arrangements specified within a contract'
      },
      {term: 'Title',
      definition: 'evidence of ownership, evidence of lawful possession'
      },
      {term: 'Title Insurance',
      definition: 'an insurance policy that protects the holder from loss sustained by defects in the title'
      },
      {term: 'Title Search',
      definition: 'an examination of the public records to determine the ownership and encumbrances affecting real property'
      },
      {term: 'Trust',
      definition: 'an arrangement whereby property is transferred to a trusted third party trustee by a grantor/trustor, trustee holds the property for the benefit of the beneficiary'
      },
      {term: 'Trustee',
      definition: 'one who holds property in trust for another to secure performance of an obligation, the neutral party in a trust deed transaction.'
      },
      {term: 'Wholesale',
      definition: 'to contract a property with the intention of reselling it quickly at a higher price.'
      },
      ]
    }
  }
}
</script>
<style scoped>
#WholesaleGlossary{
  min-height: 60vh;
  margin: 2rem 4rem;
}
#WholesaleGlossary h2{
  font-size: 2rem;
}
.container{
  width: 80%;
  margin: 2rem auto;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.free-resource{
  margin:1rem;
  font-size: 1.1rem;
}
.term{
  font-weight: bold;
}
.question-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #WholesaleGlossary h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .faq-list{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
