<template>
  <div class="home">
    <Hero />
    <OurServices />
    <TutorialPanels />
  </div>
</template>

<script>

// @ is an alias to /src
import Hero from '@/views/help/components/Hero.vue'
import OurServices from '@/views/help/components/OurServices.vue'
import TutorialPanels from '@/views/help/components/TutorialPanels.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    OurServices,
    TutorialPanels,
  },
  data(){
    return{
    }
  }
}
</script>
