<template>
  <div id="RVMScript">

    <h1>Achievement Academy RVM Script </h1>

      <div class="free-resource-area">
        <p>The idea behind this method is to make it seem like you’ve already talked with the seller before. Many of them will be unsure which will get them to pick up the phone and call you right back after hearing the voicemail.</p>

        <p>Voicemail: “Hey, my name is (your name) we spoke earlier this year, I’m a local investor here in (your market, Example: I’m a local investor here in “Atlanta”).</p>

        <p>My partner and I need to buy 1-2 more properties before the end of the (month or year). And your property is a perfect candidate in the neighborhood that we are looking to buy in.</p>

        <p>If you would like an all cash offer AS IS condition for your property in (City) please give me a call back at XXX-XXX-XXXX to discuss. Again my name is (your name)</p>

        <p>Thank you so much and have a blessed day.</p>
      </div>

  <div class="container text-default text-center">
    <small>Want to add a term to our glossary? Let us know <router-link :to="{name: 'Contact'}">here!</router-link></small>
  </div>
  </div>
</template>
<script>

export default {
  name: 'RVMScript',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>
<style scoped>
#RVMScript{
  min-height: 60vh;
  margin: 2rem 4rem;
}
#RVMScript h2{
  font-size: 2rem;
}
.container{
  width: 80%;
  margin: 2rem auto;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.free-resource-area{
  margin:1rem;
  text-align:center;
  font-size: 1.1rem;
}
.free-resource-area p{
  margin: 2rem auto;
}
.term{
  font-weight: bold;
}
.question-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #RVMScript h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .faq-list{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
