<template>
  <div id="Tutorial">
  <div class="font-sans antialiased">
      <div class="min-h-screen bg-gray-100 dark:bg-gray-900">
          <main>
              <div class="py-12">
                  <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                      <div class="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
                          <div>
                              <div
                                  class="p-6 bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 sm:px-20">
                                  <img class="rounded-lg w-full" src="https://t4.ftcdn.net/jpg/00/97/87/09/360_F_97870959_eowxg3gnQUdCcWh5wWOyVXMLgQ9K25cW.jpg" />
                                  <div class="mt-8 text-2xl dark:text-gray-200"> Welcome to your Skip Source
                                      Account! </div>
                                  <div class="mt-6 text-gray-500 dark:text-gray-400"> Skip Source provides a
                                      beautiful, robust starting point for your next application.
                                      is designed to help you build your application using a development
                                      environment that is simple, powerful, and enjoyable. We believe you should
                                      love expressing your creativity through programming, so we have spent time
                                      carefully crafting the ecosystem to be a breath of fresh air. We
                                      hope you love it. </div>
                              </div>
                              <div class="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                                  <div class="p-6">
                                      <div class="flex items-center"><svg fill="none" stroke="currentColor"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              viewBox="0 0 24 24" class="w-8 h-8 text-gray-400 dark:text-gray-200">
                                              <path
                                                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253">
                                              </path>
                                          </svg>
                                          <div
                                              class="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                              <a href="https://laravel.com/docs">RVM & TXT Blast</a>
                                          </div>
                                      </div>
                                      <div class="ml-12">
                                          <div class="mt-2 text-sm text-gray-500 dark:text-gray-400"> has
                                              wonderful documentation covering every aspect of the framework.
                                              Whether you're new to the framework or have previous experience, we
                                              recommend reading all of the documentation from beginning to end.
                                          </div><a href="https://laravel.com/docs">
                                              <div
                                                  class="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                                                  <div>Explore the documentation</div>
                                                  <div class="ml-1 text-indigo-500"><svg viewBox="0 0 20 20"
                                                          fill="currentColor" class="w-4 h-4">
                                                          <path fill-rule="evenodd"
                                                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                              clip-rule="evenodd"></path>
                                                      </svg></div>
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                                  <div
                                      class="p-6 border-t border-gray-200 dark:border-gray-700 md:border-t-0 md:border-l">
                                      <div class="flex items-center"><svg fill="none" stroke="currentColor"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              viewBox="0 0 24 24" class="w-8 h-8 text-gray-400 dark:text-gray-200">
                                              <path
                                                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z">
                                              </path>
                                              <path d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                          </svg>
                                          <div
                                              class="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                              <a href="https://skipsourcedata.com">Best Practices</a>
                                          </div>
                                      </div>
                                      <div class="ml-12">
                                          <div class="mt-2 text-sm text-gray-500 dark:text-gray-400"> Skip Source Digital
                                              offers thousands of video tutorials on, PHP, and JavaScript
                                              development. Check them out, see for yourself, and massively level
                                              up your development skills in the process. </div><a
                                              href="https://skipsourcedata.com">
                                              <div
                                                  class="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                                                  <div>Start watching Skip Source Digital</div>
                                                  <div class="ml-1 text-indigo-500"><svg viewBox="0 0 20 20"
                                                          fill="currentColor" class="w-4 h-4">
                                                          <path fill-rule="evenodd"
                                                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                              clip-rule="evenodd"></path>
                                                      </svg></div>
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                                  <div class="p-6 border-t border-gray-200 dark:border-gray-700">
                                      <div class="flex items-center"><svg fill="none" stroke="currentColor"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              viewBox="0 0 24 24" class="w-8 h-8 text-gray-400 dark:text-gray-200">
                                              <path
                                                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                                              </path>
                                          </svg>
                                          <div
                                              class="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                              <a href="https://tailwindcss.com/">Customer and Techinical Support</a>
                                          </div>
                                      </div>
                                      <div class="ml-12">
                                          <div class="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                              Skip Source is built with Tailwind, an amazing utility first CSS
                                              framework that doesn't get in your way. You'll be amazed how easily
                                              you can build and maintain fresh, modern designs with this wonderful
                                              framework at your fingertips. </div>
                                      </div>
                                  </div>
                                  <div class="p-6 border-t border-gray-200 dark:border-gray-700 md:border-l">
                                      <div class="flex items-center"><svg fill="none" stroke="currentColor"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              viewBox="0 0 24 24" class="w-8 h-8 text-gray-400 dark:text-gray-200">
                                              <path
                                                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z">
                                              </path>
                                          </svg>
                                          <div
                                              class="ml-4 text-lg font-semibold leading-7 text-gray-600 dark:text-gray-200">
                                              Skip Source CRM</div>
                                      </div>
                                      <div class="ml-12">
                                          <div class="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                              Authentication and registration views are included with
                                              Skip Source, as well as support for user email verification and
                                              resetting forgotten passwords. So, you're free to get started what
                                              matters most: building your application. </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </main>
      </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'TutorialPanel',
  components:{
  },
  data(){
    return{
    }
  },

}
</script>
<style scoped>
</style>
