export default {
  PROCESS_DATA(state, processing) {
    state.processing = processing
  },
  FEEDBACK_DATA(state, feedback) {
    state.feedback = feedback
  },
  FEEDTYPE_DATA(state, feedtype) {
    state.feedtype = feedtype
  },
  AUTH_SUCCESS(state, token, userID, role) {
    state.userID = userID
    state.token = token
    state.role = role
    state.auth = true;
  },
  AUTH_ERROR(state, userID) {
    state.userID = '';
    state.token = '';
    state.auth = false;
  },
  SET_MINUTE_PACKAGE(state, selected_package) {
    state.selected_package = selected_package
    localStorage.setItem('selected_package', JSON.stringify(selected_package))
  },
  SET_SQUEEZE_PACKAGE(state, squeeze_funnel) {
    state.squeeze_funnel = squeeze_funnel
    localStorage.setItem('squeeze_funnel', JSON.stringify(squeeze_funnel))
  },
  SET_BUYERS_LIST(state, buyers_list_product){
    state.buyers_list_product = buyers_list_product
    localStorage.setItem('buyers_list_product', JSON.stringify(buyers_list_product))
  }
}
