<template>
  <div id="Dashboard">

    <div class="nav-sidebar">
      <img src="@/assets/logo.svg" class="logo-text"/>
      <h2>SKIP SOURCE</h2>
      <div class="bg-yellow-200 py-1 text-lg font-bold w-20 rounded-lg mx-auto">BETA</div>
      <ul class="sidebar-menu">
        <li  v-for="(mu, idx) in menu"
            :key="idx"
            class="sidebar-link"
            @click="gotoRoute(mu.route)"
            :class="{'current-route': mu.route == $route.name}">

          <img :src="mu.icon" class="link-icon" />
          <p>{{mu.name}}</p>

        </li>
        <li @click="logout()"  class="sidebar-link">
          <span v-if="processing">
            <vue-spinner :processing="processing" color="#9e9e9e" size="30px"></vue-spinner>
          </span>
          <span  v-else-if="!processing">
            <img src="@/assets/icons/logout.svg" class="link-icon" />
        </span>
        <p>Logout</p>
        </li>
      </ul>
    </div>

    <div class="dashboard-area">
      <router-view />
    </div>
    <WhatNewModal v-if="whats_new_modal" @closeModal="toggleWhatsNew" />

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import WhatNewModal from '@/components/topbar/WhatNewModal.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: 'Dashboard',
  components:{
    WhatNewModal,
    'vue-spinner': ClipLoader
  },
  data(){
    return{
      photo:'https://www.psypost.org/wp-content/uploads/2017/08/meditating-woman-1200x900.jpg',
      profile:{
        photo:'https://www.psypost.org/wp-content/uploads/2017/08/meditating-woman-1200x900.jpg',
        name: 'Your Name'
      },
      search_query:'',
      processing: false,
      whats_new_modal : false,
      menu: this.$store.state.sideMenu
    }
  },
  methods:{
    logout(){
      this.processing = true
      localStorage.removeItem('usertoken')
      localStorage.removeItem('uid')
      localStorage.removeItem('role')
      setTimeout(() => {
        this.processing = false
        this.$router.push({name: 'Land'})
        location.reload()
      }, 3000);
    },
    toggleWhatsNew(val){
      this.whats_new_modal = val
    },
    gotoRoute(route){
        this.$router.push({name: route})
    }
  },
  computed:{
    ...mapGetters([
      'menus'
    ]),
  }
}
</script>
<style scoped>
#Dashboard{
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  margin: 0;
  padding: 1rem 0;
}
a{
    text-decoration: none;
}
.beta-tag{
  width: 20%;
  margin: 0 auto;
  text-align: center;
  background-color: #f3e49e;
  font-weight: bold;
  border-radius: 45px;
  padding: .5rem 1.5rem;
}
.logo-text{
  width: 5rem;
  height: 5rem;
  margin: auto;
}
.sidebar-link{
  cursor:pointer;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-size: 1rem;
  font-weight: bold;
  transition:1s;
}
.sidebar-link a {
  padding: 1rem 0.3rem;
}
.link-icon{
  transform: rotate(10deg);
  width: 1.8rem;
  transition:1s;
  margin-right: .5rem;
}
.nav-sidebar{
  background-color: #fff;
  border-right: 2px solid var(--primary);
  min-width: 19rem;
  margin:0;
  height: 100%;
  padding: 2rem 0;
  position: fixed;
  left:0;
  top:0;
  z-index: 100;
  transition: 1s;
  text-align: center;
}
.nav-sidebar h2{font-size: 1.2rem;}
.nav-sidebar p{
  margin: 1rem 1rem;
}
.account-box{
  position: absolute;
  height: 6rem;
  bottom: 4.2rem;
  width: 100%;
  left: 0;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -webkit-box-shadow: 0px -5px 22px -14px rgba(0,0,0,0.77);
  -moz-box-shadow: 0px -5px 22px -14px rgba(0,0,0,0.77);
  box-shadow: 0px -5px 22px -14px rgba(0,0,0,0.77);
}
.account-box a{
  text-align: center;
  margin-left: 2rem;
}
.account-photo {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  overflow:hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.sidebar-menu{
  margin: 2rem auto;
  list-style-type: none;
  color: var(--primary);
  display:flex;
  flex-direction: column;
}
.nav-sidebar ul li{
  padding: .6rem 2rem;
}
.nav-sidebar ul li:hover{
  background-color: var(--primary);
  color: #fff;
}
.current-route{
  background-color: var(--primary);
  color: #fff;
}
.nav-sidebar ul li a{
  font-size: 1.3rem;
  color: var(--main);
}
.dashboard-area{
  margin: 4rem auto;
  position:relative;
  margin-left: 21rem;
  margin-right: 1rem;
  min-height: 80vh;
  transition: 1s;
}
@media only screen and (max-width: 600px) {
  .nav-sidebar{
    min-width: 5rem;
  }
}
</style>
