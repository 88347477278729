<template>
  <div id="TextBlastTemplates">

    <h1>Achievement Academy TXT Blast Templates</h1>

    <div class="free-resource-list" >
      <div class="free-resource">
        <p><span class="term">Local Investor Interested</span> = Hey [First Name] it’s (Insert your name) I noticed your property on  [Address] which is an area I invest in. I'm a local investor, would you be interested in selling the property? </p>

        <p><span class="term">Transition To Get The Prospect On The Phone</span> - To get you the best offer, we would just need to ask you a few more questions about the property. Are you available to speak for a few minutes? </p>

        <p><span class="term">Probate LEAD</span>  = Hi [First Name], I noticed through county records you're the representative for the property [Address] I'm a local investor interested in the property. </p>

        <p><span class="term">Selling Deal to Cash Buyer</span> - Hey [First Name] I'm a local investor and I have an off market property which would make a great opportunity for you, in an area where you recently bought. Would you be interested in the details on this opportunity? </p>
      </div>

    </div>

  <div class="container text-default text-center">
    <small>Want to add a term to our glossary? Let us know <router-link :to="{name: 'Contact'}">here!</router-link></small>
  </div>
  </div>
</template>
<script>

export default {
  name: 'TextBlastTemplates',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>
<style scoped>
#TextBlastTemplates{
  min-height: 60vh;
  margin: 2rem 4rem;
}
#TextBlastTemplates h2{
  font-size: 2rem;
}
.container{
  width: 80%;
  margin: 2rem auto;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.free-resource{
  margin:1rem;
  font-size: 1.1rem;
}
.free-resource p{
  margin: 2rem auto;
}
.term{
  font-weight: bold;
}
.question-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #TextBlastTemplates h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .faq-list{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
