<template>
  <div id="HowItWorks">

    <h1>How it Works</h1>

    <div class="beta-tag">BETA</div>
    <p class="beta-text">Our Skip Source Software is still in beta! If you find any bugs or glitches while building out our system, please <a href="/contact">contact us here</a></p>

    <div class="container">
      <div class="how-card" v-for="sp in steps" :key="sp.step">
        <div class="card-header">
          <span class="step-num">{{sp.step}}</span>
        </div>
        <div class="card-body">
          <div class="card-body-img" :style="{'background-image': 'url(' + sp.img[0] + ')'}"></div>
          <div class="card-body-text">
            <p>{{sp.text}}</p>

            <div class="template-files-group" v-if="sp.step == 2">
              <div class="template-file-section">
                <a href="https://docs.google.com/spreadsheets/d/14l2mtUQN4eiLBHx46o7Ux1cZnU8qlWd6I3zhA3p4jvQ/edit?usp=sharing" target="_blank">
                  <img src="@/assets/icons/skip-trace/google-sheets.svg" />
                  <p><strong>Google Sheet</strong></p>
                </a>
              </div>
              <p>or</p>
              <div class="template-file-section">
                <a href="https://firebasestorage.googleapis.com/v0/b/skip-source.appspot.com/o/site_assets%2FCSV%20Template.csv?alt=media&token=f9dad6bb-bb4e-46c1-8e1b-78b95a768d91" target="_blank">
                  <img src="@/assets/icons/skip-trace/csv.svg" />
                  <p><strong>CSV File</strong></p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="container text-default text-center">
    <small>Have a question you want to ask, but it is not on here? Ask us <router-link :to="{name: 'Contact'}">here!</router-link></small>
  </div>
  </div>
</template>

<script>

export default {
  name: 'HowItWorks',
  components: {
  },
  data(){
    return{
      steps:[
        {
        step: 1,
        text: 'Start by creating an account, next submit your payment with the correct quantity of records you need skip traced. Next, submit your List of Records using our Template formats in the Form provided. After Payment is received, our Team of Experts begin skip tracing public & private records, business records, and unique databases to find the most accurate data you need.',
        img:['https://firebasestorage.googleapis.com/v0/b/skip-source.appspot.com/o/site_assets%2Fguide-media%2Fbrave_2020-12-02_15-46-30.jpg?alt=media&token=83d502cf-56dd-4c28-b21f-5a562b4a7f8a'],
      },
      {
        step: 2,
        text: 'Once you made your account you then proceed to download the template CSV file to follow and upload your documents to.',
        img:['https://firebasestorage.googleapis.com/v0/b/skip-source.appspot.com/o/site_assets%2Fguide-media%2Fbrave_2020-12-02_16-02-10.png?alt=media&token=7296e8ed-1a29-41e7-b085-86872c75f5a7'],
      },
      {
      step: 3,
      text: 'After 24-48 hours, someone from our team will distribute the file back to you and you will be able to download it from the Skip Trace tab',
      img:['https://firebasestorage.googleapis.com/v0/b/skip-source.appspot.com/o/site_assets%2Fguide-media%2Fbrave_1606943076.png?alt=media&token=03430ab6-d8c7-4501-995c-09a0babf82ca'],
      }
      ]
    }
  }
}
</script>

<style scoped>
#HowItWorks{
  min-height: 60vh;
  width: 80%;
  margin: 2rem auto;
}
#HowItWorks h2{
  font-size: 2rem;
}
.beta-tag{
  width: 20%;
  margin: 0 auto;
  text-align: center;
  background-color: #f3e49e;
  font-weight: bold;
  border-radius: 45px;
  padding: .5rem 1.5rem;
}
.container{
  width: 80%;
  margin: 2rem auto;
}
.how-card{
  transition:1s;
  margin: 2rem;
}
.card-header{
  margin: 1rem 0;
  padding-bottom: 2rem;
  border-bottom: 2px solid #000;
}
.step-num{
  border-radius: 100%;
  font-size: 1.5rem;
  font-weight: 900;
  padding: .3rem .8rem;
  border: 2px solid #000;
}
.card-body{
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;
}
.card-body-img{
  width: 100%;
  overflow:hidden;
  position: relative;
  min-height: 20rem;
  background-position:center;
  background-repeat: no-repeat;
  background-size: contain;
}
.card-body-text{
  padding: 1rem;
}
.template-files-group{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items:center;
}
.template-file-section {
  width: 5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.template-file-section:hover{
  transform: translateY(-10px);
}
.template-file-section a{
  text-decoration: none;
  color: #000;
  font-size: .6rem;
}
.template-file-section img{
  width: 70%;
  margin: .5rem auto;
}
.beta-text{
  width: 60%;
  margin: .5rem auto;
  text-align:center;
}
.beta-text a{
  color: var(--link-light);
}
@media screen and (max-width:900px) {
  #HowItWorks{
    width: 100%;
    margin: 2rem auto;
  }
  .container{
    width: 96%;
  }
  .how-card{
    margin: .1rem;
  }
  .card-body{
    grid-template-columns: 1fr;
    grid-template-rows: 5fr 4fr;
  }
  .card-body-img{
    min-height: 5rem;
  }
}
@media screen and (max-width: 600px) {
  #HowItWorks h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .faq-list{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
