<template>
<div id="SqueezeCheckout">
  <div class="container" v-if="!hasPaid">
    <h1 class="title">Place Your Order Below!</h1>
    <div class="user-funnel-info">
      <p>Please Make sure the following information is correct.</p>
      <ul class="funnel-list">
        <li  v-for="(fun_list, idx) in formatedFunnelList" :key="idx">
        {{fun_list.label}}: {{fun_list.funnel_data}}</li>
      </ul>
    </div>
    <div class="checkout-box">
        <table class="package-table">
          <tr class="head-row">
            <th class="left-side">Item</th>
            <th class="right-side">Price</th>
          </tr>

          <tr  v-for="(min, idx) in minutepackages" :key="idx">
            <td class="select left-side">
              <div class="select-price" :class="{'selected': selected_package.tag == min.tag}"
              @click="selectPackage(min)"></div>
              {{min.item}}</td>
            <td class="right-side">${{min.price}}</td>
          </tr>
        </table>
      <div v-if="selected_package.price">
        <SqueezeCard
        :squeeze_funnel="squeeze_funnel"
        :total="selected_package.price" @saleMade="saleMade" product="squeeze"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapState} from 'vuex'
import SqueezeCard from './SqueezeCard.vue'
export default {
  name: 'SqueezeCheckout',
  components: {
    SqueezeCard
  },
  data(){
    return{
      hasPaid: false,
      minutepackages:[
        {price: 150, item: 'Absentee Owner List: 2,000 Records', tag: 'absentee'},
        {price: 150, item: 'Lien List: 2,000 Records', tag: 'liens'},
      ],
      selected_package: {price: null}
    }
  },
  methods:{
    saleMade(val){
      if(val){
      this.$router.push({name: 'SqueezeConfirmation'})
      }
    },
    selectPackage(val){
      this.selected_package = val
    },
  },
  computed:{
    ...mapState(['squeeze_funnel']),
    formatedFunnelList(){
      let arr = []
      arr[0] = {label: 'First Name', funnel_data: this.squeeze_funnel.fname}
      arr[1] = {label: 'Last Name', funnel_data: this.squeeze_funnel.lname}
      arr[2] = {label: 'Email', funnel_data: this.squeeze_funnel.email}
      arr[3] = {label: 'Phone', funnel_data: this.squeeze_funnel.phone}
      arr[4] = {label: 'City', funnel_data: this.squeeze_funnel.city}
      arr[5] = {label: 'Zip', funnel_data: this.squeeze_funnel.zip}
      // arr[7] = {label: 'How many records would you like?', funnel_data: this.squeeze_funnel.records_count}
      arr[6] = {label: 'Notes', funnel_data: this.squeeze_funnel.notes}
      return arr
    }
  },
  created(){
    const package_idx = this.minutepackages.map((e)=> { return e.tag; }).indexOf(this.squeeze_funnel.list_type)
    this.selected_package = this.minutepackages[package_idx]
    console.log(this.selected_package)
  }
}
</script>
<style scoped>
#SqueezeCheckout{
  text-align:center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 3rem;
}
.presentation{
  width: 100%;
  background-color: #444;
  display: flex;
  justify-content: center;
  align-items:center;
  border-bottom: 3px solid var(--primary);
}
.skip-source-phone{
  background-color: #fff;
  width: 20%;
  margin: 1rem auto;
  padding: 2rem 2rem;
}
.skip-source-phone img{
  width:100%;
}
.funnel-list{
  list-style-type:none;
  text-align:left;
  /* font-size: 1rem; */
  transition: 1s;
}
.funnel-list li{
  margin: .5rem auto;
  transition: 1s;
}
.title{
  color: var(--primary);
  font-size: 2.4rem;
  margin: 1rem;
}
.container{
  max-width: 900px;
  margin: 0 auto;
}
.checkout-box{
  border: 4px dashed  #dedede;
  width: 80%;
  border-radius: 10px;
  margin: 2rem auto;
  padding: 4rem 0;
}
.package-table{
  width: 80%;
  margin: auto;
}
.package-table tr td{
  padding: .5rem 0;
  transition: 1s;
}
.package-table tr .left-side{
  width: 80%;
  display: flex;
  font-weight: bold;
  align-items:center;
  text-align: left;
}
.package-table tr .right-side{
  text-align: right;
  width: 20%;
}
.package-table .head-row{
  border-bottom: 1px solid #ddd;
}
.select-price{
  width: 1.7rem;
  height: 1.7rem;
  transition: 1s;
  border-radius: 5px;
  margin-right: 1rem;
  cursor: pointer;
  border: 2px solid #555;
}
.selected{
  border:none;
  width: 1.8rem;
  height: 1.8rem;
  background-color: #0066ff;
}
.input-group{
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem auto;
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: .5rem 1.2rem;
}
.checkout-inputs label{font-size: 15px; text-align: left; width: 15rem;}
.form-input{
  padding: .5rem 1.2rem;
  border: none;
  width: 100%;
  text-align: right;
  transition: 1s;
}
.form-input:focus{
    outline: none;
}
.pay-warning{
  background-color: #ddd;
  padding: 3rem;
  text-align: left;
  width: 80%;
  margin: 1rem auto;
}
.rvm-btn{
  text-decoration: none;
  border-radius: 10px;
  margin-top:3rem;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 1rem 2rem;
  color: #fff;
}
@media screen and (max-width: 390px) {
  .checkout-inputs label{font-size: 8px;}
  .input-group{padding: .5rem .2rem;  }
  .form-input{
    padding: .5rem 1rem;
    border-radius: 8px;
  }
  .test{
    font-size: 7px;
  }
  .price-text{
    float: right;
    font-size: 1.1rem;
  }
}
</style>
