<template>
<div id="ListSelector">
  <!-- <h2>Choose Your List</h2> -->
  <!-- <div
    class="list-pick"
    v-for="(rel, idx) in reLists"
    :key="idx" @click="selectList(rel.tag)"
    :class="{'selected': selected_list_tag == rel.tag}">
    <p class="list-name">{{rel.name}}</p>
    <p class="list-records">{{rel.records_count}} Records</p>
    <p class="list-records">${{rel.cost/100}}</p>
  </div> -->

  <div class="packages">
    <div class="cashbox">
      <div class="package"
      v-for="(min, idx) in reLists" :key="idx"
      @click="selectList(min.tag)">
        <div class="foreground">
          <div class="package-card">
            <h3 class="list-text">{{min.name}}</h3>
            <p>{{(min.records_count).toLocaleString()}} <br />Records</p>
            <div class="minute-card">
              <p class="price-text">${{min.cost}}</p>
            </div>
          </div>
        </div>
        <div class="gradient backdrop" :class="{'selected-backdrop': selected_list_tag == min.tag}"></div>
      </div>
    </div>
</div>
</div>
</template>

<script>

export default {
  name: 'ListSelector',
  components: {
  },
  data(){
    return{
      selected_list_tag: '',
      reLists:[
        {name: 'Absentee Owner List', tag:'absentee', records_count: 2000, cost: 150},
        {name: 'Lien List', tag:'liens', records_count: 2000, cost: 150}
      ]
    }
  },
  methods:{
    selectList(list){
      this.selected_list_tag = list
      this.$emit('selectList', list)
    }
  }
}
</script>
<style scoped>
#ListSelector{
  width: 100%;
  min-height: 15rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
}

/* PACKAGE */

.packages{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.hero{
  width: 100%;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--primary);
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items:center;
}
.container{
  margin: 0 auto;
  max-width: 900px;

}
.summary{
  margin: 0 auto;
  max-width: 900px;
}
.cashbox{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem auto;
  /* border: 3px solid var(--primary); */
}
.package{
  min-height: 15rem;
  background-color: #efefef;
  display:flex;
  border-radius: 9px;
  width: 100%;
  border: 3px solid var(--primary);
  position: relative;
  margin: .5rem .5rem;
  padding: 0;
  overflow:hidden;
  justify-content: space-evenly;
  align-items: space-between;
  -webkit-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
transition: 1s;
}
.package:hover{
  box-shadow:none;
  cursor: pointer;
  transform: translateY(-3px);
}
.package:hover .package-card{
  transform: translate(-6px, -9px);
  -webkit-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
}
.package:hover .list-text,
.package:hover .subtext{
  transform: translate(-9px, -12px);
}
.package-card{
  border-radius: 9px;
  background-color: #fff;
  height: 80%;
  display:flex;
  flex-direction: column;
  transition: 1s;
  width: 60%;
  padding: .4rem 1.5rem;
  margin:auto;
  justify-content: space-evenly;
  align-items: center;
}
.foreground{
  left: 0;
  top:0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display:flex;
  justify-content: space-between;
  align-items: center;
  position:absolute;
}
.backdrop{
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  left: 0;
  transition: 1s;
  position:absolute;
}
.package:hover .backdrop{
  transform: translateY(0%);
}

.list-text{
  transition: 1s;
  font-size: 1.5rem;
  background-color: transparent;
}
.price-text{
  margin-top: .5rem;
  font-size: 1.3rem;
  font-weight: bold;
}
.subtext{
  margin-left: 3rem;
  font-weight: bold;
  text-align: left;
  transition: 1s;
  width: 60%;
  font-size: 17px;
  font-style: italic;
}
.package-btn{
  text-decoration: none;
  border-radius: 10px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 1rem 2rem;
  margin-right: 1.4rem;
  color: #fff;
}
.package:hover  .package-btn{
  background-color: #fff;
    transition: 2s;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.selected-backdrop{
  transform: translateY(0%);
}
</style>
