<template>
  <div id="CSVPreview">
    <div class="filed-table">
      <h3>Preview</h3>
      <!-- {{synData}} -->
      <p>
        Please ensure the headers are exactly the same as the template files
        above
      </p>
      <table>
        <tr class="table-header-row">
          <th v-for="(fh, idx) in file_headers" :key="idx">{{ fh }}</th>
        </tr>
        <tr v-for="(doc, idx) in synData" :key="idx" class="file_docs">
          <td v-if="synData.length > 0">
            <button
              v-if="
                doc.fname != '' &&
                !allCSVProcessing &&
                uploading_idx.includes(idx) == false
              "
              class="trace-btn"
              @click="processRecord(idx)"
            >
              Process
            </button>
            <div
              class="skip-loading-box"
              v-else-if="uploading_idx.includes(idx)"
            >
              <em>Skip Tracing Data...</em>
            </div>
          </td>
          <td>{{ doc.fname }}</td>
          <td>{{ doc.lname }}</td>
          <td>{{ doc.address }}</td>
          <td>{{ doc.city }}</td>
          <td>{{ doc.state }}</td>
          <td>{{ doc.zip }}</td>
          <td>{{ doc.mail_address }}</td>
          <td>{{ doc.mail_city }}</td>
          <td>{{ doc.mail_state }}</td>
          <td>{{ doc.mail_zip }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { fb, db } from "@/_firebase/init";
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: "CSVPreview",
  components: {},
  props: ["availability", "allCSVProcessing"],
  data() {
    return {
      file_headers: [
        "first name",
        "last name",
        "property address",
        "city",
        "state",
        "zip",
        "mailing address",
        "mailing city",
        "mailing state",
        "mailing zip",
      ],
      feedback: "",
      status: "",
      file_links: [""],
      skip_sourced_files: [],
      skipped_data: [],
      url: null,
      testingNewFeature: true,
      uploading_idx: [],
    };
  },
  methods: {
    processRecord(index) {
      this.csv_processing = true;
      this.uploading_idx.push(index);
      const doc_data = this.synData[index];
      let payload = {
        doc: doc_data,
      };
      axios
        .post(this.$api_path + "skip_source/realtime-search/single", payload)
        .then((res) => {
          console.log(res.data);
          this.csv_processing = false;
          const indexofitem = this.uploading_idx.indexOf(index);
          if (indexofitem > -1) {
            this.uploading_idx.splice(indexofitem, 1);
          }

          this.process_data = true;
          this.$emit("processedSingle", true, res.data.response);
        })
        .catch((err) => {
          console.log(err);
          this.csv_processing = false;
          this.uploading_idx = -1;
        });
    },
  },
  computed: {
    ...mapState(["userID"]),
    // synData(){
    //   let file_data = this.availability
    //   if(file_data.length > 0){
    //     this.file_headers.unshift('skip trace')
    //   }
    //   return file_data
    // }
  },
  created() {
    // console.log(this.userID)
  },
};
</script>
<style scoped>
#SkipTrace {
  padding: 2rem;
  width: 90%;
  min-height: 44vh;
  background-color: #fff;
  border-radius: 15px;
}
.trace-btn {
  padding: 0.5rem 2rem;
  margin: 0.4rem;
  transition: 1s;
  color: #00aeef;
  background-color: #fff;
  border: 1px solid #00aeef;
  border-radius: 20px;
  cursor: pointer;
}
.trace-btn:hover {
  transform: translateY(-10px);
}
.skip-loading-box {
  font-size: 10px;
}
.selected {
  color: #fff;
  background-color: #00aeef;
}
.upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  opacity: 0;
}
.table-header-row {
  color: #fff;
  background-color: #00aeef;
  transition: 1s;
}
.table-header-row th {
  padding: 1rem;
}
.filed-table {
  width: 100%;
  max-height: 52vh;
  margin: 1rem auto;
  transition: 1s;
  overflow-x: scroll;
}
.filed-table::-webkit-scrollbar {
  width: 10px;
}

.filed-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #f4f4f4;
}

.filed-table::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.filed-table::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
.no-data {
  text-align: center;
  padding: 1rem 0;
}
.file_docs {
  text-align: center;
}
.template-files-group {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.template-file-section {
  width: 5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.template-file-section:hover {
  transform: translateY(-10px);
}
.template-file-section a {
  text-decoration: none;
  color: #000;
  font-size: 0.6rem;
}
.template-file-section img {
  width: 70%;
  margin: 0.5rem auto;
}
</style>
