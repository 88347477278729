<template>
  <div id="CSVProcessed">

    <div class="filed-table">
      <h3>Processed</h3>

      <!-- {{processed_data}} -->
      <table>
        <tr class="table-header-row">
          <th v-for="(fh, idx) in file_headers" :key="idx" :class="{'phone-col': idx == 6}">{{fh}}</th>
        </tr>
        <tr v-for="(doc, idx) in synData" :key="idx" class="file-docs" :class="{'alt-row': idx % 2 == 0 }">
          <td>{{doc.name.firstName}}</td>
          <td>{{doc.name.lastName}}</td>
          <td>{{doc.address.line}}</td>
          <td>{{doc.address.city}}</td>
          <td>{{doc.address.state}}</td>
          <td>{{doc.address.zip}}</td>
          <td>
            <div
            v-for="(ph, idx_p) in doc.phone"
            :key="'phone'+idx_p">
              <!-- {{ph}} -->
              <p>{{ph['phoneNumber']}}</p>
              <!-- <p>{{ph['company']}}</p> -->
              <div class="divider"></div>
            </div>
            <div class="divider2"></div>
          </td>
          <td>
            <div v-for="(em, idx_e) in doc.email" :key="'email'+idx_e">
              {{em['emailAddress']}}
            </div>
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import {mapState} from 'vuex'
import {fb, db} from '@/_firebase/init'
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: 'CSVProcessed',
  components:{
  },
  props:['processed_data'],
  data(){
    return{
      file_headers:['first name', 'last name', 'property address', 'city', 'state', 'zip', 'phones', 'email'],
      feedback:'',
      status: '',
      skip_sourced_files: [],
      skipped_data: [],
    }
  },
  methods:{
  },
  computed:{
    ...mapState(['userID']),
    synData(){
      let file_data = this.processed_data
      return file_data
    },
  },
  created(){
    // console.log(this.userID)
  }
}
</script>
<style scoped>
#SkipTrace{
  padding: 2rem;
  width: 90%;
  min-height: 44vh;
  background-color: #fff;
  border-radius:15px;
}
.trace-btn{
  padding: .5rem 2rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 20px;
  cursor:pointer;
}
.phone-col{
  width: 10rem;
}
.divider{
  width: 40%;
  height: 1px;
  background: #aaa;
  margin: .1rem auto;
}
.divider2{
  width: 90%;
  height: 2px;
  background: #000;
  margin: .1rem auto;
}
.selected{
  color:#fff;
  background-color: #00AEEF;
}
.table-header-row{
  color:#fff;
  background-color: #00AEEF;
}
.table-header-row th{
  padding: 1rem;
}
.filed-table{
  width: 100%;
  max-height: 62vh;
  margin: 1rem auto;
  transition: 1s;
  overflow-x: scroll;
}
.filed-table::-webkit-scrollbar {
  width: 10px;
}

.filed-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #f4f4f4;
}

.filed-table::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.filed-table::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
.no-data{
  text-align:center;
  padding: 1rem 0;
}
.file-docs{
  text-align:center;
  background-color: #efefef;
}
.alt-row{
  background-color: #fff;
}
.template-files-group{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items:center;
}
.template-file-section {
  width: 5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.template-file-section:hover{
  transform: translateY(-10px);
}
.template-file-section a{
  text-decoration: none;
  color: #000;
  font-size: .6rem;
}
.template-file-section img{
  width: 70%;
  margin: .5rem auto;
}
</style>
