<template>
  <div id="Testimonials">
	<div>
		<div class="my-10 md:my-24 container mx-auto flex flex-col md:flex-row shadow-sm overflow-hidden">
			<div class="relative w-full py-2 md:py-24 bg-yellow-700 md:w-1/2 flex flex-col item-center justify-center">

			<div class="absolute top-0 left-0 z-10 grid-yellow w-16 h-16 md:w-40 md:h-40 md:ml-20 md:mt-24"></div>

			<div class="relative text-2xl md:text-5xl py-2 px-6 md:py-6 md:px-1 md:w-64 md:mx-auto text-yellow-100 font-semibold leading-tight tracking-tight mb-0 z-20">
			<span class="md:block">What Our</span>
			<span class="md-block">Customers</span>
			<span class="block">Are Saying!</span>
			</div>

			<div class="absolute right-0 bottom-0 mr-4 mb-4 hidden md:block">
			<button
			class="rounded-l-full border-r bg-gray-100 text-gray-500 focus:outline-none hover:text-yellow-500 font-bold w-12 h-10"
			@click="countTestimonial(-1)">
			&#8592;
			</button><button
			class="rounded-r-full bg-gray-100 text-gray-500 focus:outline-none hover:text-yellow-500 font-bold w-12 h-10"
			@click="countTestimonial(1)">
			&#8594;
			</button>
			</div>
			</div>

			<div class="bg-gray-100 md:w-1/2">
			<div class="flex flex-col h-full relative">

			<div class="absolute top-0 left-0 mt-3 ml-4 md:mt-5 md:ml-12">
			<svg xmlns="http://www.w3.org/2000/svg" class="text-yellow-200 fill-current w-12 h-12 md:w-16 md:h-16" viewBox="0 0 24 24"><path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z"/></svg>
			</div>

			<div class="h-full relative z-10">
				<p class="text-gray-600 serif font-normal italic px-6 py-6 md:px-16 md:py-10 text-xl md:text-2xl">
					{{testimonials[testimonialActive].statement}}
				</p>
			</div>

			<div class="flex my-4 justify-center items-center">
				<button
					v-for="(t, idx) in testimonials" :key="idx"
					@click.prevent="testimonialActive = idx"
					class="text-center font-bold shadow-xs focus:outline-none focus:shadow-outline inline-block rounded-full mx-2"
					:class="{'h-8 w-8 opacity-25 bg-yellow-300 text-gray-600 bg-yellow-600 text-white': testimonialActive != idx}">
						{{idx}}
				</button>
			</div>

			<div class="flex justify-center px-6 pt-2 pb-6 md:py-6">
				<div class="text-center" x-show="testimonialActive == 1">
				<h2 class="text-sm md:text-base font-bold text-gray-700 leading-tight">{{testimonials[testimonialActive].speaker}}</h2>
				<small class="text-gray-500 text-xs md:text-sm truncate">{{testimonials[testimonialActive].location}}</small>
			</div>

			</div>
			</div>
		</div>
	</div>
		<!-- component -->
	</div>

  </div>
</template>
<script>
import {mapGetters,mapMutations} from "vuex"
import {db} from '@/_firebase/init'

export default {
  components: {
  },
  name: 'Testimonials',
  data(){
    return{
			testimonialActive: 0,
      testimonials :[
        {
          statement: 'Skip Source is the most efficient skip tracing company in the world, their data is top tier.',
          speaker: 'Jason Peters ',
          location:'Real Estate Investor, Atlanta, GA',
        },
        {
          statement: 'After years of wasting money on bad data, I took a leap of faith and decided to give Skip Source a try, and they have helped my business grow tremendously and close more deals.',
          speaker: 'Jennifer Santos  ',
          location:'Short Sale Specialist, Miami, FL',
        },
        {
          statement: 'The Customer Service that the team at Skip Source Provides is amazing, their Custom Service team is very professional, polite, and knowledgeable.',
          speaker: 'Anthony Smith',
          location:'Realtor, Dallas, TX',
        },
        {
          statement: 'Skip Source has great customer service, and their data is amazing, their data has helped me close more deals and drastically increase my contact rate with sellers.',
          speaker: 'David Fisk',
          location:'Real Estate Investor, Los Angeles, CA',
        },
        {
          statement: 'The Skip Source team has helped me my busines grow tremendously, I am thankful that I found their website, I was impressed with how quickly they got the data back to me.',
          speaker: 'Stacy Jones',
          location:'Wholesaler, Charlotte, NC',
        },
        {
          statement: 'I was impressed by Skip Source and how quickly they got the data back to me! My biggest challenge was: building my buyers list and getting LLC’s skip traced, I was so happy that Skip Source provided that service for me, thanks again!',
          speaker: 'Taylor Moore',
          location:'Wholesaler, Dallas, TX',
        },
        {
          statement: 'I am so grateful that I found Skip Source, my profit on my wholesale deals have doubled because of their efficient data and how quickly they get the data back to their customers.',
          speaker: 'Alex Bell',
          location:'Wholesaler, Denver, CO',
        },
      ]
    }
  },
	methods:{
		countTestimonial(i){
			if(this.testimonialActive == 0 && i == - 1){
					console.log(null)
			}else if(this.testimonialActive == this.testimonialActive.length - 1 && i == 1){
					console.log(null)
			}else{
				this.testimonialActive = 	this.testimonialActive + i
			}
		}
	},
  created(){
  }
}
</script>
<style scoped>
#Testimonials{

  width: 100%;
  margin: 0 auto;
  padding: 0rem 0rem;
  margin-top: 5rem;
  text-align: center;
  transition: 1s;
}
#testimonial-carousel{
  min-height: 5rem;
  height: 100%;
}
.slide-space {
  max-width: 20rem;
  margin: .3rem 2rem;
  cursor:pointer;
  transition: 1s;
  background-color: #fff;
  border: 2px solid #000;
  padding: 1rem;
  border-radius: 20px;
}
.slide-space img{
  width: 90%;
  border-radius: 15px;
}
.slide-space:hover{
  transform: translate(-2px, -2px) scale(1.02);
}
.quote{
  text-align: left;
  font-size: 1.2rem;
  margin: .3rem;
}
.speaker{
  text-align: right;
  font-size: .9rem;
  color: var(--primary);
  margin: .3rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 900px) {
  .slide-space img{
    width: 10rem;
  }
  .blog-metabody{
    font-size: .5rem;
  }
  .slide-space {
    max-width: 100%;
    margin: .3rem;
    padding: 1rem;
  }
  .quote{
    width: 100%;
    font-size: .8rem;
    margin: .2rem;
  }
  .speaker{
    font-size: .6rem;
  }
}
@media only screen and (max-width: 600px) {
  #Testimonials{
    width: 90%;
  }

}
@media only screen and (max-width: 400px) {
  .how-it-works-text p{
    font-size: .6rem;
    margin: 1rem 0;
  }
}
</style>
