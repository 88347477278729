<template>
  <div id="Pricing">
    <div class="container">
      <div class="left-side side">
        <h1>SKIP SOURCE RVM & TEXT BLAST SOFTWARE</h1>
        <div class="img-box">
          <img src="@/assets/media/skip-source-phone2.png" class="mac" />
        </div>
        <!-- <img src="@/assets/skip-trace-table.svg" class="img-pic" /> -->
        <br />

        <router-link :to="{name: 'MinutesHello'}" class="primary-btn link">
          SIGNUP FOR SKIP SOURCE <br />RVM & Text Blast Software
        </router-link>

        <div class="rvm-explained">
          <h2 class="font-bold text-2xl">What is RVM?</h2>
          <ul class="list-styles">
            <li>Ringless voicemail is a way to bypass dialing and answering, dropping audio messages directly into a voicemail-box.  </li>
          </ul>

          <h2 class="font-bold text-2xl">Why RVM?</h2>
          <ul class="list-styles">
            <li>Ringless voicemail has recently become a marketing tool of choice for real estate investors. This is because ringless voicemail tends to have a higher conversion rate and lower cost-per-conversion than traditional marketing methods.
              <br /><br />
            Besides being economical, the tone and feel of ringless voicemail marketing drops make them effective in the real estate investing industry.</li>
          </ul>

          <h2 class="font-bold text-2xl">What is Text blast marketing? </h2>
          <ul class="list-styles">
            <li>Through SMS marketing, you're getting as specific as possible and meeting your customers where they are – on their smartphones. By communicating your marketing messages to a targeted audience, you're able to cultivate more meaningful connections with prospects and interactions with qualified leads.</li>
          </ul>

          <h2 class="font-bold text-2xl">Why use Text blast marketing?</h2>
          <ul class="list-styles">
            <li>Text blasts provide a quick way to remain relevant to your audience even from a distance. The advantages of mass texting for marketing include: Texts have a high open-rate.  Text message blasts are an affordable web-based SMS texting service that allows you to easily and simultaneously send text messages to a large number of prospects. </li>
          </ul>
        </div>
      </div>
      <div class="right-side side">
      <h1  class="font-bold text-4xl">FAQ</h1>
      <div class="faq-box" v-for="(faq, f_id) in faq_list" :key="f_id" >
        <h3  class="font-bold text-2xl">{{faq.q}}</h3>
        <p>{{faq.a}}</p>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapMutations} from "vuex"

export default {
  components: {
  },
  name: 'Pricing',
  data(){
    return{
      faq_list :[
        {q: 'AM I BILLED PER HIT RATE OR PER RECORD SUBMITTED?', a: 'Yes, you will be billed per record submitted. The results will depend on the accuracy of the information submitted, this is a per search service.'},
        {q: 'Do you have a refund policy?', a: 'We do not offer any refunds on our products as of right now. Once an order is submitted, we are not able to offer any cancellations.'},
        {q: 'IS THERE A MINIMUM ORDER?', a: 'Yes, 500 records.'}
      ]
    }
  },
  methods:{

  },
}
</script>
<style scoped>
#Pricing{
  background: #000;
  width: 100%;

}
.container{
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0rem;
  text-align: center;
  transition: 1s;
  display: flex;
  transition: 1s;
  justify-items: space-evenly;
  align-items: flex-start;
}
.left-side{
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}
.right-side {
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}
.mac{
  max-width: 30rem;
}
.left-side h1{
  color: #fff;
  font-size: 1.4rem;
}
.left-side img{
  width: 100%;
  margin: 1rem auto;
}
.right-side h1{
  color: #fff;
}
.rvm-explained{
    color: #fff;
      text-align: left;
    margin: 1rem .8rem;
}
.rvm-explained h2{
  margin: 1rem auto;
  margin-bottom: 2rem;
}
.list-styles{
  margin-left: 1.2rem;
  color: #fff;
}
.img-box{
  margin: 2rem auto;
  background-color: #fff;
  padding: 1rem;
}
.faq-box{
  margin: 2rem auto;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 8rem;
}
.faq-box h3{
  margin-bottom: 0rem;
}
.faq-box h3, p{
  margin: 1rem auto;
  width: 80%;
}
.link{
  text-transform: uppercase;
  font-size: 1.4rem;
  max-width: 30rem;
  margin: 1rem auto;
  font-weight: bold;
  text-decoration: none;
}
@media only screen and (max-width:900px) {
  .container{
    width: 96%;
    padding: 4rem 0;
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .faq-box{
    padding: .2rem;
    min-height: 3rem;
  }
  .link{
    font-size: 1rem;
  }
}
</style>
