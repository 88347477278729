<template>
  <div id="Support">
    <div class="dashboard-header">
      <h2>Support</h2>
    </div>
    <br />
    <div class="support-tabs">
      <div class="tabs">
        <div
        @click="selectTab('skip_trace')"
        :class="{'active-tab': active_tab == 'skip_trace'}"
        class="tab"
        >Skip Trace</div>
        <div
        @click="selectTab('rvm')"
        :class="{'active-tab': active_tab == 'rvm'}"
        class="tab"
        >RVM & Text Blast</div>
        <div
        @click="selectTab('support')"
        :class="{'active-tab': active_tab == 'support'}"
        class="tab"
        >Message</div>
      </div>

      <div class="tab-view">
        <SupportSkipTraceTab v-if="active_tab == 'skip_trace'"/>
        <SupportRVMTab v-if="active_tab == 'rvm'"/>
      </div>
    </div>
  </div>
</template>

<script>
import SupportSkipTraceTab from '@/views/dashboard/support/SupportSkipTraceTab.vue'
import SupportRVMTab from '@/views/dashboard/support/SupportRVMTab.vue'
export default {
  name: 'Support',
  components:{
    SupportSkipTraceTab,
    SupportRVMTab
  },
  data(){
    return{
      active_tab: 'skip_trace'
    }
  },
  methods:{

    selectTab(tab){
      this.active_tab = tab
    }
  }
}
</script>
<style scoped>
#Support{
  padding: 2rem;
  width: 90%;
  min-height: 80vh;
  background-color: #fff;
  border-radius:15px;
}
.tabs{
  min-width:15rem;
  display: flex;
}
.tab{
  cursor: pointer;
  opacity: .6;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  font-size: 1.2rem;
  color: #333;
  padding: 1rem;
  background-color: #d3d3d3;
}
.active-tab{
  opacity: 1;
  background-color: #fff;
  border: 2px solid #eee;
}
.tab-view{
  border: 2px solid #eee;
}
</style>
