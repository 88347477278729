<template>
<div id="SqueezeSignup">
  <h1 class="head-text">Sign Up Today!</h1>
  <hr class="head-border"/>
  <p class="announe-text">
    Its our Goal to get our system updated with all of the relevant
    information so we can keep our database up to date.
    Thank you for being our Partner
  </p>
  <div class="input-area">
    <div class="input-area-header">
      <h2>Step 1</h2>
    </div>
    <p class="error-alert">{{feedback1}}</p>
    <div class="input-block">
      <input type="text" v-model="userdata.fname" placeholder="First Name" :style="requiredStyle"/>
      <input type="text" v-model="userdata.lname" placeholder="Last Name" :style="requiredStyle"/>
      <input type="email" v-model="userdata.email" placeholder="Email" :style="requiredStyle"/>
      <input type="tel" v-model="userdata.phone" placeholder="Phone"/>
      <div class="input-area-header">
      </div>
      <input type="text" v-model="userdata.address.line1" placeholder="Full Address"/>
      <input type="text" v-model="userdata.address.city" placeholder="City"/>
      <input type="text" v-model="userdata.address.state" placeholder="State"/>
      <input type="text" v-model="userdata.address.country" placeholder="Country"/>
      <input type="zip"  v-model="userdata.address.zip" placeholder="Zip"/>
    </div>
  </div>
  <div class="input-area">
    <div class="input-area-header">
      <h2>Step 2</h2>
    </div>
    <div class="input-block" >
      <p class="manual-label">Plan Type</p>

      <div class="packages">
        <div class="cashbox">
          <div class="package" v-for="(min, idx) in minutepackages" :key="idx" @click="selectPackage(min)">
            <div class="foreground">
              <div class="package-card">
                <h3 class="price-text">${{(min.price).toLocaleString()}}</h3>
                <ul class="subtext">
                  <li>${{min.text_bound}}/text inbound/outbound</li>
                  <li>${{min.voicem}}/voicemail</li>
                  <li>${{min.call_route}}/call routing</li>
                  <li>${{min.mo_num}}/mo per number</li></ul>
                <div class="minute-card">
                </div>
              </div>
              <button class="package-btn" @click="selectPackage(min)">ORDER</button>
            </div>
            <div class="gradient backdrop" :class="{'selected-backdrop': selected_package.tag == min.tag}"></div>
          </div>
        </div>
    </div>
    </div>
    <!-- <div v-if="!userdata.email" class="no-text">Please fill out all of the information above.</div> -->

  </div>

  <div class="input-area" >
    <div class="input-area-header">
      <h2>Step 3</h2>
    </div>
    <p class="error-alert" v-if="feedback1">{{feedback1}} above before continuing</p>
    <div class="checkout-wrapper" :class="{'hide': selected_package.tag == '' || displayCheckout == false}">
      <RVMCardCheckout :total="selected_package.price" @saleMade="saleMade" product="rvm" :userdata="userdata" :selected_package="selected_package"/>
      <p>or</p>
     <div id="paypal" :class="{'hide': processing}"></div>
     <vue-spinner v-if="processing" :processing="processing" color="var(--link-light)" size="76px"></vue-spinner>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import {mapMutations} from 'vuex'
import RVMCardCheckout from './RVMCardCheckout.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  name: 'RVMSignup',
  components: {
    RVMCardCheckout,
    'vue-spinner': ClipLoader,
  },
  data(){
    return{
      processing: false,
      displayCheckout: false,
      feedback1: '',
      userdata:{
        fname: '',
        lname: '',
        email: '',
        phone: '',
        package_selected: {},
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: '',
          zip: '',
        },
      },
      minutepackages:[
        {tag: 'pack25', price: 25, text_bound: .08, voicem:.05, call_route: .04, mo_num: 1},
        {tag: 'pack100', price: 100, text_bound: .06, voicem:.05, call_route: .04, mo_num: 1},
        {tag: 'pack250', price: 250, text_bound: .04, voicem:.04, call_route: .04, mo_num: 1},
        {tag: 'pack500', price: 500 , text_bound: .02, voicem:.04, call_route: .04, mo_num: 1},
        {tag: 'pack1000', price: 1000, text_bound: .015, voicem:.02, call_route: .02, mo_num: 1},
      ],
      selected_package: {price: 0, text_bound: 0, tag: '', voicem:0, call_route:0, mo_num:1},
      requiredStyle: {'border': '2px solid #fff'},
    }
  },
  methods:{
    ...mapMutations(['SET_SQUEEZE_PACKAGE']),
    displayMinPerCost(count, cost){
      return ((cost/count)*100).toFixed(1)
    },
    saleMade(val){
      this.signupUser()
      this.hasPaid = val
    },
    selectPackage(val){
      this.selected_package = val
      this.validate()
    },
    payWithPaypal(){
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: `User has brought the $${this.selected_package.price} package`,
                amount:{
                  currency_code: "USD",
                  value: this.selected_package.price
                }
              }
            ]
          })
        },
        onApprove:(data, actions) => {
        // This function captures the funds from the transaction.
        return actions.order.capture().then((details) =>{
          // This function shows a transaction success message to your buyer.
          this.saleMade(true)
          // alert('Transaction completed. ')
          })
        }
      }).render('#paypal')
    },
    selectList(list){
      this.userdata.list_type = list
      console.log(list)
    },
    validate(){
      let valid = true;
      this.requiredStyle = {'border': '2px solid #cc0000'}

      if (!this.userdata.fname) {
        valid = false;
        this.feedback1 = 'Please enter all required fields';
      }

      if (!this.userdata.lname) {
        valid = false;
        this.feedback1 = 'Please enter all required fields';
      }

      if (!this.userdata.email) {
        valid = false;
        this.feedback1 = 'Please enter all required fields';
      }

      if(valid){
        this.displayCheckout = true
      }
    },
    signupUser(){
      this.processing = true
      let payload = {
        fname: this.userdata.fname,
        lname:this.userdata.lname,
        email: this.userdata.email,
        phone: this.userdata.phone,
        list_package: this.selected_package,
        address: this.userdata.address,
        zip: this.userdata.address.zip
      }
      axios.post(this.$api_path+`skip_source/rvmtxtblast_lead`, payload)
      .then((res)=>{
        // this.SET_SQUEEZE_PACKAGE(this.userdata)
        this.feedback = res.data.feedback
        this.status = res.data.status
        this.processing = false
        this.$router.push({name: 'MinutesOrder'})
      }).catch((err)=>{
        this.feedback = 'Sorry we are experiencing difficulties and we could not send your email. If this error persists please contact support.'
        this.processing = false
        this.status = 'error'
        console.log(err)
      })
    },
  },
  mounted(){
    this.payWithPaypal()
  }
}
</script>
<style scoped>
#SqueezeSignup{
  text-align:center;
  position: relative;
  width: 100%;
  padding: 2rem 0;
  background-color: #000;
  /* max-width: 900px; */
}
.error-alert{
  color: #cc0000;
}
.head-border{
  width: 50%;
  margin: 2rem auto;
  height: 3px;
  color: var(--primary);
  background-color: var(--primary);
}
.head-text{
  font-size: 2.7rem;
  text-transform: uppercase;
  color: #fff;
}
.announe-text{
  width: 60%;
  margin: 2rem auto;
  font-size: 1.2rem;
  color: #fff;
}
.input-area-header{
  width: 55%;
  margin: 2rem auto;
  font-size: 1.3rem;
  color: #fff;
  border-bottom: 3px solid var(--primary);
}
.input-block{
  width: 80%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.manual-label{
  color: #fff;
  text-align: left;
}
.input-block input{
  transition: 1s;
  color:#fff;
  width: 80%;
  border: 2px solid #fff;
  padding: .3rem 2rem;
  margin: .6rem auto;
  background-color: transparent;
  border-radius: 16px;
  font-size: 1.3rem;
}
.input-block textarea{
  transition: 1s;
  color:#fff;
  width: 80%;
  border: 2px solid #fff;
  padding: .3rem 2rem;
  margin: .6rem auto;
  background-color: transparent;
  border-radius: 16px;
  font-size: 1.3rem;
  min-height: 10rem;
  resize: none;
}
.checkout-btn{
  width: 65%;
  font-size: 1.6rem;
}


.packages{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.hero{
  width: 100%;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--primary);
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items:center;
}
.container{
  margin: 0 auto;
  max-width: 900px;

}
.summary{
  margin: 0 auto;
  max-width: 900px;
}
.cashbox{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem auto;
  /* border: 3px solid var(--primary); */
}
.package{
  min-height: 10rem;
  background-color: #efefef;
  display:flex;
  width: 100%;
  border: 1px solid var(--primary);
  position: relative;
  margin: .5rem auto;
  /* padding: 1rem; */
  overflow:hidden;
  justify-content: space-evenly;
  align-items: space-between;
  -webkit-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  transition: 1s;
  cursor:pointer;
}
.package:hover{
  box-shadow:none;
  transform: translateY(-3px);
}
.package:hover .package-card{
  transform: translate(-6px, -9px);
  height: 100%;
  -webkit-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
}
.package:hover .price-text,
.package:hover .subtext{
  transform: translate(-9px, -12px);
}
.package h3{
  font-size: 2.4rem;
}
.package-card{
  background-color: #fff;
  height: 80%;
  display:flex;
  transition: 1s;
  width: 60%;
  padding: .4rem 1.5rem;
  /* padding: 10rem; */
  margin:auto;
  justify-content: space-evenly;
  align-items: center;
}
.foreground{
  left: 0;
  top:0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display:flex;
  justify-content: space-between;
  align-items: center;
  position:absolute;
}
.backdrop{
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  left: 0;
  transition: 1s;
  position:absolute;
}
.selected-backdrop{
  transform: translateY(0%);
}
.package:hover .backdrop{
  transform: translateY(0%);
}
.price-text{
  transition: 1s;
  background-color: transparent;
}
.subtext{
  margin-left: 3rem;
  font-weight: bold;
  text-align: left;
  transition: 1s;
  width: 60%;
  font-size: 17px;
  font-style: italic;
}
.package-btn{
  text-decoration: none;
  border-radius: 10px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 1rem 2rem;
  margin-right: 1.4rem;
  color: #fff;
}
.package:hover  .package-btn{
  background-color: #fff;
    transition: 2s;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.outreach{
  background-color: #ddd;
  padding: 3rem;
}
.checkout-wrapper{
  width: 80%;
  margin: 1rem auto;
  border-radius: 10px;
  background-color: #fff;
}
.hide{display:none;}
.no-text{
  color: #fff;
}
@media screen and (max-width: 900px) {
  .head-text{
    font-size: 2.2rem;
  }
  .subtext{
    font-size: 8px;
  }
  .package{
    min-height: 8rem;
  }
  .package a{
    padding: .6rem 1rem;
    margin-right: .5rem;
    font-size: .7rem !important;
  }
  .price-text{
    font-size: 1.3rem !important;
  }
}
@media screen and (max-width: 600px) {
  .input-block input{
    padding: .3rem 1.6rem;
    font-size: .9rem;
  }
}
</style>
