<template>
  <div id="AboutUs">
    <img src="@/assets/logo2.png" class="logo"/>
    <h1>What is Skip Soure?</h1>

    <div class="about-text container">
      <p>Skip Source provides you the with High Quality data faster and at the best price! The accuracy and efficiency on our Skip Tracing process makes us different and more effective than other skip tracing companies. We provide Phone scripts, RVM Scripts, and txt blast scripts for our customers. Your Skip Tracing results are ready for you within 48 Hours (2 Business Days) for ELITE MEMBERS & 72 hours (3 Business Days) for non ELITE MEMBERS. Our customer service team is available 24/7. Another benefit of working with Skip Source is the ability to skip trace businesses such as LLC’s.

      </p>
    </div>
    <h1>What is Skip Tracing?</h1>
    <div class="about-text container">

      <p>Skip tracing is performed by collecting information on individuals in question. All information recovered is analyzed, verified, and used to determine the location and contact information of the individuals searched
      </p>
    </div>

  </div>
</template>

<script>

export default {
  name: 'AboutUs',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>

<style scoped>
#AboutUs{
  margin: 2rem 4rem;
  min-height: 60vh;
}
.AboutUs img{
  text-align:center;
}
.logo{
  width: 70%;
  margin: 1rem auto;
}
.about-text{
  margin-left: 2rem;
  margin-right: 2rem;
}
h1{
  margin: 2rem auto;
  transition: 1s;
  font-size: 2rem;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.about-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #AboutUs h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .about-text{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
