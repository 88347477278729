<template>
  <div id="FreeResources">

    <h1>Free Resources</h1>
    <div class="free-resource-list" v-for="(free, idx) in free_resource_list" :key="idx">
      <div class="free-resource">
        <a :href="free.link">
          <img :src="free.icon" />
          <h3>{{free.name}}</h3>
        </a>
      </div>
    </div>

  <div class="container text-default text-center">
    <small>Have a question you want to ask, but it is not on here? Ask us <router-link :to="{name: 'Contact'}">here!</router-link></small>
  </div>
  </div>
</template>
<script>

export default {
  name: 'FreeResources',
  components: {
  },
  data(){
    return{
      free_resource_list: [
        {name:'Wholesale Glossary', link:'/free-resources/wholesale-glossary', icon: require('@/assets/icons/free-products/vocabulary.svg')},
        {name:'RVM Script', link:'/free-resources/rvm-script', icon:require('@/assets/icons/free-products/script.svg')},
        {name:'Text Blast Templates', link:'/free-resources/text-blast-templates', icon: require('@/assets/icons/free-products/explosion.svg')}
      ]
    }
  }
}
</script>
<style scoped>
#FreeResources{
  min-height: 60vh;
  margin: 2rem 4rem;
}
#FreeResources h2{
  font-size: 2rem;
}
.container{
  width: 80%;
  margin: 2rem auto;
}
.free-resource{
  min-width: 10rem;
  margin: .5rem auto;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  padding: 0rem;
  border-radius:10px;
}
.free-resource a{display: flex; justify-content: flex-start; align-items:center; padding: 1.4rem;}
.free-resource a img{width: 3rem; margin-right: .6rem;}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.question-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #FreeResources h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .faq-list{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
