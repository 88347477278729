<template>
  <div id="Blogs">
    <h1>BLOGS</h1>
    <br /><br />
    <carousel
    ref="blog-carousel"
    :per-page="5"
    paginationActiveColor="#aaa"
    paginationColor="#e3e3e3"
    id="recommended-carousel">
    <slide class="slide-space" v-for="(blog, idx) in blogs" :key="idx">
      <div @click="gotoBlog(blog.slug)">
      <img :src="blog.thumbnail"/>
      <p class="title"><strong>{{blog.title}}</strong></p>
      <small class="additional-info">by {{blog.author}} on {{$relativeDate(blog.created)}}</small>
      <p class="blog-metabody">{{blog.metabody}}</p>
    </div>
  </slide>

</carousel>
  </div>
</template>
<script>
import {mapGetters,mapMutations} from "vuex"
import {db} from '@/_firebase/init'

export default {
  components: {
  },
  name: 'Blogs',
  data(){
    return{
      blogs :[
        {
          title: 'Lorem ipsum dolor sit amet',
          created: 1602363622,
          thumbnail:'https://picsum.photos/400/300',
          metabody: 'zxdfghjkouytr',
          slug: ''
        },
      ]
    }
  },
  methods:{
    async getBlogs(){
      this.blogs = []
      const blogsRef = db.collection('blogs');
      const snapshot = await blogsRef.get();
      snapshot.forEach(doc => {
        this.blogs.push( {
          title: doc.data().title,
          slug: doc.data().slug,
          metabody: doc.data().metabody,
          thumbnail: doc.data().thumbnail,
          author: doc.data().author,
          created: doc.data().created
        })
      });
      this.blogs.sort((a, b) => (a.created > b.created ) ? -1 : 1)
    },
    gotoBlog(slug){
      console.log(slug)
      this.$router.push({name: 'BlogPage', params:{slug: slug}})
    },
    setRandomBar(){
      const max = 1000
      const min_time = Math.ceil(1500000000);
      const max_time = Math.floor(1600000000);
      let random_val;
      let random_time;
      for(let blog of this.blogs){
          random_val =  Math.floor(Math.random() * Math.floor(max));
          random_time =  Math.floor(Math.random() * (max_time - min_time) + min_time);
          blog['img'] = `https://picsum.photos/id/${random_val}/400/300`
          blog['created'] = random_time
      }
    }
  },
  created(){
    this.setRandomBar()
    this.getBlogs()
  }
}
</script>
<style scoped>
#Blogs{

  width: 100%;
  margin: 8rem auto;
  padding: 2rem 0rem;
  text-align: center;
  transition: 1s;
  display: grid;
}
#recommended-carousel{
  min-height: 15rem;
  height: 100%;
}
.slide-space {
  max-width: 20rem;
  margin: .3rem;
  cursor:pointer;
  transition: 1s;
  background-color: #fff;
  border-radius: 20px;
}
.slide-space img{
  width: 90%;
  border-radius: 15px;
}
.slide-space:hover{
  transform: translate(-2px, -2px) scale(1.02);
}
.title{
  margin: .2rem auto;
  font-size: .8rem;
}
.blog-metabody{
  font-size: .7rem;
  color: #777;
}
.additional-info{
  font-size: .7rem;
  color: Var(--primary);
}
@media only screen and (max-width: 900px) {
  .slide-space img{
    width: 10rem;
  }
  .blog-metabody{
    font-size: .5rem;
  }
}
@media only screen and (max-width: 600px) {
  #Blogs{
    width: 90%;
    margin: 4rem auto;
  }

}
@media only screen and (max-width: 400px) {
  .how-it-works-text p{
    font-size: .6rem;
    margin: 1rem 0;
  }
}
</style>
