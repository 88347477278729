<template>
  <div class="flex flex-col justify-center">
    <h1 class="text-4xl font-bold w-10/12 mx-auto">Your cart</h1>
  <div class="relative m-3 flex flex-wrap mx-auto justify-center">

    <div class="relative max-w-sm min-w-[340px] bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer">
      <div class="overflow-x-hidden rounded-2xl relative">
        <img class="h-1/2 rounded-2xl w-full object-cover" src="@/assets/icons/more-products/money-bag-full.svg">

        <!-- <p class="absolute right-2 top-2 bg-white rounded-full p-2 cursor-pointer group border border-gray-700">
          <img src="@/assets/icons/close.svg" class="w-4 h-4" />
         </p> -->

      </div>
      <div class="mt-4 pl-2 mb-2 flex justify-between ">
        <div>
          <p class="text-4xl mb-3 font-semibold text-gray-900 mb-0">{{productdata.title}}</p>
          <p class="text-2xl text-md text-gray-800 mt-0">${{productdata.price/100}}</p>
          <p class="text-md text-gray-800 mt-0">State:
            <span class="font-bold text-yellow-700">{{productdata.state_providence}}</span>
            </p>
        </div>
      </div>
      <div class="text-lg font-semibold " v-html="description"></div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'CartCard',
  components: {
  },
  props: ['productdata'],
  data(){
    return{
      description: `
      <p>You will receive a minimum of 5k cash buyers and real state agents in your market.
      You will receive first name, last name,  and a minimum of two phone numbers and two emails per record.</p> `,
    }
  },
  methods:{
  },
}
</script>
<style scoped>
</style>
