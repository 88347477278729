<template>
  <div id="MinutesHello">
    <div class="hero gradient">
      <h1>Skip Source RVM & TEXT BLAST SOFTWARE</h1>
    </div>
    <div class="presentation">
      <div class="skip-source-phone">
        <img src="@/assets/media/skip-source-phone2.png" />
      </div>
    </div>
    <div class="container">
    <div class="summary">
      <h2>Select from the plans below:</h2>
      <p>
Please allow 24-48 business hours for our team to set up your account: you will be emailed a receipt, log in instructions, and your account comes with free video tutorials, text and rvm scripts  to help you be as success as possible. We are honored to work with you and provide a efficient and effective solution to all of your real estate investing needs. All sales are final.</p>
    </div>
    <RVMSignup />
    <!-- <div class="packages">
      <div class="cashbox">
        <div class="package" v-for="(min, idx) in minutepackages" :key="idx">
          <div class="foreground">
            <div class="package-card">
              <h3 class="price-text">${{min.price}}</h3>
              <p class="subtext">{{min.minute_count/1000}}k MINUTES</p>
              <div class="minute-card">
                {{displayMinPerCost(min.minute_count, min.price)}} Cents a Minute
              </div>
            </div>
            <router-link :to="{name:'MinutesOrder'}">ORDER</router-link>
          </div>
          <div class="gradient backdrop"></div>
        </div>
      </div>
  </div> -->
    <br />

  <!-- <div class="training">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/ek1zbzbq5-M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div> -->

    <div class="outreach">
      <h1>For any questions or concerns please email support@skipsourcedata.com</h1>
        <br />
        <h2>(Please allow 24-48 Business Hours on Reponses from our Support Team)</h2>
    </div>
  </div>
</div>
</template>

<script>
import RVMSignup from '@/components/minutessell/RVMSignup.vue'

export default {
  name: 'MinutesHello',
  components: {
    RVMSignup

  },
  data(){
    return{
      minutepackages:[
        {price: 400, minute_count: 10000},
        {price: 750, minute_count: 25000},
        {price: 1000, minute_count: 50000},
        {price: 1700, minute_count: 100000},
      ],
      selected_package: {}
    }
  },
  methods:{
    displayMinPerCost(count, cost){
      return ((cost/count)*100).toFixed(1)
    }
  }
}
</script>
<style scoped>
#MinutesHello{
  text-align:center;
  position: relative;
  margin: 0 auto;
  /* max-width: 900px; */
}
.presentation{
  width: 100%;
  background-color: #444;
  display: flex;
  justify-content: center;
  align-items:center;
}
.skip-source-phone{
  background-color: #fff;
  width: 30%;
  margin: 2rem auto;
  padding: 2rem 5rem;
}
.skip-source-phone img{
  width:100%;
}
.packages{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.hero{
  width: 100%;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--primary);
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items:center;
}
.container{
  margin: 0 auto;
  max-width: 900px;

}
.summary{
  margin: 3rem auto;
  font-size: 1.3rem;
  max-width: 900px;
}
.cashbox{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 2rem auto;
  /* border: 3px solid var(--primary); */
}
.package{
  min-height: 20rem;
  background-color: #efefef;
  display:flex;
  width: 20%;
  border: 1px solid var(--primary);
  position:relative;
  margin: 0 auto;
  padding: 1rem;
  overflow:hidden;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: space-between;
  -webkit-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
transition: 1s;
}
.package:hover{
  box-shadow:none;
  transform: translateY(-3px);
}
.package:hover .package-card{
  transform: translate(-6px, -9px);
  -webkit-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 20px -15px rgba(0,0,0,0.75);
}
.package:hover .price-text,
.package:hover .subtext{
  transform: translate(-9px, -12px);
}
.package h3{
  font-size: 2.4rem;
}
.package-card{
  background-color: #fff;
  height: 60%;
  display:flex;
  transition: 1s;
  width: 90%;
  margin: 1rem auto;
  margin-bottom: 3rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: space-between;
}
.foreground{
  left: 0;
  top:0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  position:absolute;
}
.backdrop{
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  left: 0;
  transition: 1s;
  position:absolute;
}
.package:hover .backdrop{
  transform: translateY(0%);
}
.price-text{
  transition: 1s;
  background-color: transparent;
}
.subtext{
  transition: 1s;
  font-style: italic;
}
.package a{
  text-decoration: none;
  border-radius: 10px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 1rem 2rem;
  color: #fff;
}
.package:hover  a{
  background-color: #fff;
    transition: 2s;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.outreach{
  background-color: #ddd;
  padding: 3rem;
}
@media screen and (max-width: 600px){
  .hero{
    font-size: .9rem;
    height: 8rem;
  }
  .outreach{
    font-size: .5rem;
  }
}
</style>
