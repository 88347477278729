<template>
  <div id="WhatNewModal">
    <div class="overlay" @click="closeModal()"></div>
    <div class="modal">
      <div class="modal-header">
      <h1>What's New</h1>
      </div>
      <div class="modal-body">
        <img :src="contemporaryIcon" alt="What is New" class="illustration-whats-new"/>
        <p class="wn-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis mollis orci, non dapibus nisivalliturpis. Nullam felis tellus, gravida sit amet bibendum in, venenatis id orci. Nunc eu turpis at quam posuere fringilla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hime</p>
        <button class="confirm-btn" @click="closeModal()">Close</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'WhatNewModal',
  data(){
    return{
      contemporaryIcon: require('@/assets/illustrations/undraw_business_decisions_gjwy.svg'),
    }
  },
  methods:{
    closeModal(){this.$emit('closeModal', false)},
    toggleWhatsNew(){this.whats_new_modal = !this.whats_new_modal},
    gotoRoute(route){this.$router.push({name: route})}
  }
}
</script>
<style scoped>
.overlay{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100%;
  background-color: #000;
  opacity: .4;
  z-index: 101;
}
.modal{
  position: absolute;
  width: 40%;
  top: 26%;
  left: 30%;
  transition: 1s;
  border-radius: 10px;
  z-index: 102;
  background-color: #fff;
}
.modal-header{
  background-color: #222;
  color: #fff;
  border-radius: 10px 10px 0 0;
  text-align: center;
  padding: 1rem;
}
.modal-body{
  padding: 2rem;
  text-align: center;
}
.illustration-whats-new{
  transition: 1s;
  width: 60%;
}
.wn-text{
  margin: 1.5rem ;
  transition: 1s;
}
.confirm-btn{
  cursor: pointer;
  color:#fff;
  background-color: #2eb82e;
  padding: 1rem 3rem;
  font-size: 1.3rem;
  border:none;
  margin-top: 1rem;
  transition: 1s;
  border-radius: 10px;
}
</style>
