<template>
  <div id="">
    <Header />
      <slot />
    <Footer />
  </div>
</template>
<script>
import Header from "./sections/Header.vue";
import Footer from "./sections/Footer.vue";
import {mapState, mapGetters} from 'vuex'
export default{
components:{
  Header,
  Footer
},
computed:{
  ...mapGetters([
    'isAuthenthicated'
  ]),
}
}
</script>
<style scoped>
</style>
