<template>
  <div id="Overview">
    <div class="dashboard-header">
      <h2>Dashboard</h2>
    </div>

    <div class="balance-area">
      <!-- <h3>Balance</h3> -->
      <button class="add-balance" @click="gotoAddABalance()"> + Add Balance</button>
      <div class="file-count balance-box">
        <p class="main-text">Total Balance</p>
        <small> ${{(pricePerSkip/100)}} per skip</small>
        <p class="file-ratio">${{(user_stats.cash_balance/100).toFixed(2)}}</p>
        <p class="warning-">You do not have a balance, please load some funds to be able to skip records.</p>
      </div>
    </div>

    <br />
    <div class="main">
      <h3>Skip Trace Stats</h3>

      <div class="instant-skip-area">
        <button class="trace-btn" v-if="role != 'elite'" @click="gotoAddABalance()">Click Here to Get Access to Instant Skip Trace</button>
        <p>You now have the ability of Instant Skip Tracing</p>
      </div>


      <div class="files-count-grid">
        <div class="file-count" v-for="(fc, idx) in file_pack" :key="idx">
          <p class="main-text">{{fc.name}}</p>
          <p class="file-ratio">{{fc.ratio}}</p>
          <img :src="fc.icon" class="box-icon" />

          <p class="line-label" v-if="fc.percent >= 0"> {{fc.percent}}% of pending</p>
          <div class="line" v-if="fc.percent >= 0">
            <div class="bar"></div>
          </div>
        </div>

        <div class="file-count">
          <p class="main-text">Instant Skips</p>
          <p class="file-ratio">{{user_stats.records_skipped}}</p>
          <img src="@/assets/icons/task.svg" class="box-icon" />
        </div>
      </div>

      <!-- <div class="files-count-grid">
        <div class="file-count">
          <p class="main-text">Instant Skips</p>
          <p class="file-ratio">{{user_stats.records_skipped}}</p>
          <img src="@/assets/icons/task.svg" class="box-icon" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {db} from '@/_firebase/init';
export default {
  name: 'Overview',
  components:{
  },
  data(){
    return{
      user_stats:{
        cash_balance: 0,
        records_skipped: 0,
      },
      role: '',
      file_pack:[
        // {name:'Saved Bulk Files', ratio:'0 of 0', percent: 0, icon:require('@/assets/icons/saving.svg')},
        {name:'Uploaded Bulk Files', ratio:'0 of 0', percent: 0, icon:require('@/assets/icons/delivered.svg')},
        // {name:'Single SkipTrace Files', ratio:'0 Files', percent: -1},
      ]
    }
  },
  methods:{
    async getUserStats(){
      const userStatsRef = db.collection('user_stats').doc(this.userID);
      const doc = await userStatsRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        this.user_stats = doc.data()
        // this.file_pack[0].ratio = `${this.user_stats.pending_files}`
        this.file_pack[0].ratio = `${this.user_stats.files_submitted}`
        // this.file_pack[1].percent = 100 - (this.user_stats.pending_files/this.user_stats.files_submitted)
        console.log( doc.data())
      }
    },
    async getUserData(){
      const usersRef = db.collection('users').doc(this.userID);
      const doc = await usersRef.get();
      if (!doc.exists) {
      console.log('No such document!');
      } else {
        console.log(this.user_stats)
        this.role = doc.data().role
      }
    },
    gotoAddABalance(){
      this.$router.push({name: 'Payments'})
    }
  },
  computed:{
    ...mapState(['userID']),
    pricePerSkip(){
      let price_skip = 15
      // if(this.role == 'elite'){
      //   price_skip = 15
      // }else{
      //   price_skip = 17
      // }
      return price_skip
    }
  },
  created(){
    this.getUserStats()
    this.getUserData()
  }
}
</script>
<style scoped>
#Overview{
  padding: 2rem;
  width: 90%;
  min-height: 80vh;
  background-color: #fff;
  border-radius:15px;
}
.dashboard-header{
  font-size: 2rem;
}
.trace-btn{
  padding: .5rem 2rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 20px;
  cursor:pointer;
}
.add-balance{
  padding: .5rem 1rem;
  width: 20%;
  min-width: 10rem;
  margin: 2rem auto;
  border-radius: 10px;
  text-align: center;
  color:#fff;
  cursor:pointer;
  border: none;
  transition:1s;
  background-color: var(--primary);
}
.add-balance:hover{
  opacity:.7;
}
.files-count-grid{
  max-width: 80%;
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}
.file-count{
  padding: 1rem;
  height: 7rem;
  border-radius: 15px;
  border: 2px solid #f5f5f5;
  position: relative;
  overflow: hidden;
}
.balance-box{
  width:  60%;
}
.box-icon{
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(20deg);
  width: 4rem;
}
.main-text{
  font-size: 1.3rem;
}
.file-ratio{
  color: var(--link-light);
  font-size: 1.6rem;
  margin-left: 1rem;
  transition: 1s;
}
.line-label{
  margin-top: 1rem;
}
.bar{
  background-color: #324eb2;
  text-align: left;
  font-weight: bold;
  transition: 1.5s;
  padding: .2rem 0;
  height: .6rem;
  border-radius: 0 10px 10px 0;
  width: 2%;
}
.line{
  width: 100%;
  border-radius: 0 10px 10px 0;
  background-color: #aaa;
  height: 1rem
}
.balance-area{
  width: 55%;
}
</style>
