<template>
<div id="SqueezeSignup">
  <h1 class="head-text">Sign Up Today!</h1>
  <hr class="head-border"/>
  <p class="announe-text">
    Its our Goal to get our system updated with all of the relevant
    information so we can keep our database up to date.
    Thank you for being our Partner
  </p>
  <div class="input-area">
    <div class="input-area-header">
      <h2>Step 1</h2>
    </div>
    <p class="error-alert">{{feedback}}</p>
    <p class="success-alert">{{feedback0}}</p>
    <div class="input-block">
      <input type="text" v-model="userdata.fname" placeholder="First Name" :style="requiredStyle"/>
      <input type="text" v-model="userdata.lname" placeholder="Last Name" :style="requiredStyle"/>
      <input type="email" v-model="userdata.email" placeholder="Email" :style="requiredStyle"/>
      <input type="tel" v-model="userdata.phone" placeholder="Phone"/>
    </div>
  </div>
  <div class="input-area">
    <div class="input-area-header">
      <h2>Step 2</h2>
    </div>
    <div class="input-block">
      <p class="manual-label">Select a list</p>
      <p class="error-alert">{{feedback2}}</p>
      <ListSelector @selectList="selectList"/>
      <p class="label">Choose your Target  List City & Zip codes( must be 3-5 zip codes, and separated by a comma.)</p>

      <p class="error-alert">{{feedback3}}</p>
      <input type="text" v-model="userdata.city" placeholder="City" :style="requiredStyle"/>
      <input type="zip"  v-model="userdata.zip"
        placeholder="Zip Codes (MUST BE 3-5, seprate by comma)"
        :style="requiredStyle"
        />
      <textarea type="text" v-model="userdata.notes" placeholder="Notes"></textarea>
    </div>
  </div>
  <div class="input-area">
    <button class="primary-btn checkout-btn" v-if="!processing" @click="validate()">Checkout</button>
    <button class="primary-btn checkout-btn" v-else-if="processing">
      <vue-spinner color="#fff" size="30px"></vue-spinner>
    </button>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import {mapMutations} from 'vuex'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ListSelector from '@/components/squeeze/page/ListSelector.vue'
export default {
  name: 'SqueezeSignup',
  components: {
    'vue-spinner': ClipLoader,
    ListSelector
  },
  data(){
    return{
      processing: false,
      feedback0: '',
      feedback: '',
      feedback2: '',
      feedback3: '',
      userdata:{
        fname: '',
        lname: '',
        email: '',
        phone: '',
        list_type: '',
        city: '',
        zip: '',
        value_range: '',
        records_count: '',
        notes: ''
      },
      requiredStyle: {'border': '2px solid #fff'},
      selected_package: {}
    }
  },
  methods:{
    ...mapMutations(['SET_SQUEEZE_PACKAGE']),
    displayMinPerCost(count, cost){
      return ((cost/count)*100).toFixed(1)
    },
    selectList(list){
      this.userdata.list_type = list
      console.log(list)
    },
    validate(){
      let valid = true;
      this.requiredStyle = {'border': '2px solid #cc0000'}

      if (!this.userdata.fname) {
        valid = false;
        this.feedback = 'Please enter all required fields';
      }

      if (!this.userdata.lname) {
        valid = false;
        this.feedback = 'Please enter all required fields';
      }

      if (!this.userdata.email) {
        valid = false;
        this.feedback = 'Please enter all required fields';
      }

      if (!this.userdata.list_type) {
        valid = false;
        this.feedback2 = 'SELECT A LIST';
      }

      if (!this.userdata.city) {
        valid = false;
        this.feedback3 = 'Please enter all required fields';
      }

      if (!this.userdata.zip) {
        valid = false;
        this.feedback3 = 'Please enter all required fields';
      }

      if(valid){
        this.signupUser()
      }
    },
    signupUser(){
      this.processing = true
      let payload = {
        fname: this.userdata.fname,
        lname:this.userdata.lname,
        email: this.userdata.email,
        phone: this.userdata.phone,
        list_type: this.userdata.list_type,
        city: this.userdata.city,
        zip: this.userdata.zip,
        value_range: this.userdata.value_range,
        records_count: this.userdata.records_count,
        notes: this.userdata.notes
      }
      axios.post(this.$api_path+`skip_source/new_preprocessed_lead`, payload)
      .then((res)=>{
        this.SET_SQUEEZE_PACKAGE(this.userdata)
        this.feedback0 = res.data.feedback
        this.status = res.data.status
        setTimeout(() => {
          this.processing = false
          this.$router.push({name: 'SqueezeCheckout'})
        }, 3000);
        this.processing = false
      }).catch((err)=>{
        this.feedback = 'Sorry we are experiencing difficulties and we could not send your email. If this error persists please contact support.'
        this.processing = false
        this.status = 'error'
        console.log(err)
      })
    },
  }
}
</script>
<style scoped>
#SqueezeSignup{
  text-align:center;
  position: relative;
  width: 100%;
  padding: 2rem 0;
  background-color: #000;
  /* max-width: 900px; */
}
.error-alert{
  color: #cc0000;
}
.success-alert{
  color: #85e085;
}
.head-border{
  width: 50%;
  margin: 2rem auto;
  height: 3px;
  color: var(--primary);
  background-color: var(--primary);
}
.head-text{
  font-size: 2.7rem;
  text-transform: uppercase;
  color: #fff;
}
.announe-text{
  width: 60%;
  margin: 2rem auto;
  font-size: 1.2rem;
  color: #fff;
}
.input-area-header{
  width: 55%;
  margin: 2rem auto;
  font-size: 1.3rem;
  color: #fff;
  border-bottom: 3px solid var(--primary);
}
.input-block{
  width: 70%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.manual-label{
  color: #fff;
  text-align: left;
}
.input-block input{
  transition: 1s;
  color:#fff;
  width: 80%;
  border: 2px solid #fff;
  padding: .3rem 2rem;
  margin: .6rem auto;
  background-color: transparent;
  border-radius: 16px;
  font-size: 1.3rem;
}
.input-block textarea{
  transition: 1s;
  color:#fff;
  width: 80%;
  border: 2px solid #fff;
  padding: .3rem 2rem;
  margin: .6rem auto;
  background-color: transparent;
  border-radius: 16px;
  font-size: 1.3rem;
  min-height: 10rem;
  resize: none;
}
.checkout-btn{
  width: 65%;
  font-size: 1.6rem;
}
.label{
  color:#fff;
}
@media screen and (max-width: 900px) {
  .head-text{
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 600px) {
  .input-block input{
    padding: .3rem 1.6rem;
    font-size: .9rem;
  }
}
</style>
