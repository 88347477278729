<template>
  <div class="home">
    <!-- <NavBar /> -->
    <Hero />
    <!-- <Partners /> -->
    <FeaturedIn />
    <HowItWorks />
    <Products/>
    <Packages/>
    <Pricing />
    <Testimonials />
    <Blogs />
    <!-- <RVMBanner /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import NavBar from '@/landing/components/NavBar.vue'
import Hero from '@/landing/components/Hero.vue'
// import Partners from '@/landing/components/Partners.vue'
import Products from '@/landing/components/Products.vue'
import FeaturedIn from '@/landing/components/FeaturedIn.vue'
import Packages from '@/landing/components/Packages.vue'
import HowItWorks from '@/landing/components/HowItWorks.vue'
import Testimonials from '@/landing/components/Testimonials.vue'
import Pricing from '@/landing/components/Pricing.vue'
// import Footer from '@/landing/components/Footer.vue'
import RVMBanner from '@/landing/components/RVMBanner.vue'
import Blogs from '@/landing/components/Blogs.vue'

export default {
  name: 'home',
  components: {
    // NavBar,
    Hero,
    // Partners,
    Products,
    FeaturedIn,
    HowItWorks,
    Packages,
    Pricing,
    Testimonials,
    Blogs,
    // RVMBanner,
    // Footer,
  },
  data(){
    return{
    }
  },
  methods:{
  },
}
</script>
