<template>
  <div id="NotFound">
  <center>
    <img src="@/assets/logo.png" class="logo" />
    <br />
    <h1>Page Not Found!</h1>
    <p>
      <a href="/">Go home?</a>
    </p>
  </center>
  </div>
</template>

<script>

  export default {
    name: 'NotFound'
  }

</script>

<style scoped>
#NotFound{
  width:100%;
  text-align: center;
  height: 80vh;
}
  center {
    margin: 2vh auto;
  }
  .gfx{
    width: 40%;
  }
  h1 {
    color: var(--border);
    font-size: 2em;
  }
  .logo{
    width: 4rem;
    margin: 2rem auto;
  }
  p{
    margin: 2rem auto;
      font-size: 2rem;
  }
  p a{
    color: var(--primary);
    text-decoration: none;
  }
</style>
