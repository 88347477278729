<template>
  <div class="bg-white">
  <div class="pt-6 lg:flex">

    <!-- Image gallery -->
    <div class="mt-6 max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8">

      <div class="aspect-w-4 aspect-h-5 sm:rounded-lg">
        <img
          src="@/assets/icons/more-products/money-bag-full.svg"
          alt="buyers list"
          class="w-full h-full">
      </div>

    </div>

    <!-- Product info -->
    <div class="max-w-2xl mx-auto pt-10 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8">

      <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
        {{productdata.title}}
      </h1>

      <!-- Options -->
      <div class="mt-4">
        <h2 class="sr-only">Product information</h2>
        <p class="text-3xl text-green-600">${{productdata.price/100}}</p>

        <div>
          <h3 class="text-lg font-bold">Description</h3>

          <div class="space-y-6">
            <p class="text-base font-semibold text-gray-900" v-html="description">
            </p>
          </div>
        </div>

        <div class="mt-10">
          <!-- Colors -->
          <div>
            <h3 class="text-lg text-gray-900 font-medium">Select State</h3>
            <p class="text-red-600">{{feedback}}</p>

            <div class="relative text-lg w-48">
              <button
                class="flex items-center justify-between px-3 py-2 bg-white w-full border border-gray-500 rounded-lg"
                @click="isOptionsExpanded = !isOptionsExpanded"
                @blur="isOptionsExpanded = false"
              >
                <span>{{ selectedOption }}</span>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
                  :class="isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <transition
                enter-active-class="transform transition duration-500 ease-custom"
                enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                enter-to-class="translate-y-0 scale-y-100 opacity-100"
                leave-active-class="transform transition duration-300 ease-custom"
                leave-class="translate-y-0 scale-y-100 opacity-100"
                leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
              >
                <ul v-show="isOptionsExpanded"
                  class="absolute z-10 max-h-80 left-0 right-0 mb-4 bg-white divide-y rounded-lg shadow-lg overflow-scroll cursor-pointer"  >
                  <li
                    v-for="(option, index) in options"
                    :key="index"
                    class="px-3 py-2 transition-colors duration-300 hover:bg-gray-200"
                    @mousedown.prevent="setOption(option)">
                    {{ option }}
                  </li>
                </ul>
              </transition>
            </div>
          </div>
        </div>
          <button
              @click="setCheckoutLifecycle()"
              class="mt-10 w-full bg-primary border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              :class="{'bg-gray-500': selectedOption == 'Select one'}"
              >Proceed to checkout</button>
      </div>

    </div>
  </div>
</div>
</template>
<script>
import usa_states_json from '@/assets/json/usa_states.json'

export default {
  name: 'ProductOverview',
  components: {
  },
  props:['productdata'],
  data(){
    return{
      isOptionsExpanded: false,
      selectedOption: "Select one",
      options: usa_states_json,
      description: `
      <p>You Will receive a minimum of 5k cash buyers and real state agents in your market.
      <br />
      You will receive first name, last name,  and a minimum of two phone numbers and two emails per record.</p> `,
      feedback: ''
    }
  },
  methods:{
    setCheckoutLifecycle(){
      if(this.selectedOption == 'Select one'){
        this.feedback = 'Please select a state'
      }else{
        this.$emit('setCheckoutLifecycle', 'checkout')
      }
    },
    setOption(option) {
     this.selectedOption = option;
     this.$emit('setStateProvidence', option)
     this.isOptionsExpanded = false;
   }
  },
}
</script>
<style scoped>
.ease-custom {
  transition-timing-function: cubic-bezier(.61,-0.53,.43,1.43);
}
</style>
