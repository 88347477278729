<template>
  <div id="Blog">
    <div class="container">
      <div class="blog-thumbnail" :style="{'background-image': 'url(' + content.thumbnail + ')'}">
        <!-- <img :src="content.thumbnail" class="blog-thumbnail"/> -->
      </div>
      <div class="faq-content">
        <h2>{{content.title}}</h2>
        <p>by <span class="author-text">{{content.author}}</span> | {{$relativeDate(content.created)}}</p>
      </div>

      <div v-html="content.body"></div>
  </div>

  </div>
</template>

<script>
import {db} from '@/_firebase/init'

export default {
  name: 'BlogPage',
  components: {
  },
  data(){
    return{
      slug: this.$route.params.slug,
      content: {
        title: "AM I BILLED PER HIT RATE OR PER RECORD SUBMITTED?",
        slug: ``,
        thumbnail: '',
        created: 1604507643,
        author: ``,
        body: `Yes, you will be billed per record submitted. The results will depend on the accuracy of the information submitted, this is a per search service.`
      }
    }
  },
  methods:{
    async getBlog(){
      const blogRef = db.collection('blogs');
      const snapshot = await blogRef.where('slug', '==', this.slug).get();
      if (snapshot.empty) {
        console.log('No matching documents.');
        return;
      }

      snapshot.forEach(doc => {
        this.content = doc.data()
        // console.log(doc.id, '=>', doc.data());
      });
    }
  },
  created(){
    this.getBlog()
  }
}
</script>

<style scoped>
#Blog{
  margin: 2rem 4rem;
}
#Blog h2{
  font-size: 2rem;
}
.container{
  margin: 2rem auto;
  max-width: 900px;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.question-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
.blog-thumbnail{
  width: 100%;
  max-width: 900px;
  min-height: 10rem;
  height: 25rem;
  margin: .3rem auto;
  overflow:hidden;
  position:relative;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
}
.author-text{
  color: var(--primary);
}
@media screen and (max-width: 600px) {
  #Blog h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .container{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
