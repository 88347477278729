
const core_menu = [
  // { name: 'Overview', route: 'Overview', icon: require('@/assets/icons/menu_icons/overview_icon.svg'), style: {} },
  { name: 'Dashboard', route: 'Overview', icon: require('@/assets/icons/dashboard.svg'), style: {} },
  { name: 'SkipTrace', route: 'SkipTrace', icon: require('@/assets/icons/task.svg'), style: {} },
  { name: 'Payments', route: 'Payments', icon: require('@/assets/icons/credit-card.svg'), style: {} },
  { name: 'My Products', route: 'Payments', icon: require('@/assets/icons/credit-card.svg'), style: {} },
  { name: 'Support', route: 'DashboardSupport', icon: require('@/assets/icons/information.svg'), style: {} },
  { name: 'Account', route: 'Account', icon: require('@/assets/icons/user.svg'), style: {} },
  { name: 'Home', route: 'Land', icon: require('@/assets/icons/menu.svg'), style: {} },
]
let minutesdata = window.localStorage.getItem('selected_package');
let squeezedata = window.localStorage.getItem('squeeze_funnel');
let buyers_list_product = window.localStorage.getItem('buyers_list_product');
const  testing_mode = false

export default {
  //Main
  auth: false,
  token: localStorage.getItem('usertoken') || '',
  squeeze_funnel: squeezedata ? JSON.parse(squeezedata) : {},
  buyers_list_product: buyers_list_product ? JSON.parse(buyers_list_product) : {},
  userID: localStorage.getItem('uid') || '',
  membership: localStorage.getItem('membership') || '',
  theme: localStorage.getItem('theme') || 'light',
  selected_package: minutesdata ? JSON.parse(minutesdata) : {},
  sideMenu: core_menu,
  processing: false,
  feedback: null,
  testingMode: testing_mode,
  feedtype: '',
  cash_balance: localStorage.getItem('cash_balance') || 0,
  stripeTracePubKey: testing_mode ? 'pk_test_UgjTlJ29pHgZYa24CIyAJlc200JtBP20gh': 'pk_live_51Hgv1xGUxlkUcKrCPHIgaHmHtE9ACTtLOlprLabOFm45nbJLT0Tt7ap7AAcxihdr3iUHWPLt5jOQt54EqLdwVhMi00fV6ljj9k',
  //test: pk_test_UgjTlJ29pHgZYa24CIyAJlc200JtBP20gh
  stripeRVMPubKey: testing_mode ? 'pk_test_UgjTlJ29pHgZYa24CIyAJlc200JtBP20gh': 'pk_live_51Hy6GRFPuZtUiRvyBUPyjtMRrBXCNEax94ZY7viDnggjlcpQ6rOHBF1jjot22kAprwj4x03ScbKYgjjVec6w2TZl00j2Q8EeCC',
  elitePlanPrice: 1999,
}
