<template>
  <div id="Packages">
    <div class="container">
      <div class="left-col">
        <div class="membership">
          <h2>MEMBERSHIP BENEFITS</h2>
          <div class="divider"></div>
          <!-- <h3>${{ $fmtPrice(elitePlanPrice) }} per month</h3> -->
          <ul v-for="(feat, idx) in elite_perks" :key="idx">
            <li>{{feat}}</li>
          </ul>
        </div>

        <!-- <div class="membership membership-free">
          <h2>BASIC MEMBERSHIP:</h2>
          <ul v-for="(feat, idx) in free_perks" :key="idx">
            <li>{{feat}}</li>
          </ul>
        </div> -->

        <!-- <div class="testinmonial-img" :style="{'background-image': 'url(' + test + ')'}" v-for="(test, idx) in testimonial_pics" :key="idx"></div> -->
      </div>
      <div class="right-side">
        <h1>Skip Source!</h1>
          <p>If you have skip traced a homeowner and still are unable to reach them, we highly recommend that you pull a relationship report. Here at Skip Source, relationship reports (the phone numbers of the relatives & likely associates of the homeowner) are WAY underutilized, but they can be so important that they can make the difference between getting a deal and not getting a deal. We offer real estate skip tracing that you need!</p>
          <br />
          <router-link :to="{name: 'HowItWorks'}"  class="primary-btn link" >Here's How It Works</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  components: {
  },
  name: 'Packages',
  data(){
    return{
      elite_perks:[
        '$0.15 PER SKIP',
        'INSTANT turnaround time on skip tracing',
        'LLC Skip Tracing',
        'Unlimited skip tracing',
        'Free Resources',
        'Member Support Area',
        'Innovative SKIP SOURCE: RVM & TEXT BLAST SOFTWARE',
        'Direct Mail Marketing and Direct Mail Coaching',
        'Direct Mail Marketing Discounts',
        'Free Market Analysis',
      ],
      free_perks:[
        '$0.17 PER SKIP',
        '48 Hour turnaround time on skip tracing',
        'Unlimited skip tracing',
        'Free Resources',
        'Member Support Area',
        'Innovative SKIP SOURCE: RVM & TEXT BLAST SOFTWARE',
        'Direct Mail Marketing and Direct Mail Coaching',
        'Free Market Analysis',
      ]
    }
  },
  methods:{

  },
  computed:{
    ...mapState(['elitePlanPrice'])
  }
}
</script>
<style scoped>
#Packages{
  background: rgb(36,5,5);
  background: -moz-linear-gradient(93deg, rgba(36,5,5,1) 0%, rgba(208,15,15,1) 100%);
  background: -webkit-linear-gradient(93deg, rgba(36,5,5,1) 0%, rgba(208,15,15,1) 100%);
  background: linear-gradient(93deg, rgba(36,5,5,1) 0%, rgba(208,15,15,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#240505",endColorstr="#d00f0f",GradientType=1);
  width: 100%;
  margin: 0 auto;
}
.container{
  width: 90%;
  margin: 0 auto;
  text-align: center;
  transition: 1s;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 2px;
  justify-items: stretch;
  align-items: stretch;
}
.left-col{
  display: grid;
  margin: 1rem;
  disply: flex;
  align-items:center;
  justify-content: center;
}
.membership{
  background-color: #690707;
  border:2px solid #fff;
  width: 80%;
  padding: 2rem;
  margin: 1rem auto;
  color: #fff;
}
.membership-free{
  background-color: rgb(58,51,3);
}
.membership ul li{
  text-align: left;
  margin: .5rem auto;
}
.divider{
  height: 2px;
  width: 80%;
  background: #fff;
}

/* .testinmonial-img{
  width: 100%;
  overflow:hidden;
  position: relative;
  min-height: 20rem;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.right-side{
  color: #fff;
  width: 90%;
  margin: 4rem auto;
  font-size: 1.03rem;
  transition: 1s;
  text-align: left;
}
.right-side h1{
  font-size: 2rem;
  margin: 2rem 0;
}
.right-side p{
  margin: 2rem auto;
}
.img-pic{
  width: 20rem;
  transition: 1s;
}
.primary-btn{
  border: 2px solid #fff;
  background-color: var(--primary);
}
.right-side a,
.right-side a:visited{
  text-decoration: none;
  color: #fff;
}
.link{
  text-decoration: none;
}
@media only screen and (max-width: 900px) {
  .container{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .left-col{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media only screen and (max-width: 600px) {
  #Packages{
    max-height: 250vh;
    overflow:hidden;
  }
  .testinmonial-img{
    min-height: 15rem;
  }
  .img-pic{
    margin-top: 2rem;
    width: 10rem;
  }
  .right-side h1{
    font-size: 3rem;
    margin: 2rem 0;
  }
  .right-side p{
    width: 80%;
    font-size: .7rem;
    margin: 2rem auto;
  }
}
@media only screen and (max-width: 400px) {
  .right-side p{
    font-size: .6rem;
    margin: 1rem 0;
  }
}
</style>
