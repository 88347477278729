<template>
  <div class="bg-gray-50">
    <div class="max-w-7xl min-h-2/3 mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex items-center lg:justify-between flex-col">
      <h2 class="font-extrabold tracking-tight text-gray-900 text-center mx-auto w-1/2">
        <span class="bloc text-3xl">Thank you for your purchase!</span>
        <span class="block text-indigo-600  text-2xl  my-5">You will recieve an email from us within less 24 hours about your package.</span>
      </h2>

      <h4 class="font-extrabold tracking-tight text-center mx-auto w-1/2">
        <span class="bloc text-xl">If you have any issues, please contact support@skipsourcedata.com</span>
      </h4>

      <div class="mt-8 flex flex-shrink-0">
        <div class="inline-flex rounded-md shadow">
          <a href="/" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700">
            Return Home
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReceiptResponse',
  components: {
  },
  data(){
    return{
      product: {

      }
    }
  },
  methods:{
  },
}
</script>
<style scoped>
</style>
