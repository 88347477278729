<template>
  <div id="MoreProducts">
    <div class="more-product-header">
      <h1 class="font-bold">Save Time and Money!</h1>
    </div>

    <div class="more-product-list">
      <div v-for="(product, idx) in more_products" :key="idx" class="product-box">
        <div class="product-icon" :style="{'background-image': 'url(' + product.icon + ')'}">
        </div>
        <p class="title ">{{product.name}}</p>
        <a :href="product.link" target="_blank" class="product-btn" v-if="product.tag != 'crm' "> GET IT NOW</a>
        <button v-else> COMING SOON</button>
        <h3 v-if="product.price">${{ (product.price/100).toLocaleString()}}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from '@/_firebase/init'

export default {
  name: 'MoreProducts',
  components: {
  },
  data(){
    return{
      more_products:[
        // {icon: require('@/assets/logo.svg'),
        // link: 'https://gumroad.com/wealthforever#sSEgj',
        // name: 'Wholesaling 101 Documents', price: 10000},
        // {icon: require('@/assets/logo.svg'),
        // link: 'https://gumroad.com/wealthforever#eVmZaa',
        // name: 'Joint Venture Basics (Wholesaling Real Estate)', price: 15000},
        // {icon: require('@/assets/logo.svg'),
        // link: 'https://gumroad.com/wealthforever#IWuBOW',
        // name: 'Wholesale Mastery 101 RELOADED', price: 25000},
        {icon: require('@/assets/icons/more-products/phone.svg'),
        link: '/minutes', name: 'SKIP SOURCE RVM & TEXT BLAST SOFTWARE', tag: 'rvm'},
        {icon: require('@/assets/icons/more-products/clipboard.svg'),
        link: '/squeeze', name: 'Distressed Seller List', tag: 'sqz'},
        {icon: require('@/assets/icons/more-products/money-bag.svg'),
        link: '/buyers-list', name: 'Cash Buyers, and Agents List', tag: 'byr'},
        {icon: require('@/assets/icons/more-products/crm.svg'),
        link: '#', name: 'Skip Source CRM', tag: 'crm'},
        // {icon: require('@/assets/icons/more-products/merge.svg'),
        // link: '/more-products', name: 'Merger and Splitter', price: 120400},
      ]
    }
  },
  methods:{
  },
  created(){
  }
}
</script>

<style scoped>
#MoreProducts{
  margin: 2rem 4rem;
}
.product-icon {
  width: 10rem;
  min-height: 8rem;
  overflow:hidden;
  position: relative;
  /* Set a specific height */
  background-position:center;
  background-repeat: no-repeat;
  background-size: contain;
}
.more-product-header{
  width: 80%;
  margin: 2rem auto;
  text-align:center;
}
.more-product-header h1{
  font-size: 3rem;
  text-transform: uppercase;
}
.more-product-list{
  width: 80%;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr .5fr;
  grid-column-gap: 20px;
  grid-row-gap: 6rem;
  justify-items: center;
  align-items: center;
  transition: 1s;
}
.product-box{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  text-align: center;
  transition: 1s;
}
.title{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
  width: 80%;
  margin: 1rem auto;
  transition: 1s;
}
.product-box h3{
  font-size: 1.7rem;
    transition: 1s;
}
.product-btn{
  border-radius: 10px;
  padding: 1rem 2rem;
  margin: 1rem;
  color: #fff;
  background-color: var(--primary);
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
}
.container{
  margin: 2rem auto;
  max-width: 900px;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.question-text{
  font-weight: bold;
}

.author-text{
  color: var(--primary);
}
@media screen and (max-width: 900px) {
  .product-box .title{
    font-size: .8rem;
    transition: 1s;
  }
}
@media screen and (max-width: 600px) {
  #MoreProducts{
    margin: 2rem 0;
    min-height: 80vh;
  }
  .product-box .title{
    font-size: .6rem;
    transition: 1s;
  }
  .more-product-list{
    width: 90%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  .product-box{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    text-align: center;
    transition: 1s;
  }
  .more-product-header h1{
    font-size: 2rem;
    width: 100%;
    marign: 0;
  }
  .product-box h2{
    width: 100%;
    font-size: .8rem;
  }
  .product-btn{
    width: 80%;
    padding: .2rem 1rem;
    font-size: .7rem;
  }
  .product-box h3{
    font-size: 1.2rem;
  }
  .more-product-list{
    grid-template-columns: 1fr 1fr;
  }
}
</style>
