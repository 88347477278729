<template>
  <div id="GateAuth">
    <div class="login-area">
      <div class="brand-area" @click="gotoHome()">
        <img src="@/assets/skip-logo.jpg" class="logo-brand"/>
      </div>
      <div class="beta-tag">BETA</div>
      <br />
      <router-view />
  </div>
  <div class="flat-mountains" :style="{'backgroundImage': 'url(' + bkgd_img + ')'}"></div>
  </div>
</template>

<script>

export default {
  name: 'GateAuth',
  components: {
  },
  data(){
    return{
      loggingIn: true,
      email:'',
      password:'',
      bkgd_img: require('@/assets/suburbs.jpg'),
    }
  },
  methods:{
    gotoHome(){
      this.$router.push({name: 'Land'})
    },
    login(){
      this.$router.push({name: 'ListProperty'})
    },
    changeAuth(){
      this.loggingIn = !this.loggingIn
    }
  }
}
</script>
<style scoped>
#GateAuth{
  width: 100%;
  height: 100vh;
  position:relative;
}
.beta-tag{
  width: 20%;
  margin: 0 auto;
  text-align: center;
  background-color: #f3e49e;
  font-weight: bold;
  border-radius: 45px;
  padding: .5rem 1.5rem;
}
.login-area{
  background-color: #fff;
  width: 400px;
  height: 100vh;
  padding:1rem 2rem;
  position: fixed;
  left:0;
  z-index: 2;
  font-size: 1.3rem;
  transition: 1s;
}
.login-form{
  margin-top: 2rem
}
.login-input{
  border-radius: 10px;
  padding: .6rem 2rem;
  width: 60%;
  font-size: 1rem;
  margin: .5rem 0;
  transition: 1s;
}
.login-btn{
  background-color: var(--primary);
  text-align:center;
  border: none;
  border-radius: 10px;
  font-size: 1.3rem;
  padding: 1rem 2rem;
  color: #fff;
  width: 60%;
  margin: 2rem auto;
  cursor: pointer;
  transition: 1s;
}

.signup-link{
  cursor: pointer;
  color: var(--primary);
}
.flat-mountains{
  width: 100%;
  height: 100vh;
  overflow:hidden;
  background-attachment: fixed;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
}
#signup_carousel{
  margin: 2rem auto;
  min-height: 20rem;
}
.example{color: #aaa; font-size: .7rem;}
.brand-area{
  width: 100%;
  text-align:center;
  color: var(--primary);
  cursor: pointer;
}
.logo-brand{
  width: 80%;
  margin: 2rem auto;
  transition:1s;
}
@media only screen and (max-width: 600px) {
  #GateAuth{
    text-align: center;
  }
  .logo-brand{
    width: 80%;
    margin: 1rem auto;
  }
  .login-area{
    width: 100%;
    padding:1rem 1rem;
  }
}
</style>
