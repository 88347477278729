<template>
  <div id="Hero">
    <div
      class="hero-bg"
      :style="{'background-image': 'url(' + 'https://images.unsplash.com/photo-1565402170291-8491f14678db?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1634&q=80' + ')'}">
      <div class="overlay"></div>
      <section class="overlay-content">
        <h1>Welcome to Skip Source Help Center</h1>

        <div class="pt-2 relative mx-auto text-gray-600">
        <input class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm w-1/2 focus:outline-none"
          type="search" name="search" placeholder="Search">
        <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
          <svg class="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
            viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
            width="512px" height="512px">
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
        </button>
      </div>

      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  data(){
    return{
      search_query: ''
    }
  },
  methods:{
    searchQuery(){
      const query = this.searchQuery
      console.log(query)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#Hero{

}
.hero-bg{
  position:relative;
  width: 100vw;
  min-height: 50vh;
  overflow:hidden;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
}
.overlay{
  width:100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  z-index: 2;
  opacity: .34;
}
.overlay-content{
  position:relative;
  width:100%;
  height: 100%;
  z-index: 3;
}
.overlay-content h1{
  color: #fff;
  font-size: 2rem;
}
.input-search-group{
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 0 .5rem;
  border: 2px solid #707070;
  background-color: #fff;
  border-radius: 10px;
}
.input-search-group input{
  width: 90%;
  font-size: 1.2rem;
  border:none;
  outline: none;
}
.input-search-group button{
  /* width: 1rem; */
  display: flex;
  cursor:pointer;
  align-items:center;
  justify-content:center;
  width: 10%;
  background-color: transparent;
  border: none;
  outline: none;
}
.input-search-group .btn-icon{
  width: 40%;
  min-width: 2rem;
  padding: 1rem;
}
</style>
