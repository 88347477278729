<template>
  <div id="LandFooter">
    <div class="footer-link-area">
    <div class="left-side">
      <router-link :to="{name: 'Land'}"><img src="@/assets/logo2-fff.png" class="brand-logo"/></router-link>
      <div class="text-xs text-white  mx-auto w-8/12 my-3">
        <p>800 Battery Avenue SE, Suite 100 Atlanta, GA 30309</p>
        <p class="text-white m-5"> contact support: theskipsource@gmail.com
      </p>
      <p>phone: 404-236-9077</p>
      <p>Refund policy: No refunds, all sales are final.</p>
      </div>
    </div>
  
    <div class="right-side">
      <div class="footer-links">
        <h2>Product</h2>
        <ul class="links">
          <li><a href="/how-it-works">How It Works</a></li>
          <li><a href="/signup">Submit A List</a></li>
          <li><a href="/more-products/preprocessed-lists">Get a Skip Sourced List</a></li>
          <li><a href="/more-products">Other Products</a></li>
        </ul>
      </div>
      <div class="footer-links">
        <h2>Company</h2>
        <ul class="links">
          <li><a href="/about-us">About Us</a></li>
          <li><a href="/privacy">Privacy</a></li>
          <li><a href="/disclaimer">Disclaimer</a></li>
          <li><a href="/return-policy">Return Policy</a></li>
        </ul>
      </div>
      <div class="footer-links">
        <h2>Resources</h2>
        <ul class="links">
          <li><a href="/faq">FAQ</a></li>
          <li><a href="/contact">Contact</a></li>
          <li><a href="/support">Support</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-legal-info">
    <p>
      Copyright © Skip-Trace 2020-2023
    </p>
  </div>
  <div class="social-group">
    <a href="https://www.facebook.com/Skip-Source-106738824616594" target="_blank">
      <img src="@/assets/icons/social-media/facebook.svg" class="social-icon"/>
    </a>
      <a href="https://www.instagram.com/theskipsource" target="_blank">
        <img src="@/assets/icons/social-media/instagram.svg" class="social-icon"/>
      </a>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'LandFooter',
  components: {
  },
  data(){
    return{
    }
  },
  methods:{

  }
}
</script>
<style scoped>
#LandFooter{
  background-color: #555;
  position: relative;
  width: 100%;
  padding-top:1rem;
  padding-bottom: 6rem;
}
.footer-link-area{
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem auto;
}
.footer-legal-info{
  font-size: .5rem;
  color: #aaa;
  width: 85%;
  margin: auto;
  transition: 1s;
}
a{
  text-decoration: none;
}
.left-side{
  display: flex;
  justify-content: space-between;
  align-items:center;
  flex-direction: column;
  color: #fff;
}
.right-side{
  display:flex;
  justify-content: space-evenly;
  align-items: center;
}
.right-side h2{
  color: #aaa;
  font-size: 1.4rem;
}
.brand-logo{
  width: 18rem;
  margin-bottom: 2rem;
}
.footer-links{
  min-height: 10rem;
  height: 100%;
  diplay: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 1rem;
  padding: 0;
}
.links {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  transition: 1s;
}
li a {
  display: block;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: .6rem;
  text-decoration: none;
}

li a:hover {
  background-color: #111111;
}
.social-group{
  bottom: 3rem;
  right: 3rem;
  position: absolute;
}
.social-icon{
  width: 2rem;
  margin: 1rem;
}
@media only screen and (max-width: 600px) {
  .footer-link-area{
    width: 100%;
  }
  .left-side{
    margin: none;
    width: 100%;
    flex-direction: column;
    justify-content: space-between ;
    color: #fff;
  }
  .brand-logo{
    width: 15rem;
    margin-bottom: 2rem;
  }
  .address{
    margin-left: 1rem;
    font-size: .9rem;
  }
  .right-side{
    font-size: .8rem;
    display:flex;
    justify-content: space-evenly;
    align-items:center;
  }
  .right-side h2{
    color: #aaa;
    font-size: 1rem;
  }
  .footer-link-area{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin: 4rem auto;
  }
  .footer-legal-info{
    text-align: center;
  }
}
</style>
