<template>
<div id="ResetPassword">
  <center><h2>Reset Password</h2></center>
  <div class="contact-area">
    <p>Reset your passwords.</p>
    <p class="feedback" v-if="status == 'success'">{{feedback}}</p>
    <div class="contact-field" v-if="!processing">
      <!-- <input  class="default-input" v-model="email"  placeholder="Enter Your Email" /> <br /> -->
      <label>Enter New Password</label>
      <input type="password" v-model="password" placeholder="New Password" />
      <label>Confirm New Password</label>
      <input type="password" v-model="password2" placeholder="Confirm Password" />
      <button class="primary-btn" @click="updatePassword()" v-if="status != 'success'">Update Password</button>
    </div>

    <div class="processing" v-else-if="processing">
      <vue-spinner :processing="processing" color="#64B6DD" size="100px"></vue-spinner>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios'
import {mapState, mapActions} from 'vuex'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default{
  props:['isMobile'],
  components:{
    'vue-spinner': GridLoader,
  },
  name: 'ResetPassword',
data(){
  return{
    reset_token: this.$route.params.reset_token,
    user_id: '',
    password:'',
    password2: '',
    feedback: '',
    processing: false,
    status: '',
  }
},
methods:{
  updatePassword(){
    this.feedback = ''
    this.processing = true
    let payload ={
      user_id: this.user_id,
      password:this.password
    }
    axios.post(this.$api_path+`skip_source/update_password/`, payload)
    .then((res)=>{
      this.feedback = res.data.feedback
      this.status = res.data.status
      console.log(res.data)
      setTimeout(() => {
        this.processing = false
        this.$router.push({name: 'Land'})
        location.reload()
      }, 3000);
      this.processing = false
    }).catch((err)=>{
      this.feedback = 'Sorry we are experiencing difficulties and we could not send your email. If this error persists please contact support.'
      this.processing = false
      this.status = 'error'
      console.log(err)
    })
  },
  resetPassword(){
    this.feedback = ''
    this.processing = true
    axios.get(this.$api_path+`skip_source/reset_password/${this.reset_token}`)
    .then((res)=>{
      this.feedback = res.data.feedback
      this.status = res.data.status
      this.user_id = res.data.info
      console.log(res.data)
      this.processing = false
    }).catch((err)=>{
      this.feedback = 'Sorry we are experiencing difficulties and we could not send your email. If this error persists please contact support.'
      this.processing = false
      this.status = 'error'
      console.log(err)
    })
  }
},
created(){
  this.resetPassword()
}
}
</script>
<style scoped>
#ResetPassword h1{
  text-transform: uppercase;
  font-size: 1rem;
}
.menu-tab{
  background-color: var(--bg-secondary);
  color: var(--link-light);
  text-transform: uppercase;
}
.menu-tab ul{
  display: flex;
  list-style-type: none;
}
.menu-tab ul li{
  /* margin: 1rem; */
  padding:1rem;
  transition: 1s;
}
.menu-tab ul li:hover{
  cursor: pointer;
  color: #fff;
  background-color: var(--link-light);
}
.back-btn{
  background-color: transparent;
  border:none;
  border-radius: 10px;
  width: 2rem;
  margin: .5rem 1rem ;
}
.back-btn img{
  width: 100%;
}
.headerTwo{
  display: flex;
  position: relative;
  width: 100%;
  height: 3rem;
  align-items: center;
  border-bottom: 2px solid #aaa;
}
.setting-icon{
  position: absolute;
  right: 2rem;
  top: .5rem;
  width: 2rem;
}
.contact-area{
  width: 70%;
  margin: 3rem auto;
}
.contact-field{
  margin: 2rem auto;
}
.contact-field input{
  border:none;
  border-bottom: 1px solid var(--primary);
  padding: .4rem 1rem;
  width: 100%;
  margin: .6rem 0;
  transition: 1s;
  font-size: 1rem;
}
.contact-field textarea{
  width: 100%;
  height: 8rem;
  padding: .4rem 1rem;
  margin: .6rem 0;
  border:none;
  border-bottom: 1px solid var(--primary);
  resize: none;
  font-size: 1.3rem;
}
.feedback{color: var(--success); margin: 1rem auto;}
.danger{color: var(--danger);}
@media only screen and (max-width: 600px) {
}
@media only screen and (min-width: 600px) {

}
</style>
