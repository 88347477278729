<template>
  <div id="SupportRVMTab">
    <div class="dashboard-header">
      <h2>RVM & Text Blast Support</h2>
    </div>
    <div class="search-table">
      <div class="input-group">
        <input placeholder="What would you like assitance with?" v-model="support_query" />
        <button class="trace-btn" @click="searchData()">Search</button>
      </div>
    </div>

    <div class="btn-group">
      <button class="trace-btn" @click="changeSupportCategory('video')" :class="{'selected-category': current_support_category == 'video'}">Video</button>
      <button class="trace-btn" @click="changeSupportCategory('doc')" :class="{'selected-category': current_support_category == 'doc'}">Document</button>
    </div>

    <div v-if="current_support_category == 'video'">
      <p class="alert">The password is <strong>"play"</strong> all lowercase for all the videos. It also says it in the description.</p>
      <div v-if="filteredVideoResutls.length == 0">
        There is no information tailored to your search. You may need to file a support ticket for us to help you with your concerns!
      </div>
      <div v-else class="results">
        <div v-for="(res, idx) in filteredVideoResutls" :key="idx" class="result">
          <div class="result-brief" @click="toggleSupportDropdown(idx)">
            <a :href="res.link" target="_blank" class="result-brief-text">{{res.title}}</a>
          </div>
        </div>
      </div>
  </div>

  <div v-else-if="current_support_category == 'doc'">
    <p >We are in the process of improving our system but for now the documents must be downloaded.</p>
    <div v-if="filteredDocResutls.length == 0">
      There is no information tailored to your search. You may need to file a support ticket for us to help you with your concerns!
    </div>
    <div v-else class="results">
      <div v-for="(res, idx) in filteredDocResutls" :key="idx" class="result">
        <div class="result-brief" @click="toggleSupportDropdown(idx)">
          <a :href="res.link" target="_blank" class="result-brief-text">{{res.title}}</a>
        </div>
      </div>
    </div>
</div>
  </div>
</template>

<script>
import {rtdb} from '@/_firebase/init';
export default {
  name: 'SupportRVMTab',
  components:{
  },
  data(){
    return{
      support_query:'',
      current_support_category: 'video',
      date_format_text: 'relative',
      video_results: [{title: 'Account is buggy', link: `#`}],
      document_results: [{title: 'Account is buggy', link: `#`}],
      showResDropdown: -1,
    }
  },
  methods:{
    toggleSupportDropdown(idx){
      this.showResDropdown = idx
    },
    changeSupportCategory(category){
      this.current_support_category = category
    },
    getVideoSupportInformation(){
      this.loading = true
      this.video_results = []
      return rtdb.ref(`/support/rvm/categories/video`).on('value', (snapshot)  => { snapshot.forEach((childSnapshot) => {
        // childSnapshot.val()
        this.video_results.push(childSnapshot.val())
        this.loading = false
      });
    })
    },
    getDocSupportInformation(){
      this.loading = true
      this.document_results = []
      return rtdb.ref(`/support/rvm/categories/documents`).on('value', (snapshot)  => { snapshot.forEach((childSnapshot) => {
        // childSnapshot.val()
        this.document_results.push(childSnapshot.val())
        this.loading = false
      });
    })
    },
    searchData(){
      console.log(this.support_query)
    },
    changeDateFormat(){
      if(this.date_format_text == 'relative'){
        this.date_format_text = 'normal'
      }else{
        this.date_format_text = 'relative'
      }
    },
    dateFormat(timestamp){
      if(this.date_format_text == 'normal'){
        return this.$localDate(timestamp)
      }else{
        return this.$relativeDate(timestamp)
      }
    }
  },
  computed:{
    filteredVideoResutls(){
       let parsed_title = this.video_results.filter((x)=>{
           return x.title.includes(this.support_query)
       })
       return parsed_title
    },
    filteredDocResutls(){
       let parsed_title = this.document_results.filter((x)=>{
           return x.title.includes(this.support_query)
       })
       return parsed_title
    },
  },
  created(){
    this.getVideoSupportInformation()
    this.getDocSupportInformation()
    // for(let i = 0; i < 40; i++){
    //   this.results.push({title: 'I can\'t find my payment reciept', content: `<p>Please check the last box in Payments</p>`, last_updated: 1608004436,})
    // }
  }
}
</script>
<style scoped>
#SupportRVMTab{
  padding: 2rem;
  width: 90%;
  min-height: 80vh;
  background-color: #fff;
  border-radius:15px;
}
.alert{
  background: #fff09e;
  color: #926c01;
  font-size: 1.2rem;
  border: 2px solid #926c01;
  border-radius: 10px;
  padding: 1rem .2rem;
  padding-left: 1rem;
  margin: 1rem 0;
}
.search-table{
  display: flex;
  width: 100%;
  max-height: 3rem;
  align-items: flex-end;
  justify-content: flex-end;
}
.input-group {
  display: flex;
  width: 70%;
  justify-content: flex-end;
  align-items:center;
}
.input-group input{
  transition: 1s;
  width: 70%;
  padding: .5rem 1rem;
  border: 1px solid #aaa;
  border-radius: 5px;
}
.trace-btn{
  padding: .5rem 1.3rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 5px;
  cursor:pointer;
}
.selected-category{
  color:#fff;
  background-color: #00AEEF;
  border: none;
}
.btn-group{
  display: flex;

}
/* RESULT AREA */
.results{
  max-height: 50vh;
  overflow-y: auto;
  border: 2px solid #eee;
}
.result{
  width: 100%;
  margin:0;
  cursor:pointer;
  position: relative;
}
.result-brief{
  padding: 0;
  width: 100%;
  border-bottom: 2px solid #eee;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  height: 3rem;
  z-index: 2;
}
.result-brief-text{
    color:#00AEEF;
  margin-left: 1rem;
}
.status-icon{
  font-size: 1.2rem;
  font-weight: bold;
}
.result-dropdown{
  padding: 2rem 1rem;
  background-color: #f3f3f3;
  position: relative;
}
.time-indicator{
  position: absolute;
  right: 1rem;
  bottom: .9rem;
  cursor:pointer;
}
/* RESULT AREA */
</style>
