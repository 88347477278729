<template>
<div id="MinutesOrder">
  <div class="presentation">
    <div class="skip-source-phone">
      <img src="@/assets/media/skip-source-phone2.png" />
    </div>
  </div>
  <div class="container">
    <div class="checkout-box">
        <h2>Thank You! You payment has been made!</h2>
        <p class="ordered-text">You will be emailed within 24 to 48 hours your login and password information for our Text Blast and RVM portal.</p>

        <div class="discount-box">
          <a href="https://www.yellowletterhq.com/product/03-list-and-leads-membership/"
          target="_blank"><h3>List pulling discount at: 0.03 per record </h3></a>
          <a href="https://www.reiprintmail.com/launch/skipsource" target="_blank"><h3>Direct Mail Discount 10% off of marketing</h3></a>
        </div>

        <div class="support-section">
          <div class="support-section-text">
            <h3>Take a tutorial on the Software!</h3>
            <a href="/support" class="primary-btn">Take Tutorial</a>
          </div>
          <div class="support-section-media">
            <img src="@/assets/illustrations/tutorial.svg">
          </div>
        </div>
        <br /><br /><br />
        <a class="rvm-btn" href="https://rvmtxtsoftware.skipsourcedata.com" target="_blank">GO TO TEXT & RVM PORTAL</a>
    </div>

    <div class="training">
      <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/ek1zbzbq5-M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    </div>
  </div>
  <div class="pay-warning">
    <h3>Secure Payment - All Sales Are Final. Please email support@skipsourcedata.com with any questions prior to ordering.</h3>
    <p>All orders are through a very secure network. Your credit card information is never stored in any way. We respect your privacy.</p>
  </div>
</div>
</template>

<script>
// import {mapState, mapMutation} from 'vuex'
// import CardCheckout from '@/components/minutessell/CardCheckout.vue'
export default {
  name: 'MinutesHello',
  components: {
    // CardCheckout
  },
  data(){
    return{
      hasPaid: true,
      minutepackages:[
        {price: 400, minute_count: 10000},
        {price: 750, minute_count: 25000},
        {price: 1000, minute_count: 50000},
        {price: 1700, minute_count: 100000},
      ],
      selected_package: {price: null}
    }
  },
  methods:{
    saleMade(val){
      this.hasPaid = val
    },
    selectPackage(val){
      this.selected_package = val
    }
  }
}
</script>
<style scoped>
#MinutesOrder{
  text-align:center;
  position: relative;
  margin: 0 auto;
}
.presentation{
  width: 100%;
  background-color: #444;
  display: flex;
  justify-content: center;
  align-items:center;
  border-bottom: 3px solid var(--primary);
}
.skip-source-phone{
  background-color: #fff;
  width: 20%;
  margin: 1rem auto;
  padding: 2rem 2rem;
}
.skip-source-phone img{
  width:100%;
}
.title{
  color: var(--primary);
  font-size: 2.4rem;
  margin: 1rem;
}
.container{
  max-width: 900px;
  margin: 0 auto;
}
.support-section{
  width: 90%;
  margin: 1rem auto;
  display: flex;
}
.support-section-text{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.support-section-media img{
  width: 90%;
  max-width: 25rem;
  margin: 0 auto;
}
.checkout-box{
  border: 4px dashed  #dedede;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  min-height: 30vh;
  border-radius: 10px;
  margin: 6rem auto;
  padding: 4rem 0;
}
.ordered-text{
  width:80%;
  margin: 0 auto;
  margin-top: 2rem;
}
.discount-box{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
}
.discount-box a{
  text-decoration: none;
  font-size: 1.1rem;
  margin: 1rem auto;
}
.package-table{
  width: 80%;
  margin: auto;
}
.package-table tr td{
  padding: .5rem 0;
  transition: 1s;
}
.package-table tr .left-side{
  width: 80%;
  display: flex;
  align-items:center;
  text-align: left;
}
.package-table tr .right-side{
  text-align: right;
  width: 20%;
}
.package-table .head-row{
  border-bottom: 1px solid #ddd;
}
.select-price{
  width: 1.7rem;
  height: 1.7rem;
  transition: 1s;
  border-radius: 5px;
  margin-right: 1rem;
  border: 2px solid #555;
}
.selected{
  border:none;
  width: 1.8rem;
  height: 1.8rem;
  background-color: #0066ff;
}
.input-group{
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem auto;
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: .5rem 1.2rem;
}
.checkout-inputs label{font-size: 15px; text-align: left; width: 15rem;}
.form-input{
  padding: .5rem 1.2rem;
  border: none;
  width: 100%;
  text-align: right;
  transition: 1s;
}
.form-input:focus{
    outline: none;
}
.pay-warning{
  background-color: #ddd;
  padding: 4rem;
  text-align: left;
  width: 100%;
  margin: 0 auto;
}
.rvm-btn{
  text-decoration: none;
  border-radius: 10px;
  margin-top:3rem;
  cursor: pointer;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 1rem 2rem;
  color: #fff;
}
@media screen and (max-width: 900px) {
  .support-section{
    flex-direction: column;
  }
}

@media screen and (max-width: 390px) {
  .checkout-inputs label{font-size: 8px;}
  .input-group{padding: .5rem .2rem;  }
  .form-input{
    padding: .5rem 1rem;
    border-radius: 8px;
  }
  .rvm-btn{
    margin-top: 1rem;
  }
  .pay-warning{
    padding: 2rem;
    font-size: .8rem;
    width: 80%;
    margin: 0 auto;
  }
  .test{
    font-size: 7px;
  }
  .price-text{
    float: right;
    font-size: 1.1rem;
  }
}
</style>
