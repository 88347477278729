<template>
  <div id="NavBar">
    <div class="left-side">
      <router-link :to="{ name: 'Land' }"
        ><img src="@/assets/logo2.png" class="brand-logo"
      /></router-link>
      <ul class="links non-mobile">
        <li v-for="(nm, idx) in nav_menu" :key="idx">
          <router-link :to="{ name: nm.route }">{{ nm.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="right-side">
      <div class="app-access non-mobile">
        <span class="right-side-btn-group" v-if="!isAuthenthicated">
          <!-- <router-link :to="{ name: 'Login' }" class="primary-btn2 btn"
            ><div class="gradient-text">LOGIN1</div></router-link
          > -->
          <!-- <router-link class="primary-btn btn" :to="{ name: 'Signup' }"
            >GET STARTED</router-link
          > -->
          <a href="https://elite.skipsourcedata.com" class="primary-btn btn">
            GET STARTED</a
          >
        </span>
        <span v-else-if="isAuthenthicated">
          <router-link :to="{ name: 'Overview' }" class="primary-btn2"
            ><div class="gradient-text">APP</div></router-link
          >
        </span>
      </div>

      <button class="hamburger-menu" @click="displaySideMenu(true)">
        <img src="@/assets/icons/menu.svg" />
      </button>
    </div>

    <div
      class="overlay-header"
      v-if="showSideMenu"
      :class="{ 'overlay-header-show': showSideMenu }"
      @click="displaySideMenu(false)"
    ></div>
    <div class="dropdownMenu" :class="{ 'dropdownMenu-open': showSideMenu }">
      <ul class="links">
        <button class="close-btn" @click="displaySideMenu(false)">
          <img src="@/assets/icons/close.svg" />
        </button>
        <li
          v-for="(nm, idx) in nav_menu"
          :key="idx"
          @click="gotoRoute(nm.route)"
        >
          {{ nm.name }}
        </li>
      </ul>
      <div class="app-access">
        <span class="right-side-btn-group" v-if="!isAuthenthicated">
          <router-link :to="{ name: 'Login' }" class="primary-btn2 btn"
            ><div class="gradient-text">LOGIN</div></router-link
          >
          <router-link class="primary-btn btn" :to="{ name: 'Signup' }"
            >GET STARTED</router-link
          >
        </span>
        <span v-else-if="isAuthenthicated">
          <router-link :to="{ name: 'Overview' }" class="primary-btn2"
            ><div class="gradient-text">APP</div></router-link
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      showSideMenu: false,
      nav_menu: [
        { name: "How it Works", route: "HowItWorks" },
        { name: "More Products", route: "MoreProducts" },
        { name: "Free Resources", route: "FreeResources" },
        { name: "FAQ", route: "FAQ" },
        { name: "Contact", route: "Contact" },
      ],
    };
  },
  computed: {
    ...mapState(["userID"]),
    ...mapGetters(["isAuthenthicated"]),
  },
  methods: {
    gotoRoute(route) {
      this.$router.push({ name: route });
      this.showSideMenu = false;
    },
    displaySideMenu(bool) {
      this.showSideMenu = bool;
    },
  },
};
</script>
<style scoped>
#NavBar {
  width: 95%;
  height: 6rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 1s;
}
a {
  text-decoration: none;
}
.left-side {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.brand-logo {
  width: 12rem;
}
.links {
  font-weight: bold;
  list-style-type: none;
  margin: 0 2rem;
  padding: 0;
  overflow: hidden;
  transition: 1s;
}
.right-side {
}
.right-side-btn-group {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.primary-btn2 {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f1;
  padding: 1rem 2rem;
  height: 100%;
  margin: auto 0.1rem;
  transition: 1s;
  border-radius: 10px;
}
.primary-btn2:hover {
  transform: translateY(-10px);
}
.gradient-text {
  background: rgb(70, 72, 255);
  background: -moz-radial-gradient(
    circle,
    rgba(70, 72, 255, 1) 3%,
    rgba(61, 36, 255, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(70, 72, 255, 1) 3%,
    rgba(61, 36, 255, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(70, 72, 255, 1) 3%,
    rgba(61, 36, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4648ff",endColorstr="#3d24ff",GradientType=1);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
li {
  float: left;
}

li a {
  display: block;
  color: #333333;
  text-align: center;
  padding: 16px;
  transition: 1s;
  text-decoration: none;
}

li a:hover {
  background-color: var(--primary);
}
/* SIDEBAR COMPONENTS */
@media only screen and (max-width: 900px) {
  .hamburger-menu {
    display: block;
    width: 3rem;
    background-color: transparent;
    border: none;
  }
  .hamburger-menu img {
    width: 100%;
  }
  .non-mobile {
    display: none;
  }
  /* SIDEBAR COMPONENT */
  .dropdownMenu {
    display: none;
    position: absolute;
    background-color: #fff;
    text-align: right;
    margin: 0;
    height: auto;
    top: 0;
    right: -110%;
    width: 25rem;
    height: 100vh;
    transition: 1s;
    overflow: hidden;
    z-index: 10 !important;
  }
  .dropdownMenu-open {
    display: block;
    right: -4%;
  }
  .overlay-header {
    display: none;
    opacity: 1;
    transition: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background-color: #000;
  }
  .overlay-header-show {
    display: block;
    opacity: 0.3;
  }
  .dropdownMenu ul {
    list-style-type: none;
    text-align: right;
    border-bottom: 1px solid #444;
    margin: 2rem;
  }
  .dropdownMenu ul li {
    font-size: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.3rem 0;
    font-weight: bold;
    cursor: pointer;
    padding: 16px;
    transition: 1s;
    text-decoration: none;
  }
  .back-btn-icon {
    height: 1rem;
    margin-top: 1rem;
    margin-bottom: -1rem;
  }
  .dropdownMenu ul li:hover {
    cursor: pointer;
    background-color: #ddd;
  }
  .close-btn {
    /* position: absolute; */
    margin-left: -1rem;
    width: 2rem;
    border: none;
    background-color: transparent;
    height: 2rem;
    /* z-index: 11 !important; */
  }
}
@media only screen and (min-width: 900px) {
  .hamburger-menu {
    display: none;
  }
  .dropdownMenu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  li a {
    padding: 5px;
  }
  li {
    font-size: 0.2rem;
  }
  .btn {
    display: flex;
    justify-content: center;
    font-size: 0.5em;
    padding: 0.7rem 1rem;
    margin: 0.3rem;
    text-align: center;
    width: 4rem;
  }
  .right-side-btn-group {
  }
}
@media only screen and (max-width: 400px) {
  li {
    font-size: 0.15rem;
  }
}
</style>
