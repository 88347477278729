<template>
  <div id="EditAccount">
      <div class="account-area account-security">
        <div class="input-group">
          <p>Username</p>
          <input v-model="userdata.username" placeholder="Username"/>
        </div>
        <div class="input-group">
          <p>New Password</p>
          <input placeholder="Password"/>
        </div>
        <div class="input-group">
          <p>Confirm New Password</p>
          <input placeholder="Password"/>
        </div>
      </div>

      <div class="account-area account-settings">
        <div class="input-group">
          <p>First Name</p>
          <input v-model="userdata.fname" placeholder="First Name"/>
        </div>
        <div class="input-group">
          <p>Last Name</p>
          <input v-model="userdata.lname" placeholder="Last Name"/>
        </div>
        <div class="input-group">
          <p>Email</p>
          <input v-model="userdata.email" placeholder="Email"/>
        </div>
        <div class="input-group">
          <p>Phone Number</p>
          <input  v-model="userdata.phone" placeholder="Phone Number"/>
        </div>
        <div class="input-group">
          <p>Profile Image</p>
          <input type="file"/>
        </div>
      </div>

      <div class="account-area address-settings">
        <div class="input-group long">
          <p>Address</p>
          <input placeholder="Address"/>
        </div>
        <div class="input-group">
          <p>City</p>
          <input placeholder="City"/>
        </div>
        <div class="input-group">
          <p>State</p>
          <input placeholder="State"/>
        </div>
        <div class="input-group">
          <p>Zip</p>
          <input placeholder="Zip"/>
        </div>
      </div>
  </div>
</template>

<script>


export default {
  name: 'EditAccount',
  props:['userdata'],
  data(){
    return{
    }
  }
}
</script>
<style scoped>
.input-group{
  display: flex;
  flex-direction: column;
}
.input-group input{
  padding: .2rem 1rem;
  transition: 1s;
  border-radius: 10px;
  font-size: 1.1rem;
  border: 1px solid #ccc;
  margin: .5rem 0;
}
.trace-btn{
  padding: .5rem 2rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 20px;
  cursor:pointer;
}
.account-area{
  display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-template-rows: 1fr;
   grid-column-gap: 20px;
   grid-row-gap: 20px;
   justify-items: stretch;
   align-items: stretch;
  border-radius:10px;
  padding: 2rem;
  margin:2rem 0;
}
</style>
