<template>
  <div id="SkipTrace">
    <div class="dashboard-header">
      <h1>Skip Trace</h1>
      <p class="head-text">Current Balance: ${{(userstats.cash_balance/100).toFixed(2)}}</p>
    </div>
<!--
    <div class="btn-group">
      <button class="trace-btn selected">Bulk SkipTrace</button>
    </div> -->
    <br />
    <p class="file-warning">Files must be in .csv format</p>
    <p class="file-warning">If you need to get access to a template CSV File, you may do so by clicking on one of the icons below.</p>

    <div class="template-files-group">
      <h3>INDIVIDUAL TEMPLATE</h3>
      <div class="template-file-section">
        <a href="https://docs.google.com/spreadsheets/d/14l2mtUQN4eiLBHx46o7Ux1cZnU8qlWd6I3zhA3p4jvQ/edit?usp=sharing" target="_blank">
          <img src="@/assets/icons/skip-trace/google-sheets.svg" />
          <p><strong>Google Sheet</strong></p>
        </a>
      </div>
      <p>or</p>
      <div class="template-file-section">
        <a href="https://firebasestorage.googleapis.com/v0/b/skip-source.appspot.com/o/site_assets%2FCSV%20Template.csv?alt=media&token=f9dad6bb-bb4e-46c1-8e1b-78b95a768d91" target="_blank">
          <img src="@/assets/icons/skip-trace/csv.svg" />
          <p><strong>CSV File</strong></p>
        </a>
      </div>
    </div>

    <div class="template-files-group" v-if="role == 'elite'">
      <h3>LLC TEMPLATE</h3>
      <div class="template-file-section">
        <a href="https://firebasestorage.googleapis.com/v0/b/skip-source.appspot.com/o/site_assets%2FCSV%20Template.csv?alt=media&token=f9dad6bb-bb4e-46c1-8e1b-78b95a768d91" target="_blank">
          <img src="@/assets/icons/skip-trace/csv.svg" />
          <p><strong>CSV File</strong></p>
        </a>
      </div>
    </div>

    <section class="realtime-tracing">
    <h2>Realtime Tracing</h2>
    <!-- {{userstats.cash_balance}} -->
    <div class="skip-actions" v-if="userstats.cash_balance > 0">
      <input class="trace-btn "  type = "file" @change = "readFile" />
      <button class="trace-btn "  @click="processAllCSVs()">Process All Data</button>
    </div>
    <div class="skip-actions alert-no-skip" v-else>
      <p>In order for you to use our skip tracing software, you need to deposit some funds into your account. <router-link :to="{name: 'Payments'}">Add Funds</router-link></p>
    </div>

    <div v-if="userstats.cash_balance > 0">
      <p class="important-notice">NOTE: As soon as you press the "Process All Data" or the "Process" buttons, you if there is a successful data response, funds would be deducted out of your account.</p>
      <p>Uploaded records: {{availability.length}}</p>
      <p>Total records skipped: {{processed_data.length}}</p>
      <p>Total amount charged to account: ${{(amountCharged/100)}}</p>
      <CSVPreview
      :availability="availability"
      :allCSVProcessing="allCSVProcessing"
      @processedSingle="processedSingle"/>
      <div class="" v-if="allCSVProcessing">
        Please wait while data is uploading
      </div>
      <CSVProcessed v-if="hasProcessed" :processed_data="processed_data"/>
    </div>
  </section>
  </div>
</template>

<script>
import axios from "axios";
import {mapState} from 'vuex'
import {fb, db} from '@/_firebase/init'
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import CSVPreview from '@/views/dashboard/skiptrace/CSVPreview.vue'
import CSVProcessed from '@/views/dashboard/skiptrace/CSVProcessed.vue'

export default {
  name: 'SkipTrace',
  components:{
    // 'vue-spinner': ClipLoader,
    CSVPreview,
    CSVProcessed
  },
  data(){
    return{
      search_query: '',
      file_headers:['#', 'File', 'Total Records', 'Successful Records', 'Failed Records', 'Status', 'Estimated Price', 'Deducted Price', 'Order Stauts', 'Release Note', 'Output File'],
      online_sourced:['#','#', '#','#'],
      file_name: '',
      uploading: false,
      processing: false,
      allCSVProcessing: false,
      feedback:'',
      status: '',
      role: '',
      file_links: [],
      url: null,
      testingNewFeature: false,
      hasProcessed: false,
      availability: [],
      processed_data: [],
      userstats: {},
    }
  },
  methods:{
    async getData(userid){
      const userstatsRef = db.collection('user_stats').doc(userid)
      const doc = await userstatsRef.get();
        if (!doc.exists) {
        console.log('No such document!');
        } else {
          this.userstats = doc.data()
            console.log(this.userstats)
        }

      const fileLinkRef = userstatsRef.collection('file_links')
      const snapshot = await fileLinkRef.get();
      if (snapshot.empty) {
        // console.log('No matching documents.');
          this.userExist = false
        return;
      }
        snapshot.forEach(doc => {
          this.file_links.push(doc.data())
        });
        this.userExist = true
    },
    async getUserData(){
      const usersRef = db.collection('users').doc(this.userID);
      const doc = await usersRef.get();
      if (!doc.exists) {
      console.log('No such document!');
      } else {
        this.role = doc.data().role
      }
    },
    processedSingle(isProcessed, data){
      this.hasProcessed = isProcessed
      this.processed_data.unshift(data)
      this.chargeUser(this.pricePerSkip, 1)
    },
    processAllCSVs(){
      this.allCSVProcessing = true
      const doc_data = this.availability
      console.log(doc_data)
      let payload = {
        docs: doc_data
      }
      axios.post(this.$api_path+'skip_source/realtime-search/all',payload)
      .then((res)=>{
        console.log(res.data)
          this.processed_data = res.data.response
          this.allCSVProcessing = false
          this.hasProcessed = true
          this.chargeUser(this.amountCharged, this.processed_data.length)
      }).catch((err)=>{
        console.log(err)
        this.allCSVProcessing = false
      })
    },
    submitFile(){
      this.status = ''
      this.uploading = true
      let payload ={
        file_link: this.file_name,
        userID: this.userID
      }
      axios.post(this.$api_path+'skip_source/new_file_uploaded', payload)
      .then((res)=>{
          this.status = 'success'
        this.feedback = res.data.feedback
        this.uploading = false
      }).catch((err)=>{
        this.status = 'error'
        this.feedback = 'Could not send email'
        this.uploading = false
        console.log(err)
      })
    },
    uploadFile(evt){
      const now = Date.now(); // Unix timestamp in milliseconds
      const epoch = Math.round(now/1000)
      let file = evt.target.files[0];
      this.uploading = true
      console.log(file)
      let metadata = {contentType: 'application/octet-stream'};
      var storeRef = fb.storage().ref(`source_files/${this.userID}/${epoch}-`+file.name);
      let uploadTask = storeRef.put(file, metadata);
      uploadTask.on('state_changed', (snapshot) =>{
      },(err) => {
        this.status = 'error'
        console.log(err);
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.file_name = downloadURL;
          this.uploading = false
          this.feedback = 'File Uploaded! Press Submit File'
          this.status = 'success'
        });
      })
    },
    onComplete(results, file){
      // Your logic here! functions or axios calls
      this.updateFileLoaded()
      console.log(results)
        for(let doc of results['data']){
          let data_set = {
            'fname': doc['First Name'],
            'lname': doc['Last Name'],
            'address': doc['Property Address'],
            'city': doc['City'],
            'state': doc['State'],
            'zip': doc['Zip'],
            'mail_address': doc['Mailing Address'],
            'mail_city': doc['Mailing City'],
            'mail_state': doc['Mailing State'],
            'mail_zip': doc['Mailing Zip']
          }
          this.availability.push(data_set)
      }
      console.log(this.availability);
    },
    readFile() {
        var file = event.target.files[0];
        this.$papa.parse(file, {
            header: true,
            complete: this.onComplete // your vue methods
        });
    },
    async chargeUser(amount, count){
      this.userstats.cash_balance = this.userstats.cash_balance - amount
      this.userstats.records_skipped = this.userstats.records_skipped + count
      const userstatsRef = db.collection('user_stats').doc(this.userID);
      const res = await userstatsRef.set(this.userstats);
    },
    async updateFileLoaded(){
      this.userstats.files_submitted = this.userstats.files_submitted + 1
      const userstatsRef = db.collection('user_stats').doc(this.userID);
      const res = await userstatsRef.set(this.userstats);
    }
    // getLoadedFiles(){
    //   this.files_list = []
    //   let storeRef = fb.storage().ref()
    //   storeRef.child(`source_files/${this.userID}/`).listAll().
    //   then((res) => {
    //     res.prefixes.forEach((folderRef) => {
    //     });
    //     res.items.forEach((itemRef) => {
    //       if( this.files_list.indexOf(itemRef) > -1 ) {
    //           console.log("already exist in list");
    //       }else{
    //         this.skip_sourced_files.push(itemRef)
    //         // console.log(this.skip_sourced_files)
    //       }
    //     });
    //   }).catch((error) => {
    //     console.log(error)
    //   });
    // },
  },
  computed:{
    ...mapState(['userID']),
    amountCharged(){
      let total_amount = 0
      total_amount = this.processed_data.length * this.pricePerSkip
      return total_amount
    },
    pricePerSkip(){
      let price_skip = 15
      // if(this.role == 'elite'){
      //   price_skip = 15
      // }else{
      //   price_skip = 17
      // }
      return price_skip
    }
  },
  created(){
    this.getData(this.userID)
    this.getUserData()
    // this.getLoadedFiles()
  }
}
</script>
<style scoped>
#SkipTrace{
  padding: 2rem;
  width: 90%;
  min-height: 44vh;
  background-color: #fff;
  border-radius:15px;
}
.realtime-tracing{
  margin-top: 3rem;
}
.alert-no-skip{
  background: #fff09e;
  color: #926c01;
  font-size: 1.2rem;
  border: 2px solid #926c01;
  border-radius: 10px;
  padding: 2rem .2rem;
  margin: 1rem 0;
}
.important-notice{
  width: 80%;
  margin: 1rem 0;
  font-weight: bold;
}
.head-text{
  color: var(--link-light);
  font-size: 1rem;
  margin-top: 1rem;
  transition: 1s;
}
.trace-btn{
  padding: .5rem 2rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 20px;
  cursor:pointer;
}
.selected{
  color:#fff;
  background-color: #00AEEF;
}
.file-warning{
  font-size: .8rem;
  color: #aaa;
}
.file-warning a{
  color:#00AEEF;
  text-decoration: none;
}
.file-warning a:hover{
  color:#b3e6ff;
}
.upload-btn-group{
  display: flex;
}
.upload-btn{
  position: relative;
}
.upload-input{
  position: absolute;
  top:0;
  left: 0;
  width:100%;
  height: 8rem ;
  opacity: 0;
}
.table-header-row{
  color:#fff;
  background-color: #00AEEF;
}
.download-column{
  width: 20rem;
}
.download-btn{
  width: 96%;
  height: 1.2rem;
  margin: auto;
  border-radius: 5px;
  font-weight: bold;
  cursor:pointer;
  position: relative;
  border: 1.5px solid #0083b3;
  background-color: #00AEEF;
  text-align: right;
}
.download-btn img{width: 1rem; height: 1rem; position: absolute; left: .2rem; top:.1rem;}
.download-btn a{
  color:#fff;
  margin: .2rem;
  font-size: .7rem;
  text-decoration:none;
}
.table-header-row th{
  padding: 1rem;
}
.filed-table{
  width: 100%;
  max-height: 62vh;
  margin: 1rem auto;
  transition: 1s;
  overflow-x: scroll;
}
.filed-table::-webkit-scrollbar {
  width: 10px;
}

.filed-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #f4f4f4;
}

.filed-table::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.filed-table::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
.no-data{
  text-align:center;
  padding: 1rem 0;
}
.file_docs{
  text-align:center;
}
.template-files-group{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items:center;
}
.template-file-section {
  width: 5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.template-file-section:hover{
  transform: translateY(-10px);
}
.template-file-section a{
  text-decoration: none;
  color: #000;
  font-size: .6rem;
}
.template-file-section img{
  width: 70%;
  margin: .5rem auto;
}
</style>
