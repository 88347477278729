<template>
  <div id="SqueezeContent">
        <router-link :to="{name: 'Land'}"><img src="@/assets/logo2.png" class="brand-logo"/></router-link>
    <div class="container">
        <h1>AMERICA'S TOP RATED
    List & Skip Tracing Service</h1>

    <ul class="offer-list">
      <li> We offer Absentees, Cash Buyers, Liens lists</li>
      <li> We understand objections and how to handle rebuttals</li>
      <li> Skip Tracing has a 80-90% match rate</li>
      <li> With an average accuracy rate 85%</li>
      <li> We invest 40 hours per week into quality assurance</li>
      <li> Lists and Skiptracing is delivered within 24 to 48 hours</li>
    </ul>
    </div>
</div>
</template>

<script>

export default {
  name: 'SqueezeContent',
  components: {

  },
  data(){
    return{
      minutepackages:[
        {price: 400, minute_count: 10000},
        {price: 750, minute_count: 25000},
        {price: 1000, minute_count: 50000},
        {price: 1700, minute_count: 100000},
      ],
      selected_package: {}
    }
  },
  methods:{
    displayMinPerCost(count, cost){
      return ((cost/count)*100).toFixed(1)
    }
  }
}
</script>
<style scoped>
#SqueezeContent{
  position: relative;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  /* max-width: 900px; */
}
.brand-logo{  width: 20rem;}
.container{
  text-align:center;
  width: 90%;
  margin: 4rem auto;
}
.sub-text{
  margin: 2rem;
  font-size: 1.3rem;
}
.offer-list{
  list-style-type:none;
  text-align:left;
  font-size: 1.4rem;
  transition: 1s;
}
.offer-list li{
  margin: 3rem auto;
  transition: 1s;
}
@media screen and (max-width: 600px) {
  #SqueezeContent{
    padding: .5em;
  }
  h1{
    font-size: 1.4rem;
  }
  .offer-list{
    /* margin-left: -2rem; */
    font-size: .8rem;
  }
}
</style>
