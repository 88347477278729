<template>
  <div id="FeaturedIn">
    <h1><strong>SKIP TRACING</strong></h1>
    <img src="@/assets/illustrations/location-tracking.svg" class="map-icon" />
    <p>
      Skip tracing is performed by collecting information on individuals in
      question. All information recovered is analyzed, verified, and used to
      determine the location and contact information of the individuals
      searched.
    </p>
    <br />
    <!-- <router-link class="primary-btn" :to="{name:'Signup'}">START SKIP TRACING</router-link> -->
    <a href="https://elite.skipsourcedata.com" class="primary-btn">
      START SKIP TRACING</a
    >
  </div>
</template>

<script>
export default {
  name: "FeaturedIn",
  components: {},
};
</script>
<style scoped>
#FeaturedIn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem 0;
  font-size: 0.78rem;
  transition: 1s;
}
#FeaturedIn h1 {
  font-size: 3rem;
}
#FeaturedIn p {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 2rem auto;
  text-align: center;
  color: #000;
  width: 70%;
}
.features {
  width: 100%;
  max-width: 6rem;
  min-height: 4rem;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 1s;
}
.primary-btn {
  font-size: 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
}
.map-icon {
  width: 15%;
}
@media only screen and (max-width: 600px) {
  #FeaturedIn h1 {
    font-size: 2rem;
  }
  #FeaturedIn p {
    font-weight: bold;
    font-size: 0.8rem;
  }
}
</style>
