<template>
  <div id="CashbuyerList">
    <div class="m-6" v-if="checkoutLifecycle == 'checkout' ">
      <button
        class="m-10 py-2 px-6 bg-gray-500 text-white rounded-lg"
       @click="setCheckoutLifecycle('')">back</button>
      <CartCard :productdata="product" />
      <CardCheckout
          :total="product.price"
          :productdata="product"
          :userID="userID"
          @saleMade="saleMade"
      />
    </div>
    <ReceiptResponse v-else-if="checkoutLifecycle == 'receipt' "/>
    <ProductOverview
        :productdata="product"
        v-else
        @setCheckoutLifecycle="setCheckoutLifecycle"
        @setStateProvidence="setStateProvidence"/>
  </div>
</template>

<script>
import ProductOverview from '@/components/cashbuyers/ProductOverview.vue'
import CardCheckout from '@/components/cashbuyers/CardCheckout.vue'
import ReceiptResponse from '@/components/cashbuyers/ReceiptResponse.vue'
import CartCard from '@/components/cashbuyers/CartCard.vue'
import {mapState, mapMutations} from 'vuex'


export default {
  name: 'CashbuyerList',
  components: {
    ProductOverview,
    CardCheckout,
    CartCard,
    ReceiptResponse
  },
  data(){
    return{
      checkoutLifecycle: '',
      product: {
        title: 'Buyers List',
        price: 30000,
        state_providence: ''
      }
    }
  },
  methods:{
    ...mapMutations(['SET_BUYERS_LIST']),
    setStateProvidence(st){
      this.product.state_providence = st
    },
    setCheckoutLifecycle(cyc){
      if(cyc == 'checkout'){
        this.SET_BUYERS_LIST(this.product)
      }
      this.checkoutLifecycle = cyc
    },
    saleMade(){
      this.setCheckoutLifecycle('receipt')
    },
  },
  computed:{
    ...mapState(['userID', 'buyers_list_product'])
  },
  created(){
    // if(this.buyers_list_product.title != ''){
    //   this.product = this.buyers_list_product
    // }
  }
}
</script>
<style scoped>
#CashbuyerList{
  position: relative;
  min-height: 75vh;
  margin: 0 auto;
  /* max-width: 900px; */
}
@media screen and (max-width: 900px) {

}
</style>
