<template>
<div id="SqueezeCheckout">
  <div class="container">
    <h1 class="title">Thank You!</h1>
    <h1 class="title2">Your purchase has been made</h1>
    <div class="mail-sent">
      <img src="@/assets/illustrations/mail_sent.svg">
    </div>
    <h3>Please check your email for further instructions. You should expect to recieve your list within 24 - 48 hours.</h3>
  </div>

  <router-link class="primary-btn" :to="{name: 'Land'}"> Return Home</router-link>
</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'SqueezeCheckout',
  components: {
  },
  methods:{
  },
  computed:{
    ...mapState(['squeeze_funnel']),
    formatedFunnelList(){
      let arr = []
      arr[0] = {label: 'First Name', funnel_data: this.squeeze_funnel.fname}
      arr[1] = {label: 'Last Name', funnel_data: this.squeeze_funnel.lname}
      arr[2] = {label: 'Email', funnel_data: this.squeeze_funnel.email}
      arr[3] = {label: 'Phone', funnel_data: this.squeeze_funnel.phone}
      arr[4] = {label: 'City', funnel_data: this.squeeze_funnel.city}
      arr[5] = {label: 'Zip', funnel_data: this.squeeze_funnel.zip}
      arr[7] = {label: 'Propery Value Range', funnel_data: this.squeeze_funnel.value_range}
      arr[8] = {label: 'How many records would you like?', funnel_data: this.squeeze_funnel.records_count}
      arr[9] = {label: 'Notes', funnel_data: this.squeeze_funnel.notes}
      return arr
    }
  }
}
</script>
<style scoped>
#SqueezeCheckout{
  text-align:center;
  position: relative;
  margin: 0 auto;
  width:100%;
  margin-bottom: 3rem;
}
.presentation{
  width: 100%;
  background-color: #444;
  display: flex;
  justify-content: center;
  align-items:center;
  border-bottom: 3px solid var(--primary);
}
.skip-source-phone{
  background-color: #fff;
  width: 20%;
  margin: 1rem auto;
  padding: 2rem 2rem;
}
.skip-source-phone img{
  width:100%;
}
.title{
  color: var(--primary);
  font-size: 2.4rem;
  margin: 1rem;
}
.container{
  padding-top: 10rem;
  min-height: 40vh;
  width: 90%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  jusity-content: flex-end;
  align-items: space-evenly;
}
.mail-sent{
  width: 80%;
  max-width: 30rem;
  margin: 3rem auto;
}
.mail-sent img{
  width: 80%;
}
@media screen and (max-width: 390px) {
}
</style>
