<template>
    <div id="Privacy">
      <h2>Return policy</h2>
      <div class="privacy">
  
        <p>Thanks for your business. No refunds, all sales are final.</p>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'Privacy',
    components: {
    },
    data(){
      return{
      }
    }
  }
  </script>
  
  <style scoped>
  #Privacy{
      min-height: 60vh;
      padding: 3rem;
  }
  #Privacy h2{
  
    font-size: 2rem;
  }
  .faq-list{
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .faq-content{
    transition:1s;
    margin: 2rem;
  }
  .question-text{
    font-weight: bold;
  }
  a{
    color: var(--link-light);
    text-decoration: none;
  }
  @media screen and (max-width: 600px) {
    #Privacy h2{
      font-size: 2rem;
      margin-left: 1rem;
    }
    .faq-list{
      margin-left: .23rem;
      margin-right: .23rem;
    }
  }
  </style>
  