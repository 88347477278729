<template>
<div id="ForgotPassowrd">
  <center><h2>Forgot Password?</h2></center>
  <div class="contact-area">
    <p>Just fill out your email you used to register with the account</p>
    <p class="feedback" :class="{'danger': status != 'success'}">{{feedback}}</p>
    <div class="contact-field" v-if="!processing">
      <input  class="default-input" v-model="email"  placeholder="Enter Your Email" /> <br />
      <button class="primary-btn" @click="sendEmail()">Send</button>
      <router-link :to="{name: 'ResetPassword'}" >Password Reset</router-link>
    </div>

    <div class="processing" v-else-if="processing">
      <vue-spinner :processing="processing" color="#64B6DD" size="100px"></vue-spinner>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default{
  props:['isMobile'],
  components:{
    'vue-spinner': GridLoader,
  },
data(){
  return{
    email: '',
    feedback: '',
    processing: false,
    status: '',
  }
},
methods:{
  returnToLastRoute(){
    this.$router.back()
  },
  sendEmail(){
    this.feedback = ''
    this.processing = true
    let payload ={
      email:this.email
    }
    console.log(payload)
    axios.post(this.$api_path+'skip_source/forgot_password', payload)
    .then((res)=>{
      this.email = ''
      this.feedback = res.data.feedback
      this.status = res.data.status
      console.log(res.data)
      this.processing = false
    }).catch((err)=>{
      this.feedback = 'Sorry we are experiencing difficulties and we could not send your email. If this error persists please contact support.'
      this.processing = false
      this.status = 'error'
      console.log(err)
    })
  }
}
}
</script>
<style scoped>
#ForgotPassowrd h1{
  text-transform: uppercase;
  font-size: 1rem;
}
.menu-tab{
  background-color: var(--bg-secondary);
  color: var(--link-light);
  text-transform: uppercase;
}
.menu-tab ul{
  display: flex;
  list-style-type: none;
}
.menu-tab ul li{
  /* margin: 1rem; */
  padding:1rem;
  transition: 1s;
}
.menu-tab ul li:hover{
  cursor: pointer;
  color: #fff;
  background-color: var(--link-light);
}
.back-btn{
  background-color: transparent;
  border:none;
  border-radius: 10px;
  width: 2rem;
  margin: .5rem 1rem ;
}
.back-btn img{
  width: 100%;
}
.headerTwo{
  display: flex;
  position: relative;
  width: 100%;
  height: 3rem;
  align-items: center;
  border-bottom: 2px solid #aaa;
}
.setting-icon{
  position: absolute;
  right: 2rem;
  top: .5rem;
  width: 2rem;
}
.contact-area{
  width: 70%;
  margin: 3rem auto;
}
.contact-field input{
  border:none;
  border-bottom: 1px solid var(--primary);
  padding: .4rem 1rem;
  width: 100%;
  margin: .6rem 0;
  transition: 1s;
  font-size: 1rem;
}
.contact-field textarea{
  width: 100%;
  height: 8rem;
  padding: .4rem 1rem;
  margin: .6rem 0;
  border:none;
  border-bottom: 1px solid var(--primary);
  resize: none;
  font-size: 1.3rem;
}
.feedback{color: var(--success); margin: 1rem auto;}
.danger{color: var(--danger);}
@media only screen and (max-width: 600px) {
}
@media only screen and (min-width: 600px) {

}
</style>
