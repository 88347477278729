<template>
  <div id="Products">
    <h2 class="text-3xl text-center ">All-In-One Platform for Real Estate Professionals</h2>
    <p class="text-gray-400  text-center w-8/12 mx-auto">
      Finding Real Estate deals can be a challenge. But with BatchLeads, it doesn’t have to be.
      We’ve created a one-stop solution to all your Real Estate needs… so that you can find more SELLERS, close more DEALS and maximize REVENUE!
    </p>
    <div class="flex justify-evenly items-center p-5 w-full">
        <div v-for="(product, idx) in more_products" :key="idx" class="product-box cursor-pointer" @click="setProduct(product)">
          <div class="product-icon" :style="{'background-image': 'url(' + product.icon + ')'}">
          </div>
          <p class="text-lg ">{{product.name}}</p>
          <button v-if="product.tag == 'crm' "> COMING SOON</button>
          <h3 v-if="product.price">${{ (product.price/100).toLocaleString()}}</h3>
        <div class="w-44 h-2 bg-yellow-400" v-if="current_product == product"></div>
      </div>
    </div>

    <!-- <section class="w-11/12" >
      <div class="flex">
        <div class="w-1/2 flex justify-center items-center">
          <img class="w-2/3" src="@/assets/images/software-screenshots/Image 1.png" />
        </div>
        <div class="w-1/2">
          <p class="text-xl">{{current_product.name}}</p>
          <p class="text-gray-600 w-10/12 mx-auto">Nationwide sellers and buyers lists built  within our platform. BatchLeads has  made it more simple than ever to target Motivated Prospects.</p>
          <button :href="current_product.link" target="_blank" class="bg-yellow-400 p-4 m-3 ">
            GET IT NOW
          </button>
        </div>
      </div>
    </section> -->

  </div>
</template>
<script>

export default {
  name: 'Products',
  components: {

  },
  data(){
    return{
      current_product: null,
      more_products:[
        {icon: require('@/assets/icons/more-products/phone.svg'),
        link: '/minutes', name: 'SKIP SOURCE RVM & TEXT BLAST SOFTWARE', tag: 'rvm'},
        {icon: require('@/assets/icons/more-products/clipboard.svg'),
        link: '/squeeze', name: 'Distressed Seller List', tag: 'sqz'},
        {icon: require('@/assets/icons/more-products/money-bag.svg'),
        link: '/buyers-list', name: 'Cash Buyers, and Agents List', tag: 'byr'},
        {icon: require('@/assets/icons/more-products/crm.svg'),
        link: '#', name: 'Skip Source CRM', tag: 'crm'},
      ]
    }
  },
  methods:{
    setProduct(product){
      window.open(product.link)
      // this.current_product = product
    }
  },
  created(){
    // this.current_product = this.more_products[0]
  }
}
</script>

<style scoped>
#Products{
  margin: 2rem 0;
}
.product-icon {
  width: 10rem;
  min-height: 8rem;
  overflow:hidden;
  position: relative;
  /* Set a specific height */
  background-position:center;
  background-repeat: no-repeat;
  background-size: contain;
}
.more-product-header{
  width: 80%;
  margin: 2rem auto;
  text-align:center;
}
.more-product-header h1{
  font-size: 3rem;
  text-transform: uppercase;
}
.more-product-list{
  width: 80%;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr .5fr;
  grid-column-gap: 20px;
  grid-row-gap: 6rem;
  justify-items: center;
  align-items: center;
  transition: 1s;
}
.product-box{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  text-align: center;
  transition: 1s;
}
.title{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
  width: 80%;
  margin: 1rem auto;
  transition: 1s;
}
.product-box h3{
  font-size: 1.7rem;
    transition: 1s;
}
.product-btn{
  border-radius: 10px;
  padding: 1rem 2rem;
  margin: 1rem;
  color: #fff;
  background-color: var(--primary);
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
}
.container{
  margin: 2rem auto;
  max-width: 900px;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.question-text{
  font-weight: bold;
}

.author-text{
  color: var(--primary);
}
@media screen and (max-width: 900px) {
  .product-box .title{
    font-size: .8rem;
    transition: 1s;
  }
}
@media screen and (max-width: 600px) {
  #MoreProducts{
    margin: 2rem 0;
    min-height: 80vh;
  }
  .product-box .title{
    font-size: .6rem;
    transition: 1s;
  }
  .more-product-list{
    width: 90%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  .product-box{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    text-align: center;
    transition: 1s;
  }
  .more-product-header h1{
    font-size: 2rem;
    width: 100%;
    marign: 0;
  }
  .product-box h2{
    width: 100%;
    font-size: .8rem;
  }
  .product-btn{
    width: 80%;
    padding: .2rem 1rem;
    font-size: .7rem;
  }
  .product-box h3{
    font-size: 1.2rem;
  }
}
</style>
