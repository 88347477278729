<template>
  <div id="SqueezePage">
  <div class="main">
    <SqueezeContent />
    <SqueezeCheckoutCard />
  </div>
  <Footer />
</div>
</template>

<script>
import SqueezeCheckoutCard from '@/components/squeeze/SqueezeCheckoutCard.vue'
import SqueezeContent from '@/components/squeeze/SqueezeContent.vue'
import Footer from '@/components/layout/sections/Footer.vue'
export default {
  name: 'SqueezePage',
  components: {
    SqueezeCheckoutCard,
    SqueezeContent,
    Footer
  },
  data(){
    return{
    }
  },
  methods:{
    displayMinPerCost(count, cost){
      return ((cost/count)*100).toFixed(1)
    }
  },
  created(){
    if(!localStorage.getItem('squeeze_funnel')){
      this.$router.push({name: 'SqueezePage'})
    }
  },
}
</script>
<style scoped>
#SqueezePage{
  position: relative;
  margin: 0 auto;
  /* max-width: 900px; */
}
.presentation{
  width: 100%;
  background-color: #444;
  display: flex;
  justify-content: center;
  align-items:center;
}
.skip-source-phone{
  background-color: #fff;
  width: 30%;
  margin: 2rem auto;
  padding: 2rem 5rem;
}
.skip-source-phone img{
  width:100%;
}
.main{
  width:100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}
@media screen and (max-width: 900px) {
  .main{
    width:100%;
    flex-direction: column;
  }
}
</style>
