<template>
  <div>
    <div id="Disclaimer">
      <h2>DISCLAIMER:</h2>
      <div class="disclaimer">
        <p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants.</p>
        <br />

        <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. Certain content, products and services available via our Service may include materials from third-parties.</p>
        <br />

        <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
        <br />

        <p>{{special_content}}</p>
        <br />

        <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We are not liable for any legal action brought forth from marketing to sellers or prospects. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
        </p>
        <br />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Disclaimer',
  components: {
  },
  data(){
    return{
      special_content: 'You agree to provide current, complete, and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed. User is required to use the software in full compliance with all applicable laws and regulations.  By making any use of the software, User expressly warrants to us that user is and shall continue to act in full compliance with the law.'
    }
  }
}
</script>

<style scoped>
#Disclaimer{
  min-height: 60vh;
  margin: 2rem 4rem;
}
#Disclaimer h2{
  font-size: 2rem;
}
.disclaimer{
  width: 80%;
  margin: 2rem auto;
}
.faq-content{
  transition:1s;
  margin: 2rem;
}
.question-text{
  font-weight: bold;
}
a{
  color: var(--link-light);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  #Disclaimer h2{
    font-size: 2rem;
    margin-left: 1rem;
  }
  .faq-list{
    margin-left: .23rem;
    margin-right: .23rem;
  }
}
</style>
