import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage'
// Your web app's Firebase configuration

var firebaseConfig = {
  apiKey: "AIzaSyDQ-doU1jaf1cufc0k0RVQGmkaFJYhoOsc",
  authDomain: "skip-source.firebaseapp.com",
  databaseURL: "https://skip-source.firebaseio.com",
  projectId: "skip-source",
  storageBucket: "skip-source.appspot.com",
  messagingSenderId: "48402252033",
  appId: "1:48402252033:web:c1d50f2ac8f0dc3f605b00",
};
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const fs = firebase.firestore
const rtdb = firebase.database();
// const fb_msg = firebase.messaging();

// fb_msg.usePublicVapidKey("582851025375")
//
// fb_msg.getToken().then((token) => {
//   console.log(token)
// }).catch((err) => {
//   console.log('Unable to get permission to notify.', err);
// });

export { fb, db, rtdb, fs }
