<template>
  <div id="SubscriptionPlans">
    <div class="dashboard-header">
      <h3>Your Membership Benefits</h3>
      <!-- <p>Current Membership: {{userdata.role}}</p> -->
    </div>

    <div class="files-count-grid">
      <div class="file-count current-membership"
      v-for="(pack, idx) in memberships" :key="idx">
        <!-- <p class="main-text">{{pack.name}}</p> -->
        <!-- <p class="file-ratio current" v-if="pack.code == userdata.role">Current Subscription</p> -->
        <!-- <p class="file-ratio">{{pack.price_details}}</p> -->
        <!-- <button class="trace-btn" @click="selectMembership(pack)"> Select</button> -->
        <img :src="pack.icon" class="box-icon" />

        <div class="perks">
          <p>Details</p>
          <ul>
            <li v-for="(perk, idx) in pack.perks" :key="'perk'+idx">{{perk}}</li>
          </ul>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import {db} from '@/_firebase/init';
export default {
  name: 'SubscriptionPlans',
  components:{
  },
  props:['userdata'],
  data(){
    return{
      user_stats:{
        cash_balance: 0
      },
      memberships:[
      //   {name:'Basic Membership',
      //   price_details:'FREE',
      //   price: 0,
      //   icon:require('@/assets/icons/pending.svg'),
      //   code: 'free',
      //   perks:[
      //     '$0.17 PER SKIP',
      //     '48 Hour turnaround time on skip tracing',
      //     'Unlimited skip tracing',
      //     'Free Resources',
      //     'Member Support Area',
      //     'Innovative SKIP SOURCE: RVM & TEXT BLAST SOFTWARE',
      //     'Direct Mail Marketing and Direct Mail Coaching',
      //     'Free Market Analysis',
      //   ]
      // },
        {name:'Elite Membership',
        price_details:'$19.99 a month',
        price: 1999,
        icon:require('@/assets/icons/delivered.svg'),
        code: 'elite',
        perks:[
          '$0.15 PER SKIP',
          'INSTANT turnaround time on skip tracing',
          'LLC Skip Tracing',
          // 'Data List Discounts: Unlimited data Pulling Starting at $0.03',
          'Unlimited skip tracing',
          'Free Resources',
          'Member Support Area',
          'Innovative SKIP SOURCE: RVM & TEXT BLAST SOFTWARE',
          'Direct Mail Marketing and Direct Mail Coaching',
          'Direct Mail Marketing Discounts',
          'Free Market Analysis',
        ]
      },
      ]
    }
  },
  methods:{
    selectMembership(val){
      this.$emit('selectMembership', val)
    },
    async getUserStats(){
      const userStatsRef = db.collection('user_stats').doc(this.userID);
      const doc = await userStatsRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        this.user_stats = doc.data()
        console.log( doc.data())
      }
    },
  },computed:{
    ...mapState(['userID', 'elitePlanPrice'])
  },
  created(){
    this.getUserStats()
  }
}
</script>
<style scoped>
#SubscriptionPlans{
  padding: 2rem;
  width: 90%;
  background-color: #fff;
  border-radius:15px;
}
.dashboard-header{
  font-size: 2rem;
}
.files-count-grid{
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.file-count{
  padding: 1rem;
  min-height: 7rem;
  border-radius: 15px;
  border: 2px solid #e4e4e4;
  position: relative;
  overflow:hidden;
}
.perks ul{
  margin: 0rem 2rem;
}
.perks ul li{
  margin: 1rem;
}
.box-icon{
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(20deg);
  width: 4rem;
}
.main-text{
  font-size: 1.6rem;
  font-weight: bold;
}
.file-ratio{
  color: var(--link-light);
  font-size: 1.6rem;
  margin: 1rem;
  margin-left: 1rem;
  transition: 1s;
}
.line-label{
  margin-top: 1rem;
}
.current-membership{
  border: 2px solid var(--link-light);
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(66,135,165,0) 81%, rgba(110,229,254,0.7542892156862745) 100%);
}
.current{
  font-weight: bold;
  font-size: 1.3rem;
}
</style>
