<template>
  <div id="Login">
    <div class="login-form"  >
      <p class="welcome-text">Welcome Back</p>
      <p class="danger" :class="{'success': feedtype == 'success'}">{{feedback}}</p>

    <div class="" v-if="!processing">
      <input class="default-input login-input border-2" type="email"  v-model="email" placeholder="Email"/><br />
      <input class="default-input login-input border-2" type="password" v-model="password" placeholder="Password"/>
      <br />
        <button class="login-btn" @click="logging()">Login</button>
      </div>
      <div class="" v-if="processing">
        <vue-spinner class="process-animation" :processing="processing" color="#64B6DD" size="60px"></vue-spinner>
      </div>
      <a class="forgot-pass" href="/forgot-password">Forgot Password?</a>
      <p>Don't have an account with us? <router-link class="signup-link" :to="{name: 'Signup'}">Sign Up</router-link></p>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default{
  components:{
    'vue-spinner': PulseLoader
  },
  name: 'Login',
  data(){
    return{
      email:'',
      password: '',
    }
  },
  computed:{
    ...mapGetters([
      'isAuthenthicated'
    ]),
    ...mapState([
       'feedback', 'feedtype', 'processing'
    ]),
  },
  methods:{
    ...mapMutations(['PROCESS_DATA']),
    logging(){
      console.log('Pressed')
    this.PROCESS_DATA(true)
    let payload = {
        email: this.email.toLowerCase(),
        password: this.password
      }
      this.login(payload).then(response => {
        this.PROCESS_DATA(false)
        window.open('/app', '_self')
      }).catch((err) =>{
          this.PROCESS_DATA(false)
      })
  },
    ...mapActions([
    'login'
  ]),
  },
}
</script>
<style scoped>
#Login{
  max-width: 900px;
  margin: 3rem auto;
}

.login-form{
  margin-top: 2rem
}
.login-input{
  border-radius: 10px;
  padding: .6rem 2rem;
  width: 60%;
  font-size: 1rem;
  outline:none;
  margin: .5rem 0;
  transition: 1s;
}
.login-btn{
  background-color: var(--primary);
  text-align:center;
  border: none;
  border-radius: 10px;
  font-size: 1.3rem;
  padding: 1rem 2rem;
  color: #fff;
  width: 60%;
  margin: 2rem auto;
  cursor: pointer;
  transition: 1s;
}
.forgot-pass{
  color: var(--link-light);
  margin: 1rem auto;
}
.signup-link{
  cursor: pointer;
  color: var(--primary);
}
.flat-mountains{
  width: 100%;
  height: 100vh;
  overflow:hidden;
  background-attachment: fixed;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
}
#signup_carousel{
  margin: 2rem auto;
  min-height: 20rem;
}
.example{color: #aaa; font-size: .7rem;}
.brand-area{
  width: 100%;
  text-align:center;
  color: var(--primary);
}
.logo-brand{
  width: 80%;
  margin: 2rem auto;
  transition:1s;
}
@media only screen and (max-width: 600px) {
  #Login{
    margin: 3rem;
  }
  .input-group{
    width: 75%;
  }
  .head-text{
    font-size: 1.2rem;
    font-weight:bold;
  }
}
/* TINY */
@media only screen and (max-width: 400px) {
}
</style>
